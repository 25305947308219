import { defineStore } from 'pinia';

import { saveUsersExercise, updateUsersExercise,
  deleteUsersExercise } from './storeUsersExercise';
import { TaskBlock, Exercise, User, UsersExercise, Chapter, Material }
  from '../types';

export const SCOPE_ALL      = 'all';
export const SCOPE_CHAPTER  = 'chapter';
export const SCOPE_EXERCISE = 'exercise';

export const useExercisesStore = defineStore('exercises', {
  state: () => ({
    // Exercises
    taskBlocks: null,
    usersExercises: null,
    user: null,
    favSymbol: 'star',
    chapterTitle: '',
    chapter: null,
    savingUsersExercise: false,
    currentExerciseId: null,
    // not yet implemented.
    relatedExerciseIsActive: false,

    // Materials
    isLoading: false,
    materials: null,
    filter: {
      bio_map: false,
      chart: false,
      document: false,
      drawing: false,
      how_to: false,
      image: false,
      info_text: false,
      map: false,
      text_source: false,
      video: false,
    },
    searchTerm: '',
    scope: SCOPE_ALL,
  }),
  getters: {
    // Exercises
    exercises(state) {
      const taskBlocks = state.taskBlocks as Array<TaskBlock> | null;
      if (!taskBlocks) {
        return {};
      }

      const exercises: Array<Exercise> = taskBlocks
        .map((block: TaskBlock) => block.exercises)
        .flat();
      return exercises;
    },
    currentExercise(): Exercise | null {
      if (!this.currentExerciseId) {
        return null;
      }

      return this.exercises.find(
        (exercise: Exercise) => exercise.id === this.currentExerciseId
      );
    },
    currentUsersExercise() {
      return this.usersExercises.find((ue: UsersExercise) =>
        ue.exercise_id === this.currentExercise.wp_id);
    },
    currentUsersExerciseId() {
      return this.currentUsersExercise?.id;
    },
    initialContent() {
      return this.currentUsersExercise
        ? this.currentUsersExercise.text
        : this.currentExercise.predefined_editor_content;
    },
    relatedInitialContent() {
      return this.currentUsersExercise
        ? this.currentUsersExercise.related_text
        : this.currentExercise.predefined_editor_content_further_task;
    },

    // Materials
    filteredMaterials() {
      if (!this.materials) {
        return [];
      }

      if (this.noFilterSet) {
        return this.materials;
      }

      return this.materials.filter((material: Material) =>
        this.filter[material.type] === true);
    },
    searchedMaterials() {
      if (!this.materials) {
        return [];
      }
      if (this.noSearchTermSet) {
        return this.materials;
      }
      return this.materials.filter((material: Material) => {
        const text = `${material.title}${material.caption}${material.text}${material.short_title}${material.written_by}`.toLowerCase();
        const searchTerm = this.searchTerm.trim().toLowerCase();
        return text.search(searchTerm) !== -1;
      });
    },
    filteredAndSearchedMaterials() {
      return this.filteredMaterials.filter((material: Material) =>
        this.searchedMaterials.includes(material));
    },
    noFilterSet(): boolean {
      return Object
        .values(this.filter)
        .every((value) => value === false);
    },
    noSearchTermSet(): boolean {
      return this.searchTerm.trim().length === 0;
    },
    scopedMaterials() {
      return this.buildScopedMaterials(this.materials);
    },
    scopedFilteredAndSearchedMaterials() {
      return this.buildScopedMaterials(this.filteredAndSearchedMaterials);
    },
  },
  actions: {
    // Exercises
    initializeExercisesData(taskBlocksJson: string, usersExercisesJson: string,
      userJson: string, favSymbol: string, chapterTitle: string, chapterJson: string) {
      this.taskBlocks = JSON.parse(taskBlocksJson) as Array<TaskBlock>;
      this.usersExercises = JSON.parse(usersExercisesJson) as Array<UsersExercise>;
      this.user = JSON.parse(userJson) as Array<User>;
      this.favSymbol = favSymbol;
      this.chapterTitle = chapterTitle;
      this.chapter = JSON.parse(chapterJson) as Chapter;
    },
    updateAnswers(answer: string | undefined, relatedAnswer: string | undefined) {
      if (!this.currentUsersExerciseId) {
        this.createCurrentUsersExercise(answer || '', relatedAnswer || '');
        return;
      }
      if (this.answersAreEmpty(answer || this.currentUsersExercise.text,
        relatedAnswer || this.currentUsersExercise.related_text)) {
        this.deleteCurrentUsersExercise();
      } else {
        this.updateCurrentUsersExercise(answer || this.currentUsersExercise.text,
          relatedAnswer || this.currentUsersExercise.related_text);
      }
    },
    answersAreEmpty(answer: string, relatedAnswer: string) {
      if ((typeof answer !== 'string' || answer.trim().length === 0)
        && (typeof relatedAnswer !== 'string' || relatedAnswer.trim().length === 0)) {
        return true;
      } else {
        return false;
      }
    },
    async createCurrentUsersExercise(answer: string, relatedAnswer: string) {
      this.savingUsersExercise = true;
      const usersExercise = await saveUsersExercise(this.currentExercise.wp_id, answer, relatedAnswer);
      this.addUsersExerciseToState(usersExercise);
      this.savingUsersExercise = false;
    },
    async updateCurrentUsersExercise(answer: string, relatedAnswer: string) {
      this.savingUsersExercise = true;
      const usersExercise = await updateUsersExercise(this.currentUsersExerciseId, answer, relatedAnswer);
      this.updateUsersExerciseInState(usersExercise);
      this.savingUsersExercise = false;
    },
    async deleteCurrentUsersExercise() {
      this.savingUsersExercise = true;
      await deleteUsersExercise(this.currentUsersExerciseId);
      this.removeUsersExerciseFromState(this.currentUsersExercise)
      this.savingUsersExercise = false;
    },
    addUsersExerciseToState(usersExercise: UsersExercise) {
      if (this.usersExercises.find((ue: UsersExercise) =>
        ue.id === usersExercise.id)) {
        return;
      }
      this.usersExercises = [...this.usersExercises, usersExercise];
    },
    updateUsersExerciseInState(usersExercise: UsersExercise) {
      const index = this.usersExercises.findIndex((ue: UsersExercise) =>
        ue.id === usersExercise.id);
      if (index === -1) {
        return;
      }

      this.usersExercises = [
        ...this.usersExercises.slice(0, index),
        usersExercise,
        ...this.usersExercises.slice(index + 1)
      ];
    },
    removeUsersExerciseFromState(usersExercise: UsersExercise) {
      const index = this.usersExercises.findIndex((ue: UsersExercise) =>
        ue.id === usersExercise.id);
      if (index === -1) {
        return;
      }

      this.usersExercises = [
        ...this.usersExercises.slice(0, index),
        ...this.usersExercises.slice(index + 1)
      ];
    },
    insertHTMLIntoEditor(html: string) {
      const text = this.initialContent;
      const newText = text + html;
      this.updateAnswers(newText, undefined);
    },

    // Materials
    async fetchMaterials() {
      this.isLoading = true;
      const res = await fetch('/materials.json');
      this.isLoading = false;
      const materials = await res.json();
      this.materials = materials;
    },
    handleFilterChange(type: string) {
      this.filter[type] = !this.filter[type];
    },
    handleSearchTermChange(term: string) {
      this.searchTerm = term;
    },
    buildScopedMaterials(materials: Array<Material> | null) {
      if (!materials) {
        return [];
      }

      switch (this.scope) {
      case SCOPE_EXERCISE:
        return materials.filter((material: Material) =>
          this.currentExercise.material_ids.includes(material.wp_id));
      case SCOPE_CHAPTER:
        return materials.filter((material: Material) =>
          this.chapter.material_ids.includes(material.wp_id));
      case SCOPE_ALL:
      default:
        return materials;
      }
    },
  },
});
