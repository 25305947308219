<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>

<template>
<svg version="1.1" id="Ebene_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 544.5 445" enable-background="new 0 0 544.5 445" xml:space="preserve"
   width="26" height="26">
  <g>
    <g>
      <rect x="266.1" fill="#ffffff" width="20" height="445"
      />
    </g>
    <g>
      <rect x="272.2" y="217.5" fill="#ffffff" width="231.2" height="20"/>
    </g>
    <g>
      <rect x="41.2" y="283.5" fill="#ffffff" width="231.2" height="20"/>
    </g>
    <g>
      <rect x="272.2" y="85.5" fill="#ffffff" width="147.9" height="20"/>
    </g>
    <g>
      <rect x="124.2" y="151.5" fill="#ffffff" width="147.9" height="20"/>
    </g>
    <g>
      <rect x="272.2" y="349.5" fill="#ffffff" width="147.9" height="20"/>
    </g>
    <circle fill="#ffffff" cx="122.9" cy="157.3" r="41.2"/>
    <circle fill="#ffffff" cx="41.2" cy="289.3" r="41.2"/>
    <circle fill="#ffffff" cx="420" cy="355.3" r="41.2"/>
    <circle fill="#ffffff" cx="503.3" cy="222.5" r="41.2"/>
    <circle fill="#ffffff" cx="420" cy="91.3" r="41.2"/>
  </g>
</svg>
</template>
