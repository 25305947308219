<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';

import { useGlobalStore } from './global_store';

export default defineComponent({
  props: {
    fixed: Boolean,
  },
  computed: {
    ...mapState(useGlobalStore, ['teacherMode']),
  },
  methods: {
    ...mapActions(useGlobalStore, ['toggleTeacherMode']),
  },
});
</script>

<template>
<div class="teacher-toggle hidden-print"
  :class="{'teacher-toggle--fixed': fixed}" @click="toggleTeacherMode">
  <div class="teacher-toggle__inner">
    <i class="teacher-toggle__icon fa"
      :class="teacherMode ? 'fa-toggle-on' : 'fa-toggle-off'"
      aria-hidden="true"></i>
    <span class="teacher-toggle__text">
      {{ $t('components.help-page.teacher-toggle') }}
    </span>
  </div>
</div>
</template>
