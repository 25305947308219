<script lang="ts">
import { defineComponent } from 'vue';

import FeedbackPopup from './FeedbackPopup.vue';

export default defineComponent({
  components: {
    FeedbackPopup,
  },
  props: {
    formalAddress: Boolean,
  },
  data() {
    return {
      popupOpen: false,
    };
  },
  computed: {
  },
  methods: {
    handleClick() {
      this.popupOpen = !this.popupOpen;
    },
    handleClose() {
      this.popupOpen = false;
    },
  },
});
</script>

<template>
<li class="feedback-li">
  <button type="button"
    class="feedback-menu"
    @click="handleClick"
    data-test-id="feedback-button">
    <i class="fa fa-comment" aria-hidden="true"></i>
    <span class="feedback-menu-text">
      {{ $t('components.feedbackButton.feedback') }}
    </span>
  </button>
  <FeedbackPopup :visible="popupOpen"
    :on-close="handleClose"
    :formal-address="formalAddress">
  </FeedbackPopup>
</li>
</template>

<style scoped>
.feedback-li {
}

.feedback-menu {
  background-color: transparent;
  color: white;
  border: none;
  height: 58px;
  padding-top: 20px;
  padding-left: 19px;
  font-size: 21px;
  width: 100%;
  display: flex;
}

.feedback-menu-text {
  margin-left: var(--base-unit);
  font-size: 16px;
  display: block;
}
</style>
