<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapWritableState, mapActions } from 'pinia';

import Collapsible from '../Collapsible.vue';
import { useGlobalStore } from '../global_store';
import saveOrUpdateFavorite from '../saveOrUpdateFavorite';
import { useExercisesStore } from './exercises_store';
import PopoverButton from './PopoverButton.vue';
import StarNote from './StarNote.vue';
import HeadingCollapsible from './HeadingCollapsible.vue';
import HtmlEditorTiny from '../HtmlEditorTiny.vue';
import SavingIndicator from '../SavingIndicator.vue';
import { Exercise, User, Favorite } from '../types';

export default defineComponent({
  components: {
    Collapsible,
    HeadingCollapsible,
    HtmlEditorTiny,
    PopoverButton,
    SavingIndicator,
    StarNote,
  },
  computed: {
    ...mapState(useGlobalStore, ['teacherMode']),
    ...mapState(useExercisesStore, ['taskBlocks', 'usersExercises', 'user',
      'favSymbol', 'exercises', 'currentExercise', 'currentUsersExercise',
      'currentUsersExerciseId', 'savingUsersExercise']),
    ...mapWritableState(useExercisesStore, ['currentExerciseId']),
    showTeacherPart(): boolean {
      const user: User = this.user;
      return (user.role === 'Teacher' || user.role === 'Admin')
        && this.teacherMode;
    },
    userPrefersFormal(): boolean {
      const user: User = this.user;
      return user.form_of_address === 'formal';
    },
    exerciseTitle() {
      if (this.userPrefersFormal) {
        return this.currentExercise.title_formal;
      } else {
        return this.currentExercise.title_informal;
      }
    },
    exerciseText() {
      if (this.userPrefersFormal) {
        return this.currentExercise.text_formal;
      } else {
        return this.currentExercise.text_informal;
      }
    },
    relatedExerciseTitle() {
      if (this.userPrefersFormal) {
        return this.currentExercise.related_exercise_title_formal;
      } else {
        return this.currentExercise.related_exercise_title_informal;
      }
    },
    relatedExerciseText() {
      if (this.userPrefersFormal) {
        return this.currentExercise.related_exercise_formal;
      } else {
        return this.currentExercise.related_exercise_informal;
      }
    },
    initialContent() {
      return this.currentUsersExercise
        ? this.currentUsersExercise.text
        : this.currentExercise.predefined_editor_content;
    },
    relatedInitialContent() {
      return this.currentUsersExercise
        ? this.currentUsersExercise.related_text
        : this.currentExercise.predefined_editor_content_further_task;
    },
    showRelatedExercise() {
      return this.relatedExerciseTitle?.length > 0
    },
    relatedExerciseTeacherContent() {
      return this.showTeacherPart
        ? this.currentExercise.educational_remark_related
        : null;
    },
    hasMaterials() {
      return this.currentExercise.material_ids.length > 0;
    },
    isMogDe() {
      // Implement.
      return false;
    },
  },
  methods: {
    ...mapActions(useExercisesStore, ['updateAnswers']),
    async handleToggleStar(exercise: Exercise, starred: boolean) {
      const favorite: Favorite = await saveOrUpdateFavorite(exercise, { starred });
      exercise.favorite_id = favorite.id;
      exercise.starred = favorite.starred;
    },
    async handleUpdateNote(exercise: Exercise, note: string) {
      const favorite: Favorite = await saveOrUpdateFavorite(exercise, { note });
      exercise.favorite_id = favorite.id;
      exercise.note = favorite.note;
    },
    handleAnswerChange(answer: string) {
      this.updateAnswers(answer, undefined);
    },
    handleRelatedAnswerChange(relatedAnswer: string) {
      this.updateAnswers(undefined, relatedAnswer);
    },
  },
});
</script>

<template>
<div v-if="currentExercise" class="exercise">
  <h2 class="title">
    <div class="small">
      <router-link to="/exercises" class="u-mr-small">
        <i class="fa fa-list-ul hidden-print"
          :title="$t('components.exercise-component.exercises')" aria-hidden="true">
        </i>
      </router-link>
      {{ $t('components.exercise-component.exercise') }}
    </div>
    <span>
      <span>{{ exerciseTitle }}</span>
      <img v-if="currentExercise.is_custom"
        src="../../assets/teacher.png" class="custom-exercise"
        :title="$t('components.exercise-component.teachers_task')">
    </span>
    <span class="small pull-right">
      <a :href="`/exercises/${currentExercise.id}.pdf`"
        :title="$t('components.exercise-component.download_worksheet')"
        :aria-label="$t('components.exercise-component.download_worksheet')"
        target="_blank" rel="noreferrer">
        <i class="fa fa-file-pdf-o hidden-print" aria-hidden="true"></i>
      </a>
    </span>
  </h2>
  <div v-if="showTeacherPart" class="teacher-tools visible-teacher">
    <PopoverButton id="exercise-info-button"
      :text-html="currentExercise.educational_remark" />
    <StarNote
      :starred="!!currentExercise.starred"
      :note="currentExercise.note"
      :fav-symbol="favSymbol"
      :on-starred-change="(starred: boolean) => handleToggleStar(currentExercise, starred)"
      :on-note-change="(note: string) => handleUpdateNote(currentExercise, note)"
    />
  </div>

  <div v-html="exerciseText" class="exercise-text"></div>

  <div v-if="hasMaterials" class="back-to back-to-materials">
    <router-link to="/materials" class="back-to-inner">
      <i class="fa fa-folder-open-o" aria-hidden="true"></i>
      {{ $t('components.exercise-component.materials') }}
    </router-link>
  </div>

  <HtmlEditorTiny
    id="editor-answer"
    :initial-content="initialContent"
    :on-content-change="handleAnswerChange"
    delay-initialization
  />

  <small v-if="isMogDe">
    Änderungen werden automatisch gespeichert
  </small>

  <SavingIndicator :saving="savingUsersExercise" />

  <div v-if="showRelatedExercise" class="related_exercise_container">
    <span class="small pull-right">
        <a :href="`/exercises/${currentExercise.id}.pdf?related=true`"
          :title="$t('components.exercise-component.download_worksheet')"
          :aria-label="$t('components.exercise-component.download_worksheet')"
          target="_blank" rel="noreferrer">
          <i class="fa fa-file-pdf-o hidden-print" aria-hidden="true"></i>
        </a>
    </span>

    {{ $t('components.exercise-component.related_exercise') }}

    <HeadingCollapsible
      v-if="showRelatedExercise"
      id="related-exercise"
      :heading="relatedExerciseTitle"
      :teacherContent="relatedExerciseTeacherContent"
    >
      <div class="exercise-related-text" v-html="relatedExerciseText">
      </div>

      <HtmlEditorTiny
        id="editor-related-answer"
        :initial-content="relatedInitialContent"
        :on-content-change="handleRelatedAnswerChange"
      />

      <small v-if="isMogDe">
        Änderungen werden automatisch gespeichert
      </small>

      <SavingIndicator :saving="savingUsersExercise" />
    </HeadingCollapsible>
  </div>
</div>
</template>

<style scoped>
.blinking-cursor-field {
  border: 1px solid #9e9e9e;
  cursor: text;
  height: 4em;
  min-width: 80%;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

.blinking-cursor {
  cursor: pointer;
  left: 22px;
  margin-top: 2px;
  position: absolute;
}
</style>
