<script lang="ts">
import { defineComponent } from 'vue';

import DictionaryIcon from './DictionaryIcon.vue';
import TimelineIcon from './TimelineIcon.vue';

export default defineComponent({
  components: {
    DictionaryIcon,
    TimelineIcon,
  },
  props: {
    showBioMapItem: String,
    showTimelineItem: String,
  },
  computed: {
    showBioMapItemParsed(): boolean {
      return JSON.parse(this.showBioMapItem);
    },
    showTimelineItemParsed(): boolean {
      return JSON.parse(this.showTimelineItem);
    },
  },
});
</script>

<template>
<li v-if="showBioMapItemParsed">
  <router-link to="/biomap" class="biomap-menu biomap-tab" data-test-id="menu-biomap-link"
    active-class="active">
    <span class="fa fa-map-marker"></span>
    <span class="biomap-menu-text">
      {{ $t('components.menu-section.bio_map') }}
    </span>
  </router-link>
</li>

<li>
  <router-link to="/exercises" class="materials-menu" data-test-id="menu-exercises-link"
    active-class="active">
    <span class="fa fa-edit"></span>
    <span class="edit-menu-text">
      {{ $t('components.menu-section.exercises') }}
    </span>
  </router-link>
</li>

<li>
  <router-link to="/materials" class="materials-menu" data-test-id="menu-materials-link"
    active-class="active">
    <span class="fa fa-folder-open-o"></span>
    <span class="materials-menu-text">
      {{ $t('components.menu-section.materials') }}
    </span>
  </router-link>
</li>

<li v-if="showTimelineItemParsed">
  <router-link to="/timeline" class="timeline-menu" data-test-id="timeline-link"
    active-class="active">
    <TimelineIcon />
    <span class="timeline-menu-text">
      {{ $t('components.menu-section.timeline') }}
    </span>
  </router-link>
</li>
<li>
  <router-link to="/dictionary" class="dict-menu" data-test-id="menu-glossary-link"
    active-class="active">
    <DictionaryIcon />
    <span class="dict-menu-text">
      {{ $t('components.menu-section.dictionary') }}
    </span>
  </router-link>
</li>
<li>
  <router-link to="/search" class="search-menu" data-test-id="search-link"
    active-class="active">
    <span class="fa fa-search"></span>
    <span class="search-menu-text">
      {{ $t('components.menu-section.search') }}
    </span>
  </router-link>
</li>
</template>
