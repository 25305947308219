import groupBy from 'lodash.groupby';
import { defineStore } from 'pinia';

import { TimelineEntry } from '../types';

export const useTimelineStore = defineStore('timeline', {
  state: () => ({
    timelineEntries: [],
  }),
  getters: {
    enrichedTimelineEntries() {
      const result = this.timelineEntries.map((entry: TimelineEntry) => ({
        ...entry,
        yearFromDate: entry.date?.slice(0, 4),
      }));
      return result;
    },
    entriesByYear() {
      return groupBy(this.enrichedTimelineEntries, 'yearFromDate');
    },
    years() {
      return Object.keys(this.entriesByYear).sort();
    },
  },
  actions: {
    initializeTimelineData(timelineEntriesJson: string) {
      this.timelineEntries = JSON.parse(timelineEntriesJson) as Array<TimelineEntry>;
    },
  },
});
