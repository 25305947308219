<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    current: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    onLeft: {
      type: Function,
      required: true,
    },
    onRight: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  computed: {
    firstPage(): boolean {
      return this.current === 1;
    },
    lastPage(): boolean {
      return this.current === this.count;
    },
  },
  methods: {
    handleClose() {
      this.onClose();
    },
    handleLeft() {
      if (!this.firstPage) {
        this.onLeft();
      }
    },
    handleRight() {
      if (!this.lastPage) {
        this.onRight();
      }
    },
  },
  mounted() {
    this.$refs.modal.focus();
  },
});
</script>

<template>
<div id="modal" aria-hidden="false"
  ref="modal"
  role="dialog"
  class="slide-modal"
  tabindex="-1"
  @keyup.esc="handleClose"
  @keydown.left="handleLeft"
  @keydown.right="handleRight">
  <header class="slide-modal__header">
    <h2 class="slide-modal__title">{{ title }}</h2>
  </header>
  <div class="slide-modal__content" id="modalContent">
    <slot />
  </div>

  <nav class="slide-modal__nav">
    <ul class="slide-modal__list">
      <li class="slide-modal__item">
        <button @click="handleLeft" :title="$t('components.slide-show.previous_slide')"
          :aria-label="$t('components.slide-show.previous_slide')"
          :disabled="firstPage"
          class="slide-modal__button">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </li>
      <li class="slide-modal__item u-ml-small">
        <span :aria-label="$t('components.slide-show.current_slide', { current, count})">
          {{ current }} / {{ count }}
        </span>
      </li>
      <li class="slide-modal__item u-ml-small">
        <button @click="handleRight" :title="$t('components.slide-show.next_slide')"
          :aria-label="$t('components.slide-show.next_slide')"
          :disabled="lastPage"
          class="slide-modal__button">
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </li>
      <li class="slide-modal__item u-ml-small">
        <button id="exit-presentation" class="slide-modal__button"
          :title="$t('general.close')"
          :aria-label="$t('general.close')"
          @click="handleClose">
          <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      </li>
    </ul>
  </nav>
</div>
</template>
