<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { Exercise } from '../types';

const CHARACTER_LIMIT = 140;

export default defineComponent({
  props: {
    exercise: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    favSymbol: {
      type: String,
      required: true,
    },
    onToggleStar: {
      type: Function,
      required: true,
    },
    onUpdateNote: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showEducationalRemark: false,
    };
  },
  computed: {
  },
  methods: {
    toggleEducationalRemark() {
      this.showEducationalRemark = !this.showEducationalRemark;
    },
    highlight(text: string) {
      let re = new RegExp(this.searchTerm, 'gi');

      if (!this.hasHits(text)) {
        return this.htmlToPlaintext(text);
      } else {
        return this.htmlToPlaintextWithBreaks(text)
          .replace(re, '<span class="highlightedText">$&</span>');
      }
    },
    hasHits(text: string) {
      let re = new RegExp(this.searchTerm, 'gi')
      return this.searchTerm !== '' && re.test(text);
    },
    htmlToPlaintext(text: string) {
      return String(text)
        .replace(/<!--.*?-->/gm, '')
        .replace(/<[^>]+>/gm, '');
    },
    htmlToPlaintextWithBreaks(text: string) {
      return String(text)
        .replace(/<!--.*?-->/gm, '')
        .replace(/<[^>]+>/gm, '')
        .replace(/\n/gm, '<br/>');
    },
    limitCharacters(text: string) {
      return text.slice(0, CHARACTER_LIMIT);
    },
    handleToggleClick() {
      this.onToggleStar(this.exercise, !this.exercise.starred);
    },
    handleInputChange(event: Event) {
      const target = event.target as HTMLInputElement;
      const value = target.value;
      this.onUpdateNote(this.exercise, value);
    }
  },
});
</script>

<template>
<tr class="c-table__row">
  <td class="c-table__cell">
    <a :href="exercise.chapter_link"
      :title="`${exercise.chapter_title} - ${exercise.chapter_subtitle}`"
      v-html="highlight(exercise.chapter_title)"></a>
    <br>
    <small v-html="highlight(exercise.task_block_title)"></small>
  </td>
  <td class="c-table__cell">
    <a :href="exercise.link" :title="htmlToPlaintext(exercise.text)"
      v-html="highlight(exercise.title)"></a>
    <div>
      <span class="small" v-html="highlight(limitCharacters(exercise.text))"
        :title="htmlToPlaintext(exercise.text)"></span>
      <span v-if="!hasHits(exercise.text)">&hellip;</span>
    </div>
    <div v-if="exercise.related_exercise_title">
      <span class="exercise-related" :title="$t('components.exerciseRow.related_exercise')">∗</span>
      <strong class="small" v-html="highlight(exercise.related_exercise_title)"
        :title="$t('components.exerciseRow.related_exercise')"></strong>
      <span class="small" v-html="highlight(limitCharacters(htmlToPlaintext(exercise.related_exercise)))"
        :title="htmlToPlaintext(exercise.related_exercise)"></span>
      <span v-if="!hasHits(exercise.related_exercise)">&hellip;</span>
    </div>
  </td>
  <td class="c-table__cell">
    <ul class="label-list">
      <li v-for="subject in exercise.subjects" class="label-list__item">
        {{ subject }}
      </li>
    </ul>
  </td>
  <td class="c-table__cell">
    <ul class="label-list">
      <li v-for="tag in exercise.tags" class="label-list__item">
        {{ tag }}
      </li>
    </ul>
  </td>
  <td class="c-table__cell">
    <button type="button" class="button button--icon"
      :title="$t('components.exerciseRow.add_to_fav')"
      :aria-label="$t('components.exerciseRow.add_to_fav')"
      @click="handleToggleClick">
      <i class="starred fa" :class="exercise.starred ? `fa-${favSymbol}` : `fa-${favSymbol}-o`"></i>
    </button>
  </td>
  <td class="c-table__cell">
    <input type="text" v-if="exercise.starred"
      :value="exercise.note"
      @change="handleInputChange"
      :placeholder="$t('components.exerciseRow.enter_note')">
  </td>
</tr>
<tr v-if="exercise.educational_remark" class="c-table__row">
  <td colspan="6">
    <button type="button" class="button button--small"
      @click="toggleEducationalRemark">
      <i class="fa fa-info-circle" aria-hidden="true">
      </i> <span class="small">
        {{ $t('components.exerciseRow.educational_remark') }}
      </span> <span class="small fa"
        :class="showEducationalRemark || hasHits(exercise.educational_remark) ? 'fa-caret-down' : 'fa-caret-right'">
      </span>
    </button>
    <div class="small" v-if="showEducationalRemark || hasHits(exercise.educational_remark)"
      v-html="highlight(exercise.educational_remark)"></div>
  </td>
</tr>
</template>
