import MaterialsBrowser from './materials/MaterialsBrowser.vue';
import SlideShow from './results/SlideShow.vue';

const routes = [
  { path: '/biomap', name: 'biomap' },
  { path: '/exercises', name: 'exercises' },
  { path: '/exercises/:id', name: 'exercise-detail' },
  { path: '/dictionary', name: 'dictionary' },
  { path: '/materials', name: 'materials' },
  { path: '/search', name: 'search' },
  { path: '/timeline', name: 'timeline' },
  {
    path: '/materials/:id',
    name: 'material-detail',
    components: { materialBrowser: MaterialsBrowser },
  },
  {
    path: '/presentation',
    components: { slideshow: SlideShow },
  },
];

export default routes;
