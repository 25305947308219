import { defineStore } from 'pinia';

import { UsersExercise, User, BookletCover } from '../types';

export const useResultsStore = defineStore('materials', {
  state: () => ({
    usersExercises: [],
    user: {},
    bookletCover: {},
    bookletIntroduction: '',
    bookletSummary: '',
    checkedStates: {},
    saving: false,
    savingUsersExercise: false,
  }),
  getters: {
    sortedUsersExercises(): Array<UsersExercise> {
      return this.usersExercises.sort((a: UsersExercise, b: UsersExercise) => {
        return b.chapter.id - a.chapter.id;
      });
    },
    checkedUsersExercises(): Array<UsersExercise> {
      return this.sortedUsersExercises.filter((ue: UsersExercise) =>
        this.checkedStates[ue.id]);
    },
    bookletDataComplete(): boolean {
      return this.bookletCover.booklet_title.trim().length > 0;
    },
    bookletDataEmpty(): boolean {
      const values = Object.values(this.bookletCover) as Array<string>;
      return !values.some((value: string) => value.trim().length > 0);
    },
    introductionComplete(): boolean {
      return this.bookletIntroduction.trim().length > 0;
    },
    summaryComplete(): boolean {
      return this.bookletSummary.trim().length > 0;
    },
    isFormalAddress(): boolean {
      return this.user.form_of_address !== 'informal';
    },
  },
  actions: {
    initialize(usersExercises: Array<UsersExercise>, user: User) {
      this.usersExercises = usersExercises;
      this.user = user;

      const bookletCover: BookletCover = JSON.parse(user.booklet_cover);

      const checkedStates = usersExercises.reduce(
        (acc: object, usersExercise: UsersExercise) => {
          acc[usersExercise.id] = usersExercise.in_booklet || false;
          return acc;
        },
        {}
      );

      this.bookletCover = {
        booklet_title: bookletCover.booklet_title || '',
        booklet_subtitle: bookletCover.booklet_subtitle || '',
        booklet_image_url: bookletCover.booklet_image_url || '',
        booklet_image_attribution: bookletCover.booklet_image_attribution || '',
        booklet_author: bookletCover.booklet_author || '',
        booklet_course: bookletCover.booklet_course || '',
        booklet_date: bookletCover.booklet_date || '',
      };

      this.bookletIntroduction = user.booklet_introduction || '';
      this.bookletSummary = user.booklet_summary || '';
      this.checkedStates = checkedStates;
    },
    async saveBooklet() {
      this.saving = true;

      const path = `/admin/users/${this.user.id}/update_booklet_pages.json`;
      const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'X-User-Id': this.user.id,
        'X-User-Token': this.user.authentication_token,
      };
      const data = {
        user: {
          booklet_cover: JSON.stringify(this.bookletCover),
          booklet_introduction: this.bookletIntroduction,
          booklet_summary: this.bookletSummary,
        },
      };

      const response = await fetch(path, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();

      this.saving = false;
    },
    async saveUsersExercise(id: number) {
      this.savingUsersExercise = true;

      // TODO: Needs to be changed for group users.
      const path = `/admin/my_results/users_exercises/${id}.json`;
      const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'X-User-Id': this.user.id,
        'X-User-Token': this.user.authentication_token,
      };
      const data = {
        users_exercise: {
          in_booklet: this.checkedStates[id],
        },
      };

      try {
        const response = await fetch(path, {
          method: 'PUT',
          headers,
          body: JSON.stringify(data),
        });
        const result = await response.json();
      } catch (e) {
        console.log(e.message);
      }

      this.savingUsersExercise = false;
    },
  },
});
