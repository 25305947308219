export const OSM_LAYER = {
  url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  options: {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 19,
    maxNativeZoom: 19,
  },
};

export const ESRI_LAYER = {
  url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}',
  options: {
    attribution: '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | Source and Tiles &copy; Esri',
    maxZoom: 19,
    maxNativeZoom: 13,
  },
};

export const GOOGLE_LAYER = {
  url: 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
  options: {
    maxZoom: 19,
    subdomains:['mt0', 'mt1', 'mt2', 'mt3'],
  },
};

export const GEOJSON_LAYER_OPTIONS = {
  clickable: false,
  style: {
    color: 'black',
    weight: 1,
    fillOpacity: 0.075,
  },
};

export const PATH_LINE_OPTIONS = {
  color: '#2880ca',
  opacity: 0.85,
  weight: 2,
  dashArray: '4',
};

export const POLAND_1939_LAYER_OPTIONS = {
  clickable: false,
  style: {
    color: 'white',
    weight: 0,
    opacity: 1,
    fillOpacity: 0.2,
    fillColor: '#D4213D',
  },
};
