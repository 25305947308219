<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import { useGlobalStore } from '../global_store';
import saveOrUpdateFavorite from '../saveOrUpdateFavorite';
import { useExercisesStore } from './exercises_store';
import PopoverButton from './PopoverButton.vue';
import { Exercise, TaskBlock, User, Favorite } from '../types';
import StarNote from './StarNote.vue';

export default defineComponent({
  components: {
    PopoverButton,
    StarNote,
},
  computed: {
    ...mapState(useGlobalStore, ['teacherMode']),
    ...mapState(useExercisesStore, ['taskBlocks', 'user', 'favSymbol']),
    showTeacherPart(): boolean {
      const user: User = this.user;
      return (user.role === 'Teacher' || user.role === 'Admin')
        && this.teacherMode;
    },
  },
  methods: {
    async handleToggleStar(exercise: Exercise, starred: boolean) {
      const favorite: Favorite = await saveOrUpdateFavorite(exercise, { starred });
      exercise.favorite_id = favorite.id;
      exercise.starred = favorite.starred;
    },
    async handleUpdateNote(exercise: Exercise, note: string) {
      const favorite: Favorite = await saveOrUpdateFavorite(exercise, { note });
      exercise.favorite_id = favorite.id;
      exercise.note = favorite.note;
    }
  },
});
</script>

<template>
<div class="exercises">
  <h2 class="heading heading--large u-mb">
    {{ $t('components.menu-section.exercises') }}
  </h2>

  <div v-for="block in taskBlocks" class="task-block">
    <h2 v-html="block.title" class="task-block-heading"></h2>
    <div v-for="exercise in block.exercises" class="exercises-index">
      <div class="exercise-link">
        <router-link :to="`/exercises/${exercise.id}`" class="exercise-link-title"
          data-test-id="exercise-link">
          <span v-html="exercise.title_formal" :id="`title${exercise.id}`"></span>
          <img v-if="exercise.is_custom" src="../../assets/teacher.png"
            class="custom-exercise" :title="$t('components.exercise-component.teachers_task')" />
        </router-link>
        <div v-if="showTeacherPart" class="teacher-tools visible-teacher">
          <PopoverButton id="exercise-info-button"
            :text-html="exercise.educational_remark" />
          <StarNote
            :starred="!!exercise.starred"
            :note="exercise.note"
            :fav-symbol="favSymbol"
            :on-starred-change="(starred: boolean) => handleToggleStar(exercise, starred)"
            :on-note-change="(note: string) => handleUpdateNote(exercise, note)"
          />
        </div>
      </div>
    </div>
  </div>
</div>
</template>
