import $ from 'jquery';

import url_slug from '../url_slug';

$(() => {
  $(document).on("click", ".member-name .fa-pencil", function(){
    $(this).removeClass("fa-pencil");
    $(this).addClass("fa-check");
    var val = $(this).siblings("span").html();
    $(this).siblings("span").remove();

    var input = $("<input />");
    $(input).val(val);

    $(this).parent().prepend(input);
  });

  $(document).on("click", ".member-name .fa-check", function(){
    changeMemberName(this);
  });

  $(document).on("keyup", ".member-name input", function(e){
    var button = $(this).siblings(".fa-check");
    if (e.keyCode == 13) {
      changeMemberName(button);
    }
  });
});

function changeMemberName(el: HTMLElement | JQuery) {
  const $this = $(el);
  const userId = $this.parent().data("id");
  const name = String($this.siblings("input").val());

  // do not allow special characters
  const sanitizedName = url_slug(name);
  $(this).siblings("input").val(sanitizedName);

  const url = `/admin/users/${userId}.json`;

  $.ajax({
    method: "PUT",
    url: url,
    data: {
      name: sanitizedName,
      user: {
        name: sanitizedName
      }
    },
    success: function() {
      $this.removeClass("fa-check fa-circle-o-notch fa-spin")
      $this.addClass("btn")
      $this.addClass("fa-pencil")
      var span = $("<span />")
      span.html(sanitizedName);
      $this.parent().prepend(span);
      $this.siblings("input").remove();
    },
    fail: function (obj) {
      console.log(obj)
      if (obj["status"] == 422) {
        $this.after($("<span class='label label-danger error-text'>"
          + JSON.parse(obj["responseText"]).name + "</span>"));
        $(".error-text").delay(3000).hide('fast', function(){ $(this).remove(); })
      }
      $this.addClass("fa-check")
      $this.removeClass("fa-circle-o-notch fa-spin")
      $this.addClass("btn")
    }
  });

  $this.addClass("fa-circle-o-notch fa-spin");
  $this.removeClass("btn")
  $this.removeClass("fa-check");
}
