import { defineStore } from 'pinia';

import { BioMap, MapSetting } from '../types';

export const useMapStore = defineStore('map', {
  state: () => ({
    bioMap: null,
    mapSetting: null,
    imageUrl: '',
  }),
  actions: {
    initializeMapData(bioMapJson: string, mapSettingJson: string, imageUrl: string) {
      this.bioMap = JSON.parse(bioMapJson) as BioMap;
      this.mapSetting = JSON.parse(mapSettingJson) as MapSetting;
      this.imageUrl = imageUrl;
    },
  },
});
