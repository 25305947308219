import $ from 'jquery';

import './google';
import ThematicalMapViewer from './thematical-map-viewer';

function setElemClassName(elem$, className) {
    if (className) {
        elem$.addClass(className)
    }
}

function buildLookupMap(property,objects) {
    var map = {}
    for ( var k in objects ) {
        var object = objects[k]
        map[object[property]] = object
    }
    return map
}

function buildPropertyToPropertyLookupMap(key,value,objects) {
    var map = {}
    for ( var k in objects ) {
        var object = objects[k]
        map[object[key]] = object[value]
    }
    return map
}

function forEachObjectIn(list, callback) {
    for (var k in list) {
        callback(list[k],k)
    }
}

function ifPropertyIsSet(value, callback) {
    if (typeof value !== 'undefined')
        callback(value)
}

function ifPropertyOfObjectIsSet(object, property, callback) {
    var value = object[property]
    if (typeof value !== 'undefined')
        callback(value)
}

function populateMapNto1(map, keys, value) {
    for ( var k in keys ) {
        map[keys[k]] = value
    }
}

function getPropertyForEachObject(list, property) {
    var values = []
    for ( var k in list ) {
        values.push(list[k][property])
    }
    return values
}

export default function OlpThematicMap(tmapPostID, root$, language,
    mapurlcallback, mapx, mapy, mapzoom, tmapdatacallback, basemaplayerscallback) {
    if (!basemaplayerscallback) {
        basemaplayerscallback = function(key,attribution) {
            var attributionprefix = ''

            if (attribution) {
                attributionprefix = attribution+" | "
            }

            var googlestyle = [
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {"visibility": "off"}
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "stylers": [
                        {"visibility": "off"}
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "stylers": [
                        {"visibility": "on"}
                    ]
                },
                {}
            ]

            var hybrid = new L.Google('HYBRID', {
                mapOptions: {
                    styles: googlestyle
                }
            });

            var satellite = new L.Google('SATELLITE', {
                mapOptions: {
                    styles: googlestyle
                }
            });

            var terrain = new L.Google('TERRAIN', {
                mapOptions: {
                    styles: googlestyle
                }
            });

            var hyda = new L.TileLayer("https://{s}.tile.openstreetmap.se/hydda/base/{z}/{x}/{y}.png", {
                attribution: attributionprefix+'Tiles and map data courtesy of <a href="http://openstreetmap.se/" target="_blank">OpenStreetMap</a>'
            })


            var Esri_WorldShadedRelief = new L.TileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}', {
                attribution: 'Source and Tiles &copy; Esri',
                maxZoom: 13
            });

            if (key == 'simple') {
                return Esri_WorldShadedRelief;
            } else if (key == 'terrain') {
                return terrain;
            } else if (key == 'satellite') {
                return satellite;
            } else if (key == 'hybrid') {
                return hybrid;
            }

            return false;
        }
    }

    var olpTmap = false

    function loadNewTmap(postID) {
        if (olpTmap) {
            olpTmap = null
            console.log("loadNewTmap deleted olpTmap")
        }

        tmapdatacallback(postID, function (tmapdata) {
            olpTmap = new OlpThematicMap2(tmapdata, root$, language, mapurlcallback, mapx, mapy, mapzoom, postID)
        })
    }

    loadNewTmap(tmapPostID)

    var OlpThematicMap2 = function (data, root$, language, mapurlcallback, mapx, mapy, mapzoom, tmapPostID) {
        var defaults = {}
        var map$
        var tmapData
        var tmapviewer

        window.basedata = data

        function buildTmapDataSkeleton() {
            var highlightstyle = {
                'fillColor': 'featuregroup_active',
            }

            function initTmapData() {
                tmapData = window.tmapdata = {
                    DefaultStyleByFeature: {},
                    IsClickable: {},
                    ByCountry: {},
                    BySections: {},
                    PopupContentByFeature: {},
                    PopupContentByFeatureGroup: {},
                    BaseMapLayers: null,
                    Default: {
                        className: "tmap-default"
                    }
                }
            }

            function createDefaultStyleRecord() {
                return {
                    className: "leaflet-clickable tmap-feature",
                }
            }

            function setDefaultStyleForEachFeature() {
                forEachObjectIn(data.countries, function (country) {
                    tmapData.DefaultStyleByFeature[country.post_title] = createDefaultStyleRecord()
                })
            }

            function markClickableFeatures() {
                forEachObjectIn(data.countries, function (country) {
                    tmapData.IsClickable[country.post_title] = true
                })
            }

            function assignPopupContentToIndividualFeatures() {
                forEachObjectIn(data.sections, function (section) {

                    if (!section.enable_popup) {
                        return
                    }
                    ifPropertyIsSet(section.popup_info_block, function (popup_info_block) {

                        var popupcontent = popup_info_block

                        if (section.info_block!='') {
                            popupcontent += '<a section="'+section.name+'" class="has-details">Details</a>';
                        }

                        //console.log("assignPopupContentToIndividualFeatures", tmapData.PopupContentByFeature,section.countries,popupcontent)

                        populateMapNto1(tmapData.PopupContentByFeature,
                            getPropertyForEachObject(section.countries, "post_title"),
                            popupcontent)
                    })
                })
            }

            function assignPopupContentToIndividualFeatureGroups() {
                forEachObjectIn(data.sections, function (section) {
                    ifPropertyIsSet(section.popup_info_block, function (popup_info_block) {
                        tmapData.PopupContentByFeatureGroup[section.name] = popup_info_block
                    })
                })
            }

            initTmapData()

            setDefaultStyleForEachFeature()

            markClickableFeatures()

            assignPopupContentToIndividualFeatures()

            assignPopupContentToIndividualFeatureGroups()

            buildFeatureGroups();

            // request basemaplayers
            var basemaps = [{
                type: 'simple',
                short_caption: '',
            }];

            if (data.base_maps && data.base_maps.length > 0) {
                basemaps = data.base_maps
            }

            tmapData.baseMapLayers = {}

            for (var k in data.base_maps) {
                var bm = data.base_maps[k]
                var basemap = basemaplayerscallback(bm.type,bm.short_caption)

                if (!basemap) {
                    console.log('Base Map type "'+bm.type+'" not found')
                    continue
                }

                tmapData.baseMapLayers[bm.label] = basemap
                if (!tmapData.baseMapLayer) {
                    tmapData.baseMapLayer = [basemap]
                }
            }

            if (!tmapData.baseMapLayer) {
                throw new Error("No valid base map layer declared.")
            }

            function buildFeatureGroups() {
                forEachObjectIn(data.sections, function (section) {
                    function buildFeatureGroupRecordAndAssignItToTmapData() {
                        function overloadStyleIfColorAndOpacityDefined(style, section) {
                            var color = section.color

                            var opacity = (section.opacity === '' ? '100' : section.opacity) / 100

                            if (color) {
                                style = {
                                    fillColor: color,
                                    fillOpacity: opacity
                                }
                            }
                        }

                        var BySection = {
                            name: section.name,
                            countries: [],
                            style: {
                                'fillColor': 'featuregroup',
                            },
                            highlightstyle: highlightstyle
                        }

                        if (section.default_css_class) {
                            BySection.style = {
                                fillColor: section.default_css_class
                            }
                        }

                        if (section.active_css_class) {
                            BySection.highlightstyle = {
                                fillColor: section.active_css_class
                            }
                        }

                        forEachObjectIn(section.countries, function (country) {
                            BySection.countries.push(country.post_title)
                            var struct = tmapData.DefaultStyleByFeature[country.post_title]
                            var sectionFillColor = "featuregroup"

                            if (section.default_css_class) {
                                sectionFillColor = section.default_css_class
                            }
                                if (struct) {
                                    struct.fillColor = sectionFillColor
                                } else {
                                    struct = {
                                        className: "tmap-default",
                                        fillColor: sectionFillColor
                                    }
                                }
                                tmapData.DefaultStyleByFeature[country.post_title] = struct
                        })

                        forEachObjectIn(section.countries, function (country) {
                            tmapData.ByCountry[country.post_title] = BySection
                        })

                        tmapData.BySections[section.name] = BySection
                    }

                    buildFeatureGroupRecordAndAssignItToTmapData()
                })
            }
        }

        function initDefaultsAndLookupStructs() {
            defaults.color = data.color
            defaults.opacity = (data.opacity === '' ? 100 : data.opacity) / 100
        }

        function buildHtmlBaseStructure() {
            var InfoOverlay$
            var NavTabs$
            var InfoOverlayTabContent$
            var InfoOverlaysLinkToFeatureGroup = {}

            function setMapShowHideLoaderEventListeners(map$) {
                map$.on('show-map-loader', function () {
                    console.log('loading map')
                    //alert("show")
                    $('.geojson-layer-loader', root$).show()
                })
                map$.on('hide-map-loader', function () {
                    console.log('hide map loader')
                    //alert("hide")
                    $('.geojson-layer-loader', root$).hide()
                })
            }

            function destroyEventHandlers() {
                console.log('turning off event handlers')
                root$.off('show.bs.tab')
                root$.off('show.bs.tab')
            }

            root$.empty()

            var template$ = $('<div class="olp-tmap-base">'+
                '<h2 class="title"></h2>'+
                '<ul class="nav nav-tabs invisible" role="tablist"></ul>'+
                '<div style="clear: both;"></div>'+
                '<div class="map-frame">'+
                '<div class="leaflet-map"></div>'+
                '<div class="geojson-layer-loader">Map is loading &hellip;</div>'+
                '<div class="gesamt-button">Gesamt</div>'+
                '<div class="gesamt-content-holder">' +
                    '<a class="close-button glyphicon glyphicon-remove"></a>' +
                    '<div class="gesamt-content content"></div>' +
                    '<div class="bottom-marquee"></div>' +
                '</div>'+
                '<div class="info-overlay">' +
                '<a class="close-button close-info-overlay glyphicon glyphicon-remove hidden"></a>' +
                    '<div class="content"></div>'+
                '</div>'+
                '</div>'+
                '<div class="caption content"></div>'+
                '</div>')

            root$.append(template$)

            const OlpTmapBase$ = $('.olp-tmap-base', root$)

            InfoOverlay$ = $('.info-overlay', root$)

            if (data.intro_text) {
                $(".content", InfoOverlay$).html(data.intro_text)
                InfoOverlay$.addClass('visible')
                OlpTmapBase$.addClass("intro-text-visible")
                //if (data.is_intro_text_layer_closeable) {
                    $(".close-button", InfoOverlay$).removeClass("hidden").on('click', function() {
                        InfoOverlay$.remove()
                    })
                //}
            }

            NavTabs$ = $('.nav-tabs.nav', root$)

            InfoOverlayTabContent$ = $('.tab-content', InfoOverlay$)

            map$ = $('.leaflet-map', root$)

            function addInfoOverlayButton(idpane,
                                          name,
                                          isactive) {

                if (false && content && !map) {
                    var tabpane$ = $('<div role="tabpanel" class="info-block tab-pane fade in"/>')
                    var infoblockcontent$ = $("<div class='content'/>")

                    tabpane$.addClass('class-' + idpane)
                    tabpane$.attr('id', idpane)

                    infoblockcontent$.html(content).appendTo(tabpane$)

                    if (isactive) {
                        tabpane$.addClass('active')
                    }

                    if (iscollapsible) {
                        $("<div class='hide-info-block glyphicon glyphicon-remove'></div>").appendTo(tabpane$)
                    }
                }

                var navTabLi$ = $('<li role="presentation" class="button"/>')

                navTabLi$.addClass('class-' + idpane)

                if (isactive) {
                    navTabLi$.addClass('active')
                }

                var tabpaneAhref$ = $('<a role="tab" data-toggle="tab"/>')
                tabpaneAhref$.html(name)
                tabpaneAhref$.attr('data-target', '#' + idpane)
                tabpaneAhref$.attr('id', 'tab-' + idpane)
                tabpaneAhref$.attr('info-overlay', idpane)

                navTabLi$.append(tabpaneAhref$)

                NavTabs$.append(navTabLi$)

                if (NavTabs$.hasClass("invisible")) {
                    NavTabs$.removeClass("hidden")
                    NavTabs$.removeClass("invisible")
                }
            } // addInfoOverlayButton


            /**
             * uses:
             *
             * - data.info_sections
             *
             */
            function createTabButtons() {
                $(data.info_sections).each(function (pos, infosection) {
                    addInfoOverlayButton(
                        infosection.html_id,
                        infosection.name,
                        infosection.active
                    )
                })
            }

            function setTitle() {
                var Title$ = $('.title', root$)
                Title$.html(data.title)
            }

            function setCaption() {
                var Caption$ = $('.caption', root$)
                Caption$.html(data.caption)
            }

            function setGesamtContent() {
                if (data.overview_content) {
                    var ContentHolder$ = $('.gesamt-content-holder', root$)
                    var ContentButton$ = $('.gesamt-button', root$)
                    var CloseButton$ = $('.close-button', ContentHolder$)
                    ContentButton$.html(data.overview_button_name).addClass("visible")
                    ContentButton$.on('click', function() {
                        setGesamtContentHtml(data.overview_content)
                        ContentHolder$.toggleClass('visible')
                    })
                    CloseButton$.on('click', function() {
                        setGesamtContentHtml(data.overview_content)
                        ContentHolder$.toggleClass('visible')
                    })
                }
            }

            function setGesamtContentHtml(html) {
                var Content$ = $('.gesamt-content', root$)
                Content$.html(html)
            }

            function setGesamtContentVisible(visible) {
                var ContentHolder$ = $('.gesamt-content-holder', root$)
                if (visible) {
                    ContentHolder$.addClass('visible')
                } else {
                    ContentHolder$.removeClass('visible')
                }
            }

            function addMainButtonAsNavTabButton() {
                if (data.main_button_is_visible) {
                    addInfoOverlayButton(
                        '_start',
                        data.main_button_label,
                        true
                    )
                }
            }

            addMainButtonAsNavTabButton()

            createTabButtons()

            setTitle()

            setCaption()

            setGesamtContent()

            setMapShowHideLoaderEventListeners(map$)

            addShowBsTabEventListeners()

            $(document).on('click', '.info-block .hide-info-block', function (event) {
                var t$ = $(this)
                var closest$ = t$.closest(".active").removeClass("active").removeClass("in")
                $(".active", InfoOverlayButtons$).removeClass("active").addClass("minified")
                event.preventDefault()
                return false
            })

            function closeInfoOverlay() {
                $(".olp-tmap-base .info-overlay").removeClass("visible")
                $(".olp-tmap-base").removeClass("intro-text-visible")
            }

            $(document).on('click', '.info-overlay .close-info-overlay', function (event) {
                closeInfoOverlay()
                return false
            })

            $(document).on('click', '.info-overlay .close-info-overlay', function (event) {
                closeInfoOverlay()
                return false
            })

            var InfoSectionByName = buildLookupMap('name', data.sections)

            $(root$).on('click', '.leaflet-popup-content a.has-details', function() {

                var a$ = $(this)

                var sectionName = a$.attr('section')

                console.log('popup click '+sectionName,InfoSectionByName[sectionName])

                ifPropertyIsSet(InfoSectionByName[sectionName], function(section) {
                    console.log(section)
                    ifPropertyIsSet(section.info_block, function(html) {
                        tmapviewer.closePopups()
                        setGesamtContentVisible(false)
                        setGesamtContentHtml(html)
                        setGesamtContentVisible(true)
                    })
                })

            })

            function addShowBsTabEventListeners() {
                var InfoSectionsByHtmlId = buildLookupMap("html_id", data.info_sections)

                // root$.on('show.bs.tab', function () {
                root$.on('click','.nav-tabs a', function () {
                    console.log("show.bs.tab closing popups")
                    //tmapviewer.closePopups()
                    $(".active", NavTabs$).removeClass("active")
                    $(".minified", NavTabs$).removeClass("minified")
                })

                root$.on('click','.nav-tabs a', function (event) {
                    var target$ = $(event.target)

                    // auslesen des info-overlay attributs,
                    // welches dem TAB-button/reiter zugeordnet ist
                    // targetInfoOverlay := #INFOSECTION.html_id

                    var targetInfoOverlay = target$.attr('info-overlay')

                    console.log("2:firing shown.bs.tab " + targetInfoOverlay)

                    function isProcessChangeMapRequest(infosection_html_id) {
                        var InfoSection = InfoSectionsByHtmlId[infosection_html_id]

                        if (!InfoSection) {
                            return false
                        }

                        if (!InfoSection.map) {
                            return false
                        }

                        if (InfoSection.map.ID == tmapPostID) {
                            return false
                        }

                        return true

                    }

                    function processChangeMapRequest(infosection_html_id) {
                        var InfoSection = InfoSectionsByHtmlId[infosection_html_id]

                        destroyEventHandlers()

                        map$.trigger('show-map-loader')

                        loadNewTmap(InfoSection.map.ID)
                    }

                    function toggleFeatureGroupInMapBelongingToSection(targetInfoOverlay) {
                        /**
                         * InfoOverlaysLinkToFeatureGroup - set in <function>addInfoOverlayButton</function>
                         */
                        var sectionName = InfoOverlaysLinkToFeatureGroup[targetInfoOverlay]

                        if (sectionName) {
                            console.log('show.bs.tab:sectionName:', sectionName)
                            var bysection = tmapviewer.findBySectionBySectionName(sectionName) // #BYSECTION
                            tmapviewer.toggleFeatureGroup(bysection, true)
                        }
                    }

                    if (
                        isProcessChangeMapRequest(targetInfoOverlay)
                    ) {
                        processChangeMapRequest(targetInfoOverlay)
                    } else {
                        toggleFeatureGroupInMapBelongingToSection(targetInfoOverlay)
                    }
                })
            }
        }

        function initTmapViewer() {
            buildTmapDataSkeleton()

            tmapviewer =
                window.tmapviewer =
                    new ThematicalMapViewer(map$, language, tmapData)

            tmapviewer.initMap(mapx, mapy, mapzoom, tmapData.baseMapLayer)

            var mapurl = mapurlcallback(data.default_time_layer, language)

            window.mapurlcallback = mapurlcallback

            tmapviewer.loadMap(mapurl)

            var SectionNameToInfosectionIDMapping = buildPropertyToPropertyLookupMap('name', 'info_overlay', data.sections)

            $(document).on('leaflet.feature.highlighted', function (event, payload) {
                console.log('highlighted', payload) // , SectionToInfoSectionMapping)
                var section = payload.section
                var linkedInfoOverlay = SectionNameToInfosectionIDMapping[section.name]
                console.log('linked info overlay', linkedInfoOverlay, section.name)
                if (linkedInfoOverlay) {
                    $('a#tab-' + linkedInfoOverlay, root$).tab('show')
                }
            })
        }

        initDefaultsAndLookupStructs()
        buildHtmlBaseStructure()
        initTmapViewer()
    }
}
