import $ from 'jquery';

$(() => {
  $(".add-new-user").on("click", function(ev){
    const $this = $(this);
    const groupId = $this.data("id");
    const elementHtmlContent = $this.html();
    const $row = $(this).parent().parent();
    const url = `/admin/groups/${groupId}/add_new_user`;

    $this.html("<i class='fa fa-circle-o-notch fa-spin'></i>");

    $.ajax({
      method: "GET",
      url: url,
      success: function(msg) {
        $this.html(elementHtmlContent);
        $row.before("<tr><td class='member-name' data-id='"
          + msg[2]
          + "'><span>"
          + msg[0]
          + "</span><div class='fa fa-pencil hidden-print btn btn-small btn-default'></div></td><td></td><td><samp>"
          + msg[1]
          + "</samp></td><td></td></tr>"
        );
      },
      fail: function(msg) {
        $this.html(elementHtmlContent);
      }
    });
  });
});
