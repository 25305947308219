<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    starred: {
      type: Boolean,
      required: true,
    },
    note: {
      type: String,
      required: true,
    },
    favSymbol: {
      type: String,
      required: true,
    },
    onStarredChange: {
      type: Function,
      required: true,
    },
    onNoteChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleStarClick(event: Event) {
      this.onStarredChange(!this.starred);
    },
    handleInputChange(event: Event) {
      if (!event.target) {
        return;
      }
      const element = event.target as HTMLInputElement;
      this.onNoteChange(element.value);
    },
  },
});
</script>

<template>
<button
  type="button"
  class="button button--icon"
  @click="handleStarClick"
  :title="$t('components.exerciseRow.add_to_fav')"
  :aria-label="$t('components.exerciseRow.add_to_fav')"
  data-test-id="exercise-star-button"
>
  <i class="starred fa"
    :class="starred ? `fa-${favSymbol}` : `fa-${favSymbol}-o`"
    aria-hidden="true"></i>
</button>
<input type="text" v-if="starred"
  :value="note"
  @change="handleInputChange"
  :placeholder="$t('components.exerciseRow.enter_note')">
</template>

<style>
</style>
