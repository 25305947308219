<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { TaskBlock, Exercise, CustomExercise } from '../types';

export default defineComponent({
  props: {
    customExercise: {
      type: String,
      required: true,
    },
    exercise: {
      type: String,
      required: true,
    },
    taskBlocks: String,
    userGroups: String,
    authenticityToken: String,
  },
  data() {
    const parsedCustomExercise: CustomExercise = JSON.parse(this.customExercise);
    const parsedExercise: Exercise = JSON.parse(this.exercise);

    return {
      materialsCache: {},
      materialsLoading: false,
      currentTaskBlockId: parsedExercise.task_block_id,
      title: parsedExercise.title_informal || '',
      text: parsedExercise.text_informal || '',
      checkedGroups: parsedCustomExercise.group_ids,
      checkedMaterials: parsedExercise.material_ids,
    };
  },
  computed: {
    parsedCustomExercise(): CustomExercise {
      return JSON.parse(this.customExercise);
    },
    parsedExercise(): Exercise {
      return JSON.parse(this.exercise);
    },
    parsedTaskBlocks(): Array<TaskBlock> {
      return JSON.parse(this.taskBlocks) || [];
    },
    parsedUserGroups() {
      return JSON.parse(this.userGroups) || [];
    },
    displayedMaterials() {
      if (this.currentTaskBlockId === null) {
        return [];
      }

      return this.materialsCache[this.currentTaskBlockId] || [];
    },
    valid() {
      return this.currentTaskBlockId !== null
        && this.title.trim() !== ''
        && this.text.trim() !== ''
        && this.checkedGroups.length > 0;
    },
    isNew() {
      return this.parsedCustomExercise.id === null;
    },
    action() {
      return this.isNew
        ? '/admin/my_tasks'
        : `/admin/my_tasks/${this.parsedCustomExercise.id}`;
    },
    method() {
      return this.isNew ? 'post' : 'post';
    },
  },
  methods: {
    async fetchMaterials() {
      const id = this.currentTaskBlockId;
      if (id === null) {
        return;
      }

      if (this.materialsCache[id]) {
        return this.materialsCache[id];
      }

      this.materialsLoading = true;
      const path = `/materials.json?task_block=${id}`;
      const res = await fetch(path);
      const data = await res.json();
      this.materialsCache[id] = data;
      this.materialsLoading = false;
    },
  },
  watch: {
    currentTaskBlockId(newId, oldId) {
      this.fetchMaterials();
    },
  },
  mounted() {
    this.fetchMaterials();
  }
});
</script>

<template>
<form :action="action" :method="method" accept-charset="UTF-8">
  <input name="utf8" type="hidden" autocomplete="off" value="✓">
  <input v-if="!isNew" type="hidden" name="_method" value="patch" autocomplete="off">
  <input type="hidden" name="authenticity_token"
    :value="authenticityToken" autocomplete="off">

  <div class="form-group">
    <select v-model="currentTaskBlockId"
      class="form-control"
      data-test-id="custom-exercise-task-block-select"
      name="custom_exercise[exercise_attributes][task_block_id]">
      <option disabled :value="null">
        {{ $t('components.custom-exercise-form.chapter') }}
      </option>
      <option v-for="block in parsedTaskBlocks"
        :key="block.id" :value="block.id">
        {{ block.title_with_chapter }}
      </option>
    </select>
  </div>

  <div class="form-group">
    <input type="text"
      :placeholder="$t('components.custom-exercise-form.title')"
      v-model="title"
      class="form-control"
      name="custom_exercise[exercise_attributes][title_informal]"
      data-test-id="custom-exercise-title">
  </div>

  <div class="form-group">
    <textarea
      name="custom_exercise[exercise_attributes][text_informal]"
      v-model="text"
      :placeholder="$t('components.custom-exercise-form.text')"
      class="form-control"
      data-test-id="custom-exercise-text"></textarea>
  </div>

  <div class="form-group u-mb-none">
    <h4>{{ $t('components.custom-exercise-form.materials') }}</h4>

    <div class="u-mb" v-if="materialsLoading">
      {{ $t('general.loading') }}
      <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div v-else class="c-column">
      <div v-for="material in displayedMaterials"
        :key="material.id" class="checkbox u-mt-none u-mb">
        <label>
          <input type="checkbox"
            name="selected_materials[]"
            v-model="checkedMaterials" :value="material.wp_id"
            data-test-id="custom-exercise-material-checkbox">
          <div class="o-media o-media--small">
            <img class="o-media__img" :src="material.thumbnail_path" alt="">
            <div class="o-media__body">
              <div>{{ material.title }}</div>
              <div class="u-color-mid-gray">{{ $t(`materials.types.singular.${material.type}`) }}</div>
              <div>
                <a :href="`/materials#/${material.id}`">
                  {{ $t('components.custom-exercise-form.show_material') }}
                </a>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>

  <div class="form-group">
    <h4 class="u-mt-none">
      {{ $t('components.custom-exercise-form.groups') }}
    </h4>

    <div v-for="group in parsedUserGroups"
      :key="group.id" class="u-mt-none u-mb-small">
      <label>
        <input type="checkbox"
          name="selected_groups[]"
          v-model="checkedGroups" :value="group.id"
          :data-test-id="`custom-exercise-group-checkbox-${group.id}`">
        {{ group.name }}
      </label>
    </div>
  </div>

  <div class="form-group u-mt-large">
    <button type="submit"
      class="btn btn-primary"
      :disabled="!valid"
      data-test-id="custom-exercise-submit-button">
      {{ $t('general.save') }}
    </button>
  </div>
</form>
</template>

<style scoped>
.o-media__img {
  width: calc(var(--base-unit) * 2);
}

.c-column {
  columns: calc(var(--base-unit) * 15);
}
</style>
