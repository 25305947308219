<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';

import { useMapStore } from './map_store';
import BiographyMap from './BiographyMap.vue';

export default defineComponent({
  components: {
    BiographyMap,
  },
  props: {
    bioMapJson: String,
    mapSettingJson: String,
    imageUrl: String,
  },
  methods: {
    ...mapActions(useMapStore, ['initializeMapData']),
  },
  beforeMount() {
    this.initializeMapData(this.bioMapJson, this.mapSettingJson, this.imageUrl);
  },
});
</script>

<template>
<BiographyMap skip-heading />
</template>
