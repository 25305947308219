<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>

<template>
<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="-281 404.9 32 32" enable-background="new -281 404.9 32 32" xml:space="preserve"
  width="26" height="26">
  <g id="Grid" display="none">
    <g display="inline">
      <g opacity="0.1">
        <g>
          <g>
            <rect x="-25" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-89" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-153" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-217" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-281" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-345" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-409" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-473" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-537" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-601" y="532.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-665" y="532.9" width="32" height="32"/>
          </g>
        </g>
        <g>
          <g>
            <rect x="-25" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-89" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-153" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-217" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-281" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-345" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-409" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-473" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-537" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-601" y="468.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-665" y="468.9" width="32" height="32"/>
          </g>
        </g>
        <g>
          <g>
            <rect x="-25" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-89" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-153" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-217" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-281" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-345" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-409" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-473" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-537" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-601" y="404.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-665" y="404.9" width="32" height="32"/>
          </g>
        </g>
        <g>
          <g>
            <rect x="-25" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-89" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-153" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-217" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-281" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-345" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-409" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-473" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-537" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-601" y="340.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-665" y="340.9" width="32" height="32"/>
          </g>
        </g>
        <g>
          <g>
            <rect x="-25" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-89" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-153" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-217" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-281" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-345" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-409" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-473" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-537" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-601" y="276.9" width="32" height="32"/>
          </g>
          <g>
            <rect x="-665" y="276.9" width="32" height="32"/>
          </g>
        </g>
      </g>
    </g>
  </g>
  <g id="Workspace">
    <g>
      <path fill="#ffffff" d="M-252.5,411.3c0,0-11.5-4.6-12.6-5c-0.7-0.3-3.3-0.6-5.2,0.4c-0.9,0.5-1.6,1.2-1.9,2.3
        c-1.3,0-2.8,0.3-3.9,1.1c-0.5,0.4-1.1,1.2-1.2,2.4l0,18.2c0,0,11.7,4.5,12.6,4.8c1,0.3,2.7,0.2,2.7,0.2v-17.2l-14.1-5.6
        c0-0.9,0.2-1.5,0.8-2c1.4-1.1,4.3-1,5-0.7c1,0.4,10.4,4.2,12.4,4.9v18.5l1-0.5v-18.7c0,0-12-4.7-13-5.2c-0.2-0.1-0.6-0.2-1.1-0.2
        c0.2-0.7,0.6-1.2,1.2-1.5c1.6-0.8,4-0.5,4.5-0.4c1,0.4,10.4,4.2,12.4,4.9V431l1-0.5v-19.1L-252.5,411.3z"/>
    </g>
  </g>
</svg>
</template>
