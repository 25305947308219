import videojs from 'video.js';

function clipPlugin(player: videojs.Player, options): void {
  const clipButton = createButton('&#9986;');

  clipButton.onclick = function handleClipButtonClick() {
    if (typeof options.toggle === 'function') {
      options.toggle();
    }
  };

  const playbackRateButton = document.querySelector('.vjs-subs-caps-button');
  insertAfter(clipButton, playbackRateButton);

  window.addEventListener('own-router:exercise:enter', updateButtonVisibility);
  window.addEventListener('own-router:exercise:exit', updateButtonVisibility);
  player.on('fullscreenchange', updateButtonVisibility);
  updateButtonVisibility();

  function createButton(icon: string) {
    const button = document.createElement('button');
    button.classList.add('vjs-menu-button');
    button.innerHTML = icon;
    button.style.fontSize = '20px';
    button.style.display = 'none';
    button.ariaLabel = options.label;
    button.title = options.label;
    return button;
  }

  function insertAfter(newEl: HTMLElement, el: Element | null) {
    el?.parentNode?.insertBefore(newEl, el.nextSibling);
  }

  function updateButtonVisibility() {
    if (isExerciseDetailRoute() && !player.isFullscreen()) {
      showClipButton();
    } else {
      hideClipButton();
    }
  }

  function showClipButton() {
    clipButton.style.display = 'block';
  }

  function hideClipButton() {
    clipButton.style.display = 'none';
  }
}

function isExerciseDetailRoute() {
  const { hash } = window.location;
  const isExerciseHashRegexp = /^#\/exercises\/\d+$/;

  return isExerciseHashRegexp.test(hash);
}

videojs.registerPlugin('clipPlugin', clipPlugin);
