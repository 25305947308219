<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import SearchInput from '~/vue/SearchInput.vue';
import DictionaryEntry from './DictionaryEntry.vue';
import { useDictionaryStore } from './dictionary_store';

export default defineComponent({
  components: {
    SearchInput,
    DictionaryEntry,
  },
  props: {
    skipHeading: Boolean,
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    ...mapState(useDictionaryStore, ['entries']),
    filteredEntries() {
      const entries = this.entries;
      const term = this.searchTerm.trim().toLowerCase();
      return entries.filter((entry) => {
        return entry.table.title.toLowerCase().includes(term)
          || entry.table.short_entry_text.toLowerCase().includes(term)
          || entry.table.long_entry_text.toLowerCase().includes(term);
      });
    },
  },
  methods: {
    handleLinkClick(event: Event) {
      event.preventDefault();
      const target = event.target as HTMLAnchorElement;

      if (target.tagName === 'A' && target.href) {
        this.scrollToEntry(target.getAttribute('href')?.slice(1));
      }
    },
    scrollToEntry(htmlId: string) {
      this.searchTerm = '';

      this.$nextTick(() => {
        const el = document.getElementById(htmlId);
        el?.scrollIntoView({ behavior: 'smooth' });
      });
    },
  },
});
</script>

<template>
<div>
  <h2 v-if="!skipHeading" class="heading heading--large u-mb">
    {{ $t('components.menu-section.dictionary') }}
  </h2>
  <SearchInput v-model="searchTerm" />
  <ul class="list">
    <li class="list__item" v-for="entry in filteredEntries">
      <a @click="handleLinkClick"
        :key="entry.table.slug"
        :href="`#${entry.table.slug}`"
        v-html="entry.table.title">
      </a>
    </li>
  </ul>

  <DictionaryEntry
    v-for="entry in filteredEntries"
    :key="entry.table.slug"
    :entry="entry"
    :onLinkClick="handleLinkClick"
  />
</div>
</template>

<style scoped>
.list {
  list-style-type: none;
  max-height: calc(10 * 24px);
  overflow: auto;
  padding: 0;
  width: calc(15 * 24px);
}
.list__item {
  line-height: 24px;
}
</style>
