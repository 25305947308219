import $ from 'jquery';

import OlpThematicMap from '../thematic-map/olp-thematic-map';

// todo: beim import holen (map-data.json, liegen unter maps-instanz. liegen in eigener maps-instanz. unter slug speichern, nicht unter postID)
//return '/map-data.json?histmap=' + MapName + '&lang=' + Language
$(() => {
  const $el = $('#olp-tmap');
  const mapId = $el.data('map-id');
  const lang = document.documentElement.getAttribute('lang');

  if (!mapId) {
    return;
  }

  const OlpTmap = new OlpThematicMap(
    mapId,
    $el,
    lang,
    (mapName: string, language: string) => `/mapdata/${language}/${mapName}.json`,
    52.5075419,
    13.4251364,
    4,
    function (postID, callback) {
      $.get(`/tmapdata/tmap-data-${postID}.json`, function (tmapdata) {
        callback(tmapdata.values)
      }, 'json');
    });
});
