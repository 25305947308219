<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { SearchResults } from '../types';

export default defineComponent({
  props: {
    searchTerm: String,
    type: String,
    results: {
      type: Array as PropType<SearchResults>,
      required: true,
    },
  },
});
</script>

<template>
<div class="accordion">
  <button :aria-controls="`${type}-search`"
    aria-expanded="false" class="accordion__button"
    data-parent="#accordion" :data-target="`#${type}-search`"
    data-toggle="collapse" role="tab" type="button">
    <h4 class="accordion__heading">
      <strong>
        {{ $t(`searchResultTypes.${type}`) }}
      </strong>
      –
      <span v-text="results.length"></span>
      {{ $t('components.resultList.search_results') }}
      {{ $t('components.resultList.for') }}
      "<span v-text="searchTerm"></span>"
    </h4>
  </button>
  <div :aria-labelledby="`${type}-search`"
    :id="`${type}-search`"
    class="accordion__panel collapse"
    v-if="results.length > 0" role="tabpanel">
    <div class="panel-body">
      <ul style="padding-left: 24px">
        <li v-for="result in results">
          <a v-html="result.title"
            v-bind:href="result.href"
            v-if="result.href"></a>
          <div v-html="result.title"
            v-if="!result.href"></div>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>
