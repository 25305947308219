<script lang="ts">
import $ from 'jquery';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { TimelineEntry } from '../types';

export default defineComponent({
  props: {
    entry: {
      type: Object as PropType<TimelineEntry>,
      required: true,
    },
  },
  methods: {
    addClickHandler() {
      $(this.$el).on('click', function() {
        $(this).find('.timeline-body').toggle('slow');
      });
    }
  },
  mounted() {
    this.addClickHandler();
  },
});
</script>

<template>
<button class="timeline-card timeline-panel-element"
  :data-test-id="`entry-${entry.id}-button`">
  <div class="timeline-heading">
    <p>
      <small class="text-muted">
        <i class="fa fa-calendar"></i> <span>{{ entry.month_day }}</span> <span>{{ entry.year }}</span>
      </small>
      <span class="label" :class="entry.timeline_class">
        {{ entry.timeline_title }}
      </span>
    </p>
    <h4 class="timeline-card__title">
      {{ entry.title }}
    </h4>
  </div>
  <div class="timeline-body">
    <div v-html="entry.description"></div>
  </div>
</button>
</template>
