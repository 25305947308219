<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';

import timeToSeconds from './timeToSeconds';
import { useVideoStore } from './video_store';

function mapSegmentTimes(segment) {
  return {
    ...segment,
    startInSeconds: timeToSeconds(segment.start),
    endInSeconds: timeToSeconds(segment.end),
  };
}

function scrollToSegment(type: string, id: number) {
  const htmlId = `${type}-segment-${id}`;
  const parent = document.getElementById(`${type}-container`);
  const element = document.getElementById(htmlId);
  if (!element || !parent) {
    return;
  }
  $(parent).animate({
    scrollTop: (element.offsetTop - parent.offsetTop),
  }, 1000);
}

export default defineComponent({
  props: { type: String },
  data() {
    return {
      searchTerm: '',
      segments: [],
      currentIndex: 0,
      error: false,
    };
  },
  methods: {
    ...mapActions(useVideoStore, ['changeVideoTime']),
    handleSegmentClick(segment) {
      this.changeVideoTime(segment.start);
    },
    fetchTranscript() {
      fetch(`${window.location.pathname}/${this.type}.json`)
        .then((response) => response.json())
        .then((data) => {
          const transformedSegments = data.cues.map(mapSegmentTimes);
          this.segments = transformedSegments;
        })
        .catch((error) => {
          this.error = true;
        });
    },
    resetSearch() {
      this.searchTerm = '';
    },
    updateScrollPosition() {
      // Use a timeout for now so that it works in combination with the
      // Bootstrap tabs.
      setTimeout(() => {
        scrollToSegment(this.type, this.currentSegment.identifier);
      }, 250);
    },
  },
  computed: {
    ...mapState(useVideoStore, ['currentTime']),
    filteredSegments() {
      const searchTerm = this.searchTerm.trim().toLowerCase();

      if (searchTerm === '') {
        return this.segments;
      }

      return this.segments
        .filter((segment) => segment.text.toLowerCase().includes(searchTerm))
        .map((segment) => ({
          ...segment,
          text: segment.text.replace(
            new RegExp(searchTerm, 'gi'),
            '<mark class="highlightedText">$&</mark>',
          ),
        }));
    },
    currentSegment() {
      return this.segments[this.currentIndex];
    },
  },
  watch: {
    currentTime(val) {
      const oldIndex = this.currentIndex;

      const newIndex = this.segments.findIndex(
        (segment) => segment.startInSeconds <= this.currentTime
          && this.currentTime < segment.endInSeconds,
      );

      if (oldIndex !== newIndex) {
        this.currentIndex = newIndex;
        this.updateScrollPosition();
      }
    },
  },
  mounted() {
    this.fetchTranscript();
  },
});
</script>

<template>
  <div class="input-group input-group-sm u-mt-small">
    <span class="input-group-addon">
      <span class="fa fa-search"></span>
    </span>
    <input class="form-control" type="text"
      v-model="searchTerm"
      @keydown.esc="resetSearch"
      :placeholder="$t('components.video-player.searchTranscript')">
  </div>
  <div :id="`${type}-container`" class="transcript">
    <button type="button"
      :id="`${type}-segment-${segment.identifier}`"
      v-for="segment in filteredSegments"
      :key="segment.identifier"
      v-html="segment.text"
      @click="handleSegmentClick(segment)"
      class="segment"
      :class="{ 'segment--active': segment.identifier === currentSegment.identifier }">
    </button>
  </div>
</template>

<style scoped>
.transcript {
  height: calc(var(--base-unit) * 20);
  overflow-y: scroll;
}
.segment {
  background-color: transparent;
  border: none;
  font-size: var(--font-size-small);
  line-height: var(--base-unit);
  margin: 0;
  padding: calc(var(--base-unit) / 4) calc(var(--base-unit) / 4);
  text-align: left;
}

.segment--active {
  background-color: var(--bg-color-warning);
}
</style>
