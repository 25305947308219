<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isLoading: Boolean,
    onSubmit: Function,
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    submitDisabled() {
      const query = this.searchInput.trim();
      return query === '';
    },
  },
  methods: {
    clearSearchInput() {
      this.searchInput = '';
    },
    handleFormSubmit(event: Event) {
      event.preventDefault();
      const query = this.searchInput.trim();

      this.onSubmit(query);
    }
  },
});
</script>

<template>
<form @submit.prevent="handleFormSubmit" class="form-inline">
  <div class="form-group">
    <div class="input-group input-group-sm">
      <div class="input-group-addon">
        <i class="fa fa-search"></i>
      </div>
      <input type="text"
        class="form-control" id="search-input"
        data-test-id="search-input"
        v-model="searchInput"
        :placeholder="$t('components.searchInput.search')"
        @keydown.esc="clearSearchInput">
    </div>
  </div>

  <button type="submit"
    v-show="!isLoading"
    class="btn btn-primary btn-sm u-ml-tiny"
    data-test-id="search-button"
    @click="handleFormSubmit"
    :disabled="submitDisabled">
    {{ $t('general.submit') }}
  </button>
  <span v-show="isLoading"
    class="search-bar__loading-indicator u-ml-tiny">
    {{ $t('components.searchInput.loading') }}
    <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
  </span>
</form>
</template>
