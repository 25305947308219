<script lang="ts">
import { defineComponent } from 'vue';
import { mapWritableState } from 'pinia';

import BiographyMap from '../map/BiographyMap.vue';
import DefinitionDictionary from '../dictionary/DefinitionDictionary.vue';
import MaterialsComponent from '../materials/MaterialsComponent.vue';
import SiteSearch from '../search/SiteSearch.vue';
import TimelineComponent from '../timeline/TimelineComponent.vue';
import ExerciseComponent from './ExerciseComponent.vue';
import TaskBlocks from './TaskBlocks.vue';
import { useExercisesStore } from './exercises_store';

export default defineComponent({
  components: {
    BiographyMap,
    DefinitionDictionary,
    ExerciseComponent,
    MaterialsComponent,
    SiteSearch,
    TaskBlocks,
    TimelineComponent,
  },
  computed: {
    ...mapWritableState(useExercisesStore, ['currentExerciseId']),
  },
  methods: {
    redirectFromRootToExercises(newRoute) {
      setTimeout(() => {
        if (newRoute.path === '/') {
          this.$router.replace('/exercises');
        }
      }, 125);
    },
    setCurrentExerciseId(newRoute) {
      this.currentExerciseId = Number(newRoute.params.id);
    },
    dispatchEvent(eventName: string) {
      this.$nextTick(() => {
        const event = new Event(eventName);
        window.dispatchEvent(event);
      });
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      this.redirectFromRootToExercises(newRoute);
      this.dispatchEvent('exercise-changed');

      if (newRoute.name === 'exercise-detail' && oldRoute.name !== 'material-detail') {
        this.setCurrentExerciseId(newRoute);
        this.dispatchEvent('own-router:exercise:enter');
      }
      if (oldRoute.name === 'exercise-detail') {
        this.dispatchEvent('own-router:exercise:exit');
      }
      if (newRoute.name === 'biomap') {
        this.dispatchEvent('own-router:redraw-map');
      }
      if (newRoute.name === 'materials' && oldRoute.name !== 'material-detail') {
        this.dispatchEvent('own-router:set-materials-scope');
      }
    }
  },
});
</script>

<template>
<BiographyMap v-show="$route.name === 'biomap'" />
<DefinitionDictionary v-show="$route.name === 'dictionary'" />
<ExerciseComponent v-show="$route.name === 'exercise-detail'" />
<MaterialsComponent v-show="$route.name === 'materials' || $route.name === 'material-detail'" />
<SiteSearch v-show="$route.name === 'search'" />
<TaskBlocks v-show="$route.name === 'exercises'" />
<TimelineComponent v-show="$route.name === 'timeline'" />
</template>
