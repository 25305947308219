const metaTag = document.querySelector('meta[name="csrf-token"]');
const csrfToken = metaTag?.getAttribute('content') as string;

const headers = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken,
  Accept: 'application/json',
};

export interface FeedbackResponse {
  result: string
}

export default async function submitFeedback(feedbackText: string): Promise<FeedbackResponse> {
  const path = '/feedbacks';

  const response = await fetch(path, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      text: feedbackText,
    })
  });

  return response.json();
}
