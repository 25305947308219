import {
  Map,
  MarkerClusterGroup,
  control,
  divIcon,
  geoJSON,
  icon,
  latLng,
  layerGroup,
  map,
  marker,
  polyline,
  tileLayer,
  markerClusterGroup,
  IconOptions,
  LatLng,
  LayerGroup,
} from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowIconUrl from 'leaflet/dist/images/marker-shadow.png';
import iconUrl2 from 'leaflet/dist/images/marker-icon-2x.png';
// Layer icons have to be present in public folder.
import 'leaflet/dist/leaflet.css';

import fetchHistoricalLayer from './fetchHistoricalLayer';
import fetchPoland1939Layer from './fetchPoland1939Layer';
import { Marker, LayerSetting, FeatureCollection } from '../types';

import {
  OSM_LAYER,
  ESRI_LAYER,
  GOOGLE_LAYER,
  GEOJSON_LAYER_OPTIONS,
  PATH_LINE_OPTIONS,
  POLAND_1939_LAYER_OPTIONS,
} from './mapInstanceOptions';

const LAYER_TODAY = 'today';

const ICON_OPTIONS: IconOptions = {
  iconUrl: iconUrl,
  iconRetinaUrl: iconUrl2,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -40],
  shadowUrl: shadowIconUrl,
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
};

const MARKER_CLUSTER_GROUP_OPTIONS = {
  spiderfyOnMaxZoom: true,
  showCoverageOnHover: true,
  zoomToBoundsOnClick: true,
  maxClusterRadius: 15,
};

type MapInstanceOptions = {
  htmlId: string,
  layers: Array<LayerSetting>,
  onActiveMarker?: (markerId: string | null) => void;
  drawPaths?: boolean;
  initialCenter?: [number, number],
  initialZoom?: number,
  showPoland1939?: boolean,
  todayIsDefault?: boolean,
  fitToMarkers?: boolean,
  useGoogleLayer?: boolean,
};

const defaultOptions = {
  drawPaths: false,
  showPoland1939: false,
  todayIsDefault: false,
  fitToMarkers: false,
  useGoogleLayer: false,
};

export default class MapInstance {
  map: Map;
  options: MapInstanceOptions;
  historicalLayerLoaded: object;
  layerGroups: object;
  markersByNumber: object;
  clusterGroup: MarkerClusterGroup;
  filteredMarkers: Array<Marker>;

  constructor(options: MapInstanceOptions) {
    this.options = {
      ...defaultOptions,
      ...options,
    };
    this.historicalLayerLoaded = {};
    this.layerGroups = {};
    this.markersByNumber = {};
    this.#setup();
  }

  addMarkers(markers: Array<Marker>) {
    const { onActiveMarker, drawPaths, fitToMarkers } = this.options;

    const map = this.map;
    const customIcon = icon(ICON_OPTIONS);
    this.clusterGroup = markerClusterGroup(MARKER_CLUSTER_GROUP_OPTIONS);

    const filteredMarkers = markers.filter((m: Marker) => {
      const lat = Number(m.location.lat);
      const lng = Number(m.location.lng);

      if (Number.isNaN(lat) || Number.isNaN(lng)) {
        console.info(`Skipping marker number ${m.number} with invalid geo coordinates in chapter ${m.chapter}.`);
        return false;
      }

      return true;
    });

    this.filteredMarkers = filteredMarkers;

    filteredMarkers.forEach((m: Marker) => {
      const lat = Number(m.location.lat);
      const lng = Number(m.location.lng);

      const markerOptions = {
        icon: customIcon,
        alt: m.title,
        autoPanOnFocus: true,
      };

      const newMarker = marker(
        [lat, lng],
        markerOptions
      );

      let popupContent: string;
      if (m.chapter) {
        popupContent = `<div class="map-popup">
  <a class="map-popup__link" href="${m.chapterPath}">${m.chapter}</a></br>
  <b>${m.date}:</b> ${m.title}
</div>`;
      } else {
        popupContent = `<div class="map-popup">
  <b>${m.date}:</b> ${m.title}
</div>`;
      }

      newMarker.bindPopup(popupContent);
      newMarker.on('popupopen', () => {
        if (typeof onActiveMarker === 'function') {
          onActiveMarker(m.number);
        }
      });
      newMarker.on('popupclose', () => {
        if (typeof onActiveMarker === 'function') {
          onActiveMarker(null);
        }
      });

      this.markersByNumber[m.number] = newMarker;

      this.clusterGroup.addLayer(newMarker);
    });

    map.addLayer(this.clusterGroup);

    if (drawPaths) {
      this.addPaths(filteredMarkers);
    }

    if (fitToMarkers) {
      const bounds = filteredMarkers.map((marker: Marker) => {
        return [
          Number(marker.location.lat),
          Number(marker.location.lng)
        ];
      });

      map.fitBounds(bounds, {
        maxZoom: 10,
      });
    }
  }

  addPaths(markers: Array<Marker>) {
    const latlngs = markers.map((m: Marker) =>
      latLng(Number(m.location.lat), Number(m.location.lng))
    );
    const line = polyline(latlngs, PATH_LINE_OPTIONS).addTo(this.map);
  }

  focusMarker(markerId: string) {
    const marker = this.markersByNumber[markerId];
    this.clusterGroup.zoomToShowLayer(marker, () => {
      marker.togglePopup();
    });
  }

  #setup() {
    const { htmlId, showPoland1939, fitToMarkers } = this.options;

    let center: LatLng | undefined;
    if (this.options.initialCenter) {
      center = latLng(this.options.initialCenter);
    } else {
      center = latLng([50, 15])
    }

    this.map = map(htmlId, {
      center,
      zoom: this.options.initialZoom || 5,
      minZoom: 3,
      maxZoom: 19,
      scrollWheelZoom: false,
      attributionControl: false,
    });

    this.#setupLayers();
    if (showPoland1939) {
      this.#setupPoland1939Layer();
    }
    this.#setupEventListeners();

    window.addEventListener('own-router:redraw-map', () => {
      this.map.invalidateSize();

      if (fitToMarkers && this.filteredMarkers) {
        const bounds = this.filteredMarkers.map((marker: Marker) => {
          return [
            Number(marker.location.lat),
            Number(marker.location.lng)
          ];
        });

        this.map.fitBounds(bounds, {
          maxZoom: 10,
        });
      }
    });
  }

  #setupLayers() {
    const { layers, todayIsDefault, useGoogleLayer } = this.options;

    const todayLayerData = useGoogleLayer ? GOOGLE_LAYER : OSM_LAYER;
    const todayLayer = tileLayer(todayLayerData.url, todayLayerData.options);
    const historicalLayer = tileLayer(ESRI_LAYER.url, ESRI_LAYER.options);

    // Setup control
    const baseMapsForControl = {};

    layers.forEach((layer: LayerSetting) => {
      let group: LayerGroup;
      if (layer.name === LAYER_TODAY) {
        group = layerGroup([todayLayer]);
      } else {
        group = layerGroup([historicalLayer]);
      }
      baseMapsForControl[layer.label] = group;
      this.layerGroups[layer.name] = group;
    });

    const layerControl = control.layers(baseMapsForControl, undefined, {
      collapsed: false,
      hideSingleBase: false,

    });
    layerControl.addTo(this.map);

    // Add default layer to map.
    const index = todayIsDefault ? layers.length - 1 : 0;
    const defaultLayerGroup = baseMapsForControl[layers[index].label];
    this.map.addLayer(defaultLayerGroup);
    if (layers[index].name !== LAYER_TODAY) {
      this.#loadHistoricalMapData(layers[index].name);
    }
  }

  async #setupPoland1939Layer() {
    const layerData = await fetchPoland1939Layer();
    const borders = geoJSON(layerData, POLAND_1939_LAYER_OPTIONS);

    Object.entries(this.layerGroups).forEach(([name, group]) => {
      if (name === LAYER_TODAY) {
        return;
      }
      borders.addTo(group);
    });
  }

  #setupEventListeners() {
    const { htmlId, layers } = this.options;

    this.map.on('baselayerchange', (e) => {
      const label = e.name;
      const layer = layers.find((layer) => layer.label === label);
      if (!layer) {
        throw new Error(`Could not find layer ${e.name}`);
      }

      if (layer.name === LAYER_TODAY) {
        return;
      }

      if (this.historicalLayerLoaded[layer.name]) {
        return;
      }

      this.#loadHistoricalMapData(layer.name);
    });

    this.map.on('zoomend', (e) => {
      const zoomLevel = this.map.getZoom();
      const el = document.getElementById(htmlId);
      if (zoomLevel < 5) {
        el?.classList.add('map--labels-hidden');
      } else {
        el?.classList.remove('map--labels-hidden');
      }
    });
  }

  async #loadHistoricalMapData(name: string) {
    this.historicalLayerLoaded[name] = true;
    const layerData: FeatureCollection = await fetchHistoricalLayer(name);
    const group = this.layerGroups[name];

    const onEachFeature = (feature, layer) => {
      const coords = layer.getBounds().getCenter();
      const label = marker(coords, {
        icon: divIcon({
          iconSize: [10, 10],
          className: 'country-label-test',
        }),
      })
        .bindTooltip(feature.properties.ZWAR_NAME, {
          permanent: true,
          direction: 'center',
          className: 'country-label',
        });
      label.addTo(group);
    };

    const geoJSONLayer = geoJSON(layerData, {
      ...GEOJSON_LAYER_OPTIONS,
      onEachFeature,
    });

    geoJSONLayer.addTo(group);
  }
}
