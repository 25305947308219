<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    entry: Object,
    onLinkClick: Function,
  },
});
</script>

<template>
<div :id="entry.table.slug">
  <h3 v-html="entry.table.title"></h3>

  <div @click="onLinkClick" v-html="entry.table.short_entry_text"></div>

  <div v-if="entry.table.long_entry_text"
    @click="onLinkClick"
    class="back-to long_entry_text_button"
    aria-controls="longEntryText" aria-expanded="false"
    :data-target="`#longEntryText-${entry.table.slug}`"
    data-toggle="collapse">
    <span class="back-to-inner">
      <span class="glyphicon glyphicon-asterisk" aria-hidden="true"></span>
      &nbsp;
      <small>{{ $t('components.dictionaryEntry.longText') }}</small>
    </span>
  </div>
  <div class="collapse" :id="`longEntryText-${entry.table.slug}`">
    <div v-html="entry.table.long_entry_text"></div>
  </div>
</div>
</template>
