import $ from 'jquery';

const IMAGE_TRANSITION_DELAY = 300;
const SECTION_TRANSITION_DELAY = 800;

$(() => {
  addImageTransitionListeners();
  addActivateSectionListeners();
  activateFirstSection();
});

function addImageTransitionListeners() {
  $('.chapter').on('mouseenter', function() {
    $(this).find('.chapter-image-primary').stop().animate({
      opacity: 0
    }, IMAGE_TRANSITION_DELAY);
    $('.chapter-info').css({visibility: 'visible'}).addClass('animated pulse');
    $('.chapter-title').html( $(this).data('title') );
    $('.chapter-subtitle').html( $(this).data('subtitle') );
  });

  $('.chapter').on('mouseleave', function () {
    $(this).find('.chapter-image-primary').stop().animate({
      opacity: 1
    }, IMAGE_TRANSITION_DELAY);
    $('.chapter-info').css({visibility: 'hidden'}).removeClass('animated pulse');
    $('.chapter-title').html('');
    $('.chapter-subtitle').html('');
  });
}

function addActivateSectionListeners() {
  $('.section-nav-wrapper.startpage .section-nav-element').on('click', function() {
    if ($(this).hasClass('disabled')) {
      return;
    }

    handleSectionLinkClick(this as HTMLAnchorElement);
  });
}

function handleSectionLinkClick(anchor: HTMLAnchorElement){
  const slug = $(anchor).data('identifier');
  const $section = $(`.section#${slug}`).not('active');

  disableAnchors();

  activateSection($section);

  $(anchor).addClass('active');
  $(anchor).siblings().removeClass('active');
};

function activateFirstSection() {
  const firstSectionLink = $('.section-nav-wrapper.startpage .section-nav-element')[0] as HTMLAnchorElement;

  if (firstSectionLink) {
    handleSectionLinkClick(firstSectionLink);
  } else {
    const $section = $('.sections .section').first();
    activateSection($section);
  }
}

function activateSection($section: JQuery) {
  $section.addClass('active')
    .css('z-index', '2')
    .css('opacity', '1')
    .animate({ top: '0px' }, SECTION_TRANSITION_DELAY);

  $section.siblings('.section')
    .removeClass('active')
    .css('z-index', '1')
    .animate({ top: '-255px', opacity: '0' }, SECTION_TRANSITION_DELAY, enableAnchors);
}

function disableAnchors() {
  $('.section-nav-element').addClass('disabled');
}

function enableAnchors() {
  $('.section-nav-element').removeClass('disabled');
}
