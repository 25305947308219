import videojs, { VideoJsPlayerOptions } from 'video.js';

const videoPlayerId = 'video-startpage';
const playerOptions: VideoJsPlayerOptions = {
  fluid: true,
  controls: true,
  preload: 'auto',
  controlBar: {
    children: [
      'playToggle',
      'progressControl',
      'volumePanel',
      'qualitySelector',
      'fullscreenToggle',
    ],
  },
};

document.addEventListener('DOMContentLoaded', () => {
  const videoPlayerEl = document.getElementById(videoPlayerId);
  if (!videoPlayerEl) {
    return;
  }
  videojs(videoPlayerId, playerOptions);
});
