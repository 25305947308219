import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
  state: () => ({
    _teacherMode: undefined,
  }),
  getters: {
    teacherMode() {
      if (this._teacherMode === undefined) {
        this._teacherMode = teacherModeFromStorage();
      }
      return this._teacherMode;
    },
  },
  actions: {
    toggleTeacherMode() {
      this._teacherMode = !this._teacherMode;
      saveTeacherModeToStorage(this._teacherMode);
    },
  },
});

function teacherModeFromStorage() {
  const defaultValue = true;
  const storedValue = window.localStorage.getItem('teacher-content-visible');
  if (storedValue !== null) {
    return JSON.parse(storedValue);
  } else {
    return defaultValue;
  }
}

function saveTeacherModeToStorage(mode: boolean) {
  window.localStorage.setItem('teacher-content-visible', JSON.stringify(mode));
}
