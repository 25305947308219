import $ from 'jquery';
import fitty from 'fitty';

$(() => {
  const SECTION_NAV_ID = 'section-nav';
  const $el = $(`#${SECTION_NAV_ID}`);
  if ($el.length === 0) {
    return;
  }

  const fittyInstance = fitty(`#${SECTION_NAV_ID}`, {
    maxSize: 55,
    minSize: 14,
  });

  // Schedule a re-fit operation on the next animation frame.
  window.requestAnimationFrame(() => {
    fittyInstance[0]?.fit();
  });
});
