const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  'X-User-Id': current_user_id,
  'X-User-Token': current_user_token
};

export async function saveUsersExercise(exerciseWpId: number, text: string, relatedText: string) {
  const locationPath = window.location.pathname;
  const path = `${locationPath}/users_exercises.json`;

  const data = {
    users_exercise: {
      user_id: current_user_id,
      exercise_id: exerciseWpId,
      text: text,
      related_text: relatedText
    },
  };

  try {
    const response = await fetch(path, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateUsersExercise(usersExerciseId: number, text: string, relatedText: string) {
  const locationPath = window.location.pathname;
  const path = `${locationPath}/users_exercises/${usersExerciseId}.json`;

  const data = {
    users_exercise: {
      text: text,
      related_text: relatedText
    },
  };

  try {
    const response = await fetch(path, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (e) {
    console.log(e.message);
  }
}

export async function deleteUsersExercise(usersExerciseId: number) {
  const locationPath = window.location.pathname;
  const path = `${locationPath}/users_exercises/${usersExerciseId}.json`;

  try {
    const response = await fetch(path, {
      method: 'DELETE',
      headers,
    });
    const result = await response.json();
    return result;
  } catch (e) {
    console.log(e.message);
  }
}
