<script lang="ts">
import { defineComponent } from 'vue';
import { mapWritableState, mapActions } from 'pinia';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import videojsQualitySelector from '@silvermine/videojs-quality-selector';
import langDe from 'video.js/dist/lang/de.json';
import langCs from 'video.js/dist/lang/cs.json';
import langEl from 'video.js/dist/lang/el.json';
import langNl from 'video.js/dist/lang/nl.json';
import langPl from 'video.js/dist/lang/pl.json';
import langRu from 'video.js/dist/lang/ru.json';
import 'video.js/dist/video-js.css';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';

import { useVideoStore } from './video_store';
import { Transcript, Video } from '../types';
import ClipForm from './ClipForm.vue';
import './clip-plugin.ts';

videojsQualitySelector(videojs);
videojs.addLanguage('de', langDe);
videojs.addLanguage('cs', langCs);
videojs.addLanguage('el', langEl);
videojs.addLanguage('nl', langNl);
videojs.addLanguage('pl', langPl);
videojs.addLanguage('ru', langRu);

const VIDEOJS_OPTIONS: VideoJsPlayerOptions = {
  fluid: true,
  controls: true,
  preload: 'auto',
  controlBar: {
    children: [
      'playToggle',
      'progressControl',
      'volumePanel',
      'subsCapsButton',
      'qualitySelector',
      'fullscreenToggle'
    ],
  },
};

export default defineComponent({
  components: {
    ClipForm,
  },
  props: {
    video: String,
    title: String,
    transcription: String,
    translation: String,
    startTime: String,
    endTime: String,
  },
  data() {
    return {
      stoppedOnce: false,
    };
  },
  computed: {
    ...mapWritableState(useVideoStore, [
      'duration',
      'currentTime',
      'player',
      'videoLoaded',
      'isPlaying',
      'clipPanelIsOpen',
    ]),
    parsedVideo(): Video {
      return JSON.parse(this.video);
    },
    parsedTranscription(): Transcript | null {
      try {
        return JSON.parse(this.transcription);
      } catch (e) {
        return null;
      }
    },
    parsedTranslation(): Transcript | null {
      try {
        return JSON.parse(this.translation);
      } catch (e) {
        return null;
      }
    },
    parsedStartTime() {
      try {
        return JSON.parse(this.startTime);
      } catch (e) {
        return null;
      }
    },
    parsedEndTime() {
      try {
        return JSON.parse(this.endTime);
      } catch (e) {
        return null;
      }
    },
    origStreamPrefix() {
      return this.parsedVideo.has_both_streams
        ? (this.parsedVideo.langcode?.toUpperCase() || this.$t('components.video-player.ov'))
        : '';
    },
    dubbedStreamPrefix() {
      return this.parsedVideo.has_both_streams
        ? (this.parsedVideo.langcode_voiceover?.toUpperCase() || this.$t('components.video-player.tr'))
        : '';
    },
  },
  methods: {
    ...mapActions(useVideoStore, []),
    origStreamLabel(resolution: number): string {
      return `${this.origStreamPrefix} ${resolution}p`;
    },
    dubbedStreamLabel(resolution: number): string {
      return `${this.dubbedStreamPrefix} ${resolution}p`;
    },
    initializePlayer() {
      const options = {
        ...VIDEOJS_OPTIONS,
        poster: this.parsedVideo?.poster_image_link,
      };

      const player = videojs('olp-video-player', options);
      const vueComponent = this;

      player.on('ready', function() {
        this.clipPlugin(this, {
          label: vueComponent.$t('components.video-player.clip'),
          toggle: () => {
            vueComponent.clipPanelIsOpen = !vueComponent.clipPanelIsOpen;
          },
        });

        vueComponent.player = this;
      });

      player.on('loadedmetadata', function() {
        vueComponent.duration = this.duration();
        vueComponent.videoLoaded = true;

        // This is only used with the single video template.
        if (vueComponent.parsedStartTime) {
          this.currentTime(vueComponent.parsedStartTime);
        }
      });

      player.on('timeupdate', function() {
        vueComponent.currentTime = this.currentTime();

        // This is only used with the single video template.
        if (vueComponent.parsedEndTime
          && vueComponent.stoppedOnce === false
          && vueComponent.currentTime >= vueComponent.parsedEndTime) {
          this.pause();
          vueComponent.stoppedOnce = true;
        }
      });

      player.on('play', () => {
        vueComponent.isPlaying = true;
      });

      player.on('ended', () => {
        vueComponent.isPlaying = false;
      });

      player.on('pause', () => {
        vueComponent.isPlaying = false;
      });
    },
  },
  mounted() {
    this.initializePlayer();
  },
});
</script>

<template>
<video id="olp-video-player" class="video-js">
  <source v-if="parsedVideo.video_240p"
    type="video/mp4"
    :src="parsedVideo.video_240p"
    :label="origStreamLabel(240)" />

  <source v-if="parsedVideo.video_480p"
    :selected="!parsedVideo.video_dubbed_480p"
    type="video/mp4"
    :src="parsedVideo.video_480p"
    :label="origStreamLabel(480)" />

  <source v-if="parsedVideo.video_720p"
    type="video/mp4"
    :src="parsedVideo.video_720p"
    :label="origStreamLabel(720)" />

  <source v-if="parsedVideo.video_dubbed_240p"
    type="video/mp4"
    :src="parsedVideo.video_dubbed_240p"
    :label="dubbedStreamLabel(240)" />

  <source v-if="parsedVideo.video_dubbed_480p"
    :selected="!!parsedVideo.video_dubbed_480p"
    type="video/mp4"
    :src="parsedVideo.video_dubbed_480p"
    :label="dubbedStreamLabel(480)" />

  <source v-if="parsedVideo.video_dubbed_720p"
    type="video/mp4"
    :src="parsedVideo.video_dubbed_720p"
    :label="dubbedStreamLabel(720)" />

  <track v-if="parsedTranscription"
    kind="subtitles"
    :label="parsedVideo.langcode?.toUpperCase() || $t('components.video-player.ov')"
    :src="`/assets/${parsedTranscription.filename}`"
    :srclang="parsedVideo.langcode?.toUpperCase() || $t('components.video-player.ov')" />

  <track v-if="parsedTranslation"
    kind="subtitles"
    :label="parsedVideo.langcode_voiceover?.toUpperCase() || $t('components.video-player.tr')"
    :src="`/assets/${parsedTranslation.filename}`"
    :srclang="parsedVideo.langcode_voiceover?.toUpperCase() || $t('components.video-player.tr')" />

  <p class="vjs-no-js">
    To view this video please enable JavaScript, and consider upgrading to a
    web browser that
    <a href="https://videojs.com/html5-video-support/" target="_blank">
      supports HTML5 video
    </a>
  </p>
</video>
<ClipForm v-if="videoLoaded"
  :chapterTitle="title"
  :videoId="parsedVideo.wp_id"
  :posterUrl="parsedVideo.poster_image_link" />
</template>
