<script lang="ts">
import { defineComponent } from 'vue';

import TableOfContents from './TableOfContents.vue';
import TranscriptComponent from './TranscriptComponent.vue';

export default defineComponent({
  components: {
    TableOfContents,
    TranscriptComponent,
  },
  props: {
    transcription: String,
    translation: String,
    chapterList: String,
  },
  computed: {
    parsedChapterList() {
      return JSON.parse(this.chapterList);
    },
    parsedTranscription() {
      return JSON.parse(this.transcription);
    },
    parsedTranslation() {
      return JSON.parse(this.translation);
    },
  },
  methods: {
    triggerTranscriptUpdate(type: string) {
      const component = this.$refs[type];
      component?.updateScrollPosition();
    },
  },
});
</script>

<template>
<nav v-cloak id="video-tabs" class="u-mt">
  <ul class="nav nav-tabs u-font-size-small" role="tablist">
    <li class="active" role="presentation">
      <a href="#video_chapters"
        aria-controls="video_chapters"
        role="tab"
        data-toggle="tab"
        data-test-id="video-chapters-tab">
        {{ $t('components.video-tabs.video_chapters') }}
      </a>
    </li>
    <li v-if="parsedTranscription" role="presentation">
      <a href="#transcription"
        @click="triggerTranscriptUpdate('transcription')"
        aria-controls="transcription"
        role="tab"
        data-toggle="tab"
        data-test-id="video-transcription-tab">
        {{ $t('components.video-tabs.transcript') }}
      </a>
    </li>
    <li v-if="parsedTranslation" role="presentation">
      <a href="#translation"
        @click="triggerTranscriptUpdate('translation')"
        aria-controls="translation"
        role="tab"
        data-toggle="tab"
        data-test-id="video-translation-tab">
        {{ $t('components.video-tabs.translation') }}
      </a>
    </li>
  </ul>

  <div class="tab-content">
    <div class="tab-pane fade in active" id="video_chapters" role="tabpanel">
      <TableOfContents :chapterList="parsedChapterList" />
    </div>
    <div v-if="parsedTranscription"
      class="tab-pane fade" id="transcription" role="tabpanel">
      <TranscriptComponent type="transcription" ref="transcription" />
    </div>
    <div v-if="parsedTranslation"
      class="tab-pane fade" id="translation" role="tabpanel">
      <TranscriptComponent type="translation" ref="translation" />
    </div>
  </div>
</nav>
</template>
