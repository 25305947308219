import url_slug from '../../url_slug';
import { SearchResult } from '../types';

const tLower = (key: string): string => url_slug(key);

const htmlToPlaintext = (text: string): string => (text
  ? String(text).replace(/<!--.*?-->/gm, '').replace(/<[^>]+>/gm, '').replace(/\n/gm, '<br/>')
  : '');

// "Krankenhaus" => "Kr[äa]nkenh[äa][üu]s"
const getRegexWithUmlauts = (text: string): string => text
  .replace(/[äa]/g, '[äa]')
  .replace(/[öo]/g, '[öo]')
  .replace(/[üu]/g, '[üu]')
  .replace(/[ÄA]/g, '[ÄA]')
  .replace(/[ÖO]/g, '[ÖO]')
  .replace(/[ÜU]/g, '[ÜU]');

function highlight(text: string, searchTerm: string): string {
  if (!searchTerm) {
    return text;
  }
  return text.replace(
    new RegExp(getRegexWithUmlauts(searchTerm), 'gi'),
    '<span class="highlightedText">$&</span>',
  );
};

const h = (text: string, searchTerm: string): string =>
  highlight(htmlToPlaintext(text), searchTerm);

export default function transformSearchResult(type: string, result: any,
  searchTerm: string): SearchResult {
  let href: string | null = '';
  let title = '';

  switch (type) {
    case 'chapters':
      href = `/${result.section.slug}/${result.slug}`;
      title = `${result.section.title}: ${h(result.title, searchTerm)} - ${h(result.subtitle, searchTerm)}`;
      break;
    case 'cues':
      title = `<a href="/${result[0].section.slug}/${result[0].chapter.slug}"><strong>${result[0].section.title}: ${result[0].chapter.title}</strong></a>`;
      result.forEach((match) => {
        title += `<br/><strong>${match.start.split('.')[0]}:</strong> ${h(match.text, searchTerm)}`;
      });
      break;
    case 'exercises':
      title = `<a href="/${result.section.slug}/${result.chapter.slug}#${tLower('exercises')}/${result.wp_id}">${h(result.title_formal, searchTerm)} (${result.section.title}: ${result.chapter.title})</a>`;
      result.search_matches.forEach((match) => {
        title += `<br/>${h(match, searchTerm)} …`;
      });
      break;
    case 'materials':
      title = `<a href="/materials#${result.id}"> ${h(result.title, searchTerm)} <i>${I18n[result.type.toLowerCase()]}</i></a>`;
      result.search_matches.forEach((match) => {
        title += `<br/>${h(match, searchTerm)} …`;
      });
      break;
    case 'dictionary':
      href = null;
      title = `<a href="/dictionary#${result.slug}"><strong>${h(result.title, searchTerm)}</strong></a><br/>${h(result.short_entry_text, searchTerm)}`;
      break;
    case 'timelines':
    default:
      href = null;
      title = `<strong>${result.month_day}${result.year}: ${h(result.title, searchTerm)}</strong><br/>${h(result.description, searchTerm)}`;
      break;
  }

  return {
    href,
    title,
  };
}
