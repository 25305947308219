<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    submitting: {
      type: Boolean,
      required: true,
    },
    error: Boolean,
    success: Boolean,
    onSubmit: {
      type: Function,
      required: true,
    },
    onOkay: {
      type: Function,
      required: true,
    },
    modelValue: String,
    formalAddress: Boolean,
  },
  emits: ['update:modelValue'],
  methods: {
    handleSubmit(event: Event) {
      event.preventDefault();
      this.onSubmit();
    },
    handleCloseButton() {
      this.onOkay();
    },
    handleChange(event: Event) {
      const textArea = event.target as HTMLTextAreaElement;
      if (!textArea) {
        return;
      }

      const value = textArea.value;
      this.$emit('update:modelValue', value);
    }
  },
});
</script>

<template>
<div class="feedback-form">
  <h3 class="feedback-form__heading">
    {{ $t('components.feedbackButton.feedback') }}
  </h3>

  <p v-if="error" class="u-color-error u-mt-small u-mb-none">
    {{ $t('general.transmission_error') }}
  </p>

  <div v-if="success">
    <p class="u-mt-small u-mb-none">
      {{ $t(`components.feedbackButton.transmitted.${formalAddress ? 'formal' : 'informal'}`) }}
    </p>

    <button type="button"
      @click="handleCloseButton"
      class="btn btn-primary feedback-form__submit u-mt-small">
      {{ $t('general.close') }}
    </button>
  </div>

  <form v-else class="feedback-form__form" @submit="handleSubmit">
    <textarea
      name="feedbackText"
      class="feedback-form__input u-mt-small"
      data-test-id="feedback-input"
      :value="modelValue"
      @input="handleChange"
      minlength="5"
      maxlength="500"
      required
      :placeholder="$t(`components.feedbackButton.placeholder.${formalAddress ? 'formal' : 'informal'}`)"
      :disabled="submitting"></textarea>

    <button type="submit"
      class="btn btn-primary feedback-form__submit u-mt-small"
      data-test-id="feedback-submit"
      :disabled="submitting">
      {{ $t('general.submit') }}
    </button>
  </form>
</div>
</template>
