import { Exercise, Favorite } from '../types';

function headers() {
  return {
    'Content-Type': 'application/json; charset=utf-8',
    'X-User-Id': current_user_id,
    'X-User-Token': current_user_token,
  };
};

export default async function saveOrUpdateFavorite(exercise: Exercise,
  favoriteData: object): Promise<Favorite> {
  let url: string, method: string, data: object;

  if (exercise.favorite_id) {
    url = `/favorites/${exercise.favorite_id}.json`;
    method = 'PUT';
    data = { favorite: favoriteData };
  } else {
    url = '/favorites.json';
    method = 'POST';
    data = {
      favorite: {
        user_id: current_user_id,
        exercise_id: exercise.wp_id,
        ...favoriteData,
      },
    };
  }

  const res = await fetch(url, {
    method,
    headers: headers(),
    body: JSON.stringify(data),
  });

  const favorite: Favorite = await res.json() as Favorite;
  return favorite;
}
