<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import MaterialCard from './MaterialCard.vue';
import { Material } from '../types';

export default defineComponent({
  components: {
    MaterialCard,
  },
  props: {
    materials: {
      type: Array as PropType<Array<Material>>,
    },
    isNarrow: Boolean,
    isLoading: Boolean,
  },
});
</script>

<template>
<ul class="grid grid--materials"
  :class="{ 'grid--materials-narrow': isNarrow }">
  <MaterialCard
    v-for="material in materials"
    :material="material"
  />
</ul>
</template>

<style scoped>
.grid {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
