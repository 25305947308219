<script lang="ts">
import { mapState, mapWritableState, mapActions } from 'pinia';
import tinymce from 'tinymce';

import formatTime from './formatTime';
import formatDuration from './formatDuration';
import { useExercisesStore } from '../chapter/exercises_store';
import { useVideoStore } from './video_store';
import generateVideoLinkHtml from './generateVideoLinkHtml';
import insertHTMLIntoEditor from './insertHTMLIntoEditor';

export default {
  props: {
    chapterTitle: String,
    videoId: Number,
    posterUrl: String,
  },
  data() {
    return {
      startTime: 0,
      endTime: 0,
    };
  },
  watch: {
    clipPanelIsOpen(newValue, oldValue) {
      if (newValue === true) {
        this.setStartTimeToCurrent();
        this.endTime = this.duration;
      }
    },
  },
  computed: {
    ...mapState(useVideoStore, ['duration', 'currentTime']),
    ...mapWritableState(useVideoStore, ['clipPanelIsOpen']),
    formattedClipDuration() {
      return formatDuration(this.endTime - this.startTime);
    },
    startTimeFormatted() {
      return formatTime(this.startTime);
    },
    endTimeFormatted() {
      return formatTime(this.endTime);
    }
  },
  methods: {
    ...mapActions(useExercisesStore, ['insertHTMLIntoEditor']),
    submit(event: Event): void {
      event.preventDefault();
      this.handleClipFormSubmit(this.startTime, this.endTime);
      this.startTime = 0;
      this.endTime = 0;
    },
    handleClipFormSubmit(startTime: number, endTime: number) {
      const title = this.chapterTitle;
      const wpId = this.videoId;

      const html = generateVideoLinkHtml({
        title: title,
        url: `/videos/${wpId}?start=${startTime}&end=${endTime}`,
        stillURI: this.posterUrl,
        startTime: formatTime(startTime),
        endTime: formatTime(endTime),
      });

      insertHTMLIntoEditor(tinymce.activeEditor, html);

      this.clipPanelIsOpen = false;
    },
    cancel(): void {
      this.startTime = 0;
      this.endTime = 0;
      this.clipPanelIsOpen = false;
    },
    setStartTimeToCurrent(): void {
      this.startTime = this.currentTime;
      if (this.startTime > this.endTime) {
        this.endTime = this.startTime;
      }
    },
    setEndTimeToCurrent(): void {
      this.endTime = this.currentTime;
      if (this.endTime < this.startTime) {
        this.startTime = this.endTime;
      }
    },
  },
}
</script>

<template>
  <div v-show="clipPanelIsOpen" class="well">
    <form @submit="submit">
      <h4 class="title">
        {{ $t('components.video-player.clip') }} ({{ formattedClipDuration }})
      </h4>
      <div class="form-grid">
        <div class="input-group u-mt-small">
          <span class="input-group-btn">
            <button type="button" class="btn btn-default btn-sm"
              @click="setStartTimeToCurrent">
              {{ $t('components.video-player.start') }}
            </button>
          </span>
          <input type="text" readonly
            class="form-control input-sm"
            placeholder="Start time"
            :value="startTimeFormatted">
        </div>
        <div class="input-group u-mt-small">
          <span class="input-group-btn">
            <button type="button" class="btn btn-default btn-sm"
              @click="setEndTimeToCurrent">
              {{ $t('components.video-player.end') }}
            </button>
          </span>
          <input type="text" readonly
            class="form-control input-sm"
            placeholder="End time"
            :value="endTimeFormatted">
        </div>
      </div>
      <div class="u-mt-small">
        <button type="submit" class="btn btn-primary btn-sm">
          <span class="glyphicon glyphicon-paperclip" aria-hidden="true">
          </span>
          {{ $t('components.video-player.insertIntoExercise') }}
        </button>
        <button type="button" class="btn btn-secondary btn-sm u-ml-small"
          @click="cancel">
          {{ $t('general.close') }}
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
}
.form-grid {
  display: grid;
  grid-gap: calc(var(--base-unit) / 2);
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}
.input-group {
  margin-top: 0;
}
</style>
