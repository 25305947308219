import $ from 'jquery';

$(() => {
  $('.tag').on('click', function() {
    $('.tags-content-small').remove();
    $('.tags-content-list').empty();
    $('.tags-content-title').text($(this).find('.tag-name').html());

    $(this).append('<div class="visible-xs"><ul class="tags-content-list tags-content-small"></ul></div>');
    $('.tags-content').fadeIn('fast');

    const $tags_content_list = $(this).data('exercises');
    $.each($tags_content_list, function(i, l){
      $('.tags-content-list').append(
        $(`<li><a href="${l['link']}" title="${I18n['open_chapter']}"><strong>${l['chapter']}:</strong> ${l['title']}</a></li>`)
      );
    });
    $('.tags-content-small').fadeIn('fast');
  })

  $('.tags-content-close').on('click', function() {
    $('.tags-content').fadeOut('fast');
    $('.tags-content-list').empty();
  })
});
