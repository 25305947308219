<script lang="ts">
import { defineComponent } from 'vue';
import { VueSlickCarousel } from "vue-slick-ts";
import 'vue-slick-ts/dist/css/slick.css';
import 'vue-slick-ts/dist/css/slick-theme.css';

import { GalleryImage } from '../types';

export default defineComponent({
  components: {
    VueSlickCarousel,
  },
  props: {
    imagesJson: {
      type: String,
      required: true,
    },
  },
  computed: {
    images(): Array<GalleryImage> {
      return JSON.parse(this.imagesJson);
    },
  },
});
</script>

<template>
<div class="gallery">
  <VueSlickCarousel
    ref="carousel"
    :autoplay-speed="4000"
    :autoplay="true"
    :dots="true"
    :arrows="true"
    :slides-to-show="1"
    :speed="300"
    :infinite="true">
    <div v-for="image in images" class="gallery-image">
      <img :src="image.image_path" :alt="image.caption">
      <div class="gallery-image-caption">
        <strong>{{ image.title }}</strong>
        <br/>
        {{ image.caption }}
      </div>
    </div>
  </VueSlickCarousel>
</div>
</template>
