<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import { useTimelineStore } from './timeline_store';
import TimelineEntry from './TimelineEntry.vue';

export default defineComponent({
  components: {
    TimelineEntry
  },
  props: {
    skipHeading: Boolean,
  },
  computed: {
    ...mapState(useTimelineStore, ['years', 'entriesByYear']),
  },
});
</script>

<template>
<div :class="{'timeline-column': !skipHeading}">
  <h2 v-if="!skipHeading" class="heading heading--large u-mb">
    {{ $t('components.menu-section.timeline') }}
  </h2>

  <ul class="timeline">
    <li v-for="(year, index) in years"
      :class="{'timeline-inverted': index % 2 === 0}">
      <div class="timeline-badge">
        <span>
          {{ year }}
        </span>
      </div>
      <div class="timeline-panel">
        <TimelineEntry v-for="entry in entriesByYear[year]"
          :entry="entry">
        </TimelineEntry>
      </div>
    </li>
  </ul>
</div>
</template>
