<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';

import { useDictionaryStore } from '../dictionary/dictionary_store';
import { useMapStore } from '../map/map_store';
import { useTimelineStore } from '../timeline/timeline_store';
import { useExercisesStore } from './exercises_store';
import VideoPlayer from '../video/VideoPlayer.vue';
import VideoTabs from '../video/VideoTabs.vue';
import TeacherSwitch from '../TeacherSwitch.vue';
import { User } from '../types';

import ChapterRouter from './ChapterRouter.vue';

export default defineComponent({
  components: {
    VideoPlayer,
    VideoTabs,
    TeacherSwitch,
    ChapterRouter,
  },
  props: {
    chapterTitle: String,
    userJson: String,

    taskBlocksJson: String,
    usersExercisesJson: String,
    favSymbol: String,

    videoJson: String,
    transcriptionJson: String,
    translationJson: String,
    videoChaptersJson: String,
    dictionaryEntriesJson: String,

    chapterJson: String,

    bioMapJson: String,
    mapSettingJson: String,
    mapImageUrl: String,

    timelineEntriesJson: String,
  },
  computed: {
    ...mapState(useExercisesStore, ['user']),
    showTeacherSwitch() {
      const user: User = this.user;
      return user.role === 'Teacher' || user.role === 'Admin';
    }
  },
  methods: {
    ...mapActions(useDictionaryStore, ['initialize']),
    ...mapActions(useMapStore, ['initializeMapData']),
    ...mapActions(useTimelineStore, ['initializeTimelineData']),
    ...mapActions(useExercisesStore, ['initializeExercisesData']),
  },
  beforeMount() {
    this.initialize(this.dictionaryEntriesJson);
    this.initializeMapData(this.bioMapJson, this.mapSettingJson, this.mapImageUrl);
    this.initializeTimelineData(this.timelineEntriesJson);
    this.initializeExercisesData(this.taskBlocksJson, this.usersExercisesJson,
      this.userJson, this.favSymbol, this.chapterTitle, this.chapterJson);
  },
});
</script>

<template>
<div class="grid grid--chapter">
  <div v-if="videoJson" class="hidden-print">
    <VideoPlayer
      :title="chapterTitle"
      :video="videoJson"
      :transcription="transcriptionJson"
      :translation="translationJson">
    </VideoPlayer>

    <VideoTabs
      :chapter-list="videoChaptersJson"
      :transcription="transcriptionJson"
      :translation="translationJson">
    </VideoTabs>
  </div>

  <div>
    <ChapterRouter />
  </div>
</div>

<TeacherSwitch v-if="showTeacherSwitch" fixed />
</template>
