<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { mapState, mapActions } from 'pinia';

import { useExercisesStore } from '../chapter/exercises_store';
import SearchInput from '~/vue/SearchInput.vue';
import { Material } from '../types';

type FilterCategory = {
  name: string,
  count: number,
  selected: boolean,
}

export default defineComponent({
  components: {
    SearchInput,
  },
  props: {
    materials: {
      type: Array as PropType<Array<Material>>,
    },
    isLoading: Boolean,
    filter: Object,
    onChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    ...mapState(useExercisesStore, ['searchedMaterials']),
    enrichedFilter(): object {
      const result = {};

      Object.keys(this.filter).map((category: string) => {
        result[category] = {
          name: category,
          count: 0,
          selected: this.filter[category],
        };
      });

      this.materials?.forEach((material: Material) => {
        result[material.type].count += 1;
      });

      return result;
    },
    filterCategories(): Array<FilterCategory> {
      if (!this.materials) {
        return [];
      }

      const result: Array<FilterCategory> = Object.values(this.enrichedFilter);
      return result;
    }
  },
  methods: {
    ...mapActions(useExercisesStore, ['handleSearchTermChange']),
  },
  watch: {
    searchTerm(newTerm) {
      this.handleSearchTermChange(newTerm);
    },
  },
});
</script>

<template>
<div>
  <p v-if="isLoading" class="u-mt-small">Loading</p>
  <div v-if="!isLoading" class="filter u-mt-small">
    <button
      v-for="category in filterCategories"
      @click="onChange(category.name)"
      class="filter__button"
      :class="{'filter__button--active': category.selected, 'filter__button--hidden': category.count === 0 && !category.selected}">
      {{ $t(`components.materials.types.plural.${category.name}`) }} {{ category.count }}
    </button>
  </div>

  <SearchInput v-model="searchTerm" class="u-mt-small" />
</div>
</template>

<style scoped>
.filter {
  list-style-type: none;
  margin: 0;
  max-width: calc(var(--base-unit) * 30);
  padding: 0;
}

.filter__button {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid gray;
  display: inline-block;
  line-height: var(--base-unit);
  margin-bottom: calc(var(--base-unit) / 4);
  margin-right: calc(var(--base-unit) / 2);
  padding: 0 calc(var(--base-unit) / 2);
  transition: all 125ms;
}

.filter__button--active {
  background-color: var(--theme-color);
  color: white;
}

.filter__button--hidden {
  display: none;
}
</style>
