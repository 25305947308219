<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import { useGlobalStore } from '../global_store';
import { HelpChapter } from '../types';
import TeacherSwitch from '../TeacherSwitch.vue';

export default defineComponent({
  components: {
    TeacherSwitch
},
  props: {
    chapters: String,
    teacherRights: String,
  },
  computed: {
    ...mapState(useGlobalStore, ['teacherMode']),
    parsedChapters(): Array<HelpChapter> {
      return JSON.parse(this.chapters);
    },
    parsedTeacherRights(): boolean {
      return JSON.parse(this.teacherRights);
    },
    tocChapters(): Array<HelpChapter> {
      return this.parsedChapters.filter((chapter: HelpChapter) =>
        chapter.is_main_chapter
        && (this.parsedTeacherRights || !chapter.for_teachers_only)
      );
    },
    filteredChapters(): Array<HelpChapter> {
      return this.parsedChapters.filter((chapter: HelpChapter) =>
        (this.parsedTeacherRights && this.teacherMode)
          || !chapter.for_teachers_only
      );
    },
    showToc() {
      return this.tocChapters.length > 0;
    },
  },
  methods: {
    alphanumericOnly(text: string) {
      const pattern = /\W/g;
      return text.replace(pattern, '');
    },
  },
  mounted() {
    $(() => {
      $('h3,h4').each(function() {
        $(this).next('p,ul').addBack().wrapAll('<div class="staytogether" />');
      });
    });
  }
});
</script>

<template>
<div v-if="showToc" class="panel panel-default">
  <div class="panel-body">
    <ul>
      <li v-for="chapter in tocChapters">
        <a :href="`#${alphanumericOnly(chapter.title)}`" target="_self">
          {{ chapter.title }}
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="pull-right">
  <a :href="`/help.pdf?teacher=${JSON.stringify(parsedTeacherRights)}`"
    title="PDF">
    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
  </a>
</div>

<TeacherSwitch v-if="parsedTeacherRights" />

<div v-for="(chapter, index) in filteredChapters"
  :class="{ 'for-teachers-only': chapter.for_teachers_only }"
  style="padding-left: 24px">
  <hr v-if="chapter.is_main_chapter && index > 0" />

  <div :id="alphanumericOnly(chapter.title)">
    <h3 v-if="chapter.is_main_chapter">{{ chapter.title }}</h3>
    <h4 v-else>{{ chapter.title }}</h4>
    <div v-html="chapter.text"></div>
  </div>
</div>
</template>

<style scoped>
h4 {
  margin-top: 22px;
}

.staytogether {
  page-break-inside: avoid;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 1em auto;
}

p:after {
  content: '';
  display: block;
  clear: both;
}
</style>
