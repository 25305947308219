<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapWritableState, mapActions } from 'pinia';

import { useExercisesStore, SCOPE_ALL, SCOPE_CHAPTER, SCOPE_EXERCISE }
  from '../chapter/exercises_store';
import MaterialsFilter from './MaterialsFilter.vue';
import MaterialsGrid from './MaterialsGrid.vue';
import MaterialsSelector from './MaterialsSelector.vue';

export default defineComponent({
  components: {
    MaterialsFilter,
    MaterialsGrid,
    MaterialsSelector
},
  props: {
    standAlone: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState(useExercisesStore, ['currentExerciseId', 'currentExercise',
      'chapter', 'isLoading', 'filter', 'scopedMaterials',
      'scopedFilteredAndSearchedMaterials']),
    ...mapWritableState(useExercisesStore, ['scope']),
    showMaterialsSelector() {
      return !this.standAlone;
    },
    includeExerciseInSelector() {
      return this.currentExerciseId !== null;
    },
    showBackToExerciseLink(): boolean {
      if (this.standAlone) {
        return false;
      }

      return this.currentExerciseId !== null;
    },
    skipHeading() {
      return this.standAlone;
    },
  },
  methods: {
    ...mapActions(useExercisesStore, ['fetchMaterials', 'handleFilterChange']),
    addEventListener() {
      window.addEventListener('own-router:set-materials-scope', this.handleRouteChange);
    },
    removeEventListener() {
      window.removeEventListener('own-router:set-materials-scope', this.handleRouteChange);
    },
    handleRouteChange() {
      if (this.includeExerciseInSelector) {
        this.scope = SCOPE_EXERCISE;
      }
    },
    setScope() {
      this.scope = this.standAlone ? SCOPE_ALL : SCOPE_CHAPTER;

      if (this.includeExerciseInSelector) {
        this.scope = SCOPE_EXERCISE;
      }
    },
  },
  mounted() {
    this.fetchMaterials();
    this.addEventListener();
    this.setScope();
  },
  beforeUnmount() {
    this.removeEventListener();
  },
});
</script>

<template>
<div>
  <h2 v-if="!skipHeading" class="heading heading--large u-mb">
    {{ $t('components.menu-section.materials') }}
  </h2>

  <MaterialsSelector v-if="showMaterialsSelector"
    v-model="scope"
    :include-exercise="includeExerciseInSelector" />

  <MaterialsFilter
    :materials="scopedMaterials"
    :isLoading="isLoading"
    :filter="filter"
    :onChange="handleFilterChange"
  />

  <div v-if="showBackToExerciseLink" class="u-mt">
    <router-link :to="`/exercises/${currentExerciseId}`" class="back-to-inner">
      <i class="fa fa-edit" aria-hidden="true"></i>
      {{ $t('components.materials.back_to_exercise') }}
    </router-link>
  </div>

  <MaterialsGrid
    :materials="scopedFilteredAndSearchedMaterials"
    :is-narrow="!standAlone"
    :isLoading="isLoading"
    class="u-mt-large"
  />
  <router-view name="materialBrowser"></router-view>
</div>
</template>
