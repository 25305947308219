<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    saving: Boolean,
  },
});
</script>

<template>
<div class="saving-indicator">
  <span v-if="saving">
    {{ $t('general.saving') }}
  </span>
</div>
</template>

<style scoped>
.saving-indicator {
  min-height: var(--base-unit);
}
</style>
