<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';

import { useVideoStore } from './video_store';
import { Chapter } from '../types';

export default defineComponent({
  props: {
    chapterList: { type: Array<Chapter>, required: true },
  },
  computed: {
    ...mapState(useVideoStore, ['currentTime']),
  },
  methods: {
    ...mapActions(useVideoStore, ['jumpToSeconds']),
    isChapterActive(chapterStart: number, chapterEnd: number) {
      return (chapterStart <= this.currentTime)
        && (this.currentTime < chapterEnd);
    },
  },
});
</script>

<template>
<table class="table table-condensed toc u-mt-small">
  <tbody>
    <tr v-for="(chapter, index) in chapterList"
      class="toc__row"
      :class="{ 'toc__row--active': isChapterActive(chapter.timecode,
        chapterList[index + 1] ? chapterList[index + 1].timecode : 36000) }">
      <td>
        <button type="button"
          class="toc__button u-font-size-small"
          @click="jumpToSeconds(chapter.timecode)">
          <b>
            {{ chapter.timecode_pretty }}
          </b>
        </button>
      </td>
      <td>
        <button type="button"
          class="toc__button u-font-size-small"
          @click="jumpToSeconds(chapter.timecode)">
          {{ chapter.heading_de }}
        </button>
      </td>
    </tr>
  </tbody>
</table>
</template>
