<script lang="ts">
import { defineComponent } from 'vue';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import videojsQualitySelector from '@silvermine/videojs-quality-selector';
import langDe from 'video.js/dist/lang/de.json';
import langCs from 'video.js/dist/lang/cs.json';
import langEl from 'video.js/dist/lang/el.json';
import langNl from 'video.js/dist/lang/nl.json';
import langPl from 'video.js/dist/lang/pl.json';
import langRu from 'video.js/dist/lang/ru.json';
import 'video.js/dist/video-js.css';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';

videojsQualitySelector(videojs);
videojs.addLanguage('de', langDe);
videojs.addLanguage('cs', langCs);
videojs.addLanguage('el', langEl);
videojs.addLanguage('nl', langNl);
videojs.addLanguage('pl', langPl);
videojs.addLanguage('ru', langRu);

const VIDEOJS_OPTIONS: VideoJsPlayerOptions = {
  controls: true,
  preload: 'auto',
  controlBar: {
    children: [
      'playToggle',
      'progressControl',
      'volumePanel',
      'qualitySelector',
      'fullscreenToggle'
    ],
  },
};

export default defineComponent({
  props: {
    projectName: String,
  },
  computed: {
    showGermanVideo() {
      return this.projectName === 'de';
    },
    showCzechVideo() {
      return this.projectName === 'cz';
    },
  },
  methods: {
    initializePlayer() {
      videojs('video-startpage', VIDEOJS_OPTIONS);
    },
  },
  mounted() {
    this.initializePlayer();
  },
});
</script>

<template>
<video id="video-startpage" controls class="video-js vjs-fluid vjs-16-9"
  :poster="`/assets/trailer-lernen-mit-interviews-still-${projectName}.jpg`">
  <source v-if="showGermanVideo" src="https://medien.cedis.fu-berlin.de/muma_zwar/OEA/lernplatform_240p.mp4"
    type="video/mp4" label="240p">
  <source v-if="showGermanVideo" src="https://medien.cedis.fu-berlin.de/muma_zwar/OEA/lernplatform_480p.mp4"
    type="video/mp4" label="480p" selected="true">
  <source v-if="showGermanVideo" src="https://medien.cedis.fu-berlin.de/muma_zwar/OEA/lernplatform_720p.mp4"
    type="video/mp4" label="720p">
  <source v-if="showCzechVideo" src="https://medien.cedis.fu-berlin.de/muma_tschech/NUCENAPRACE_Intro_OLP-hd720p.mp4"
    type="video/mp4" data-res="720">
</video>
</template>
