import $ from 'jquery';

$(() => {
  $(".show_password").on("click", function() {
    var $userPassword = $("#user_password");
    if (this.checked) {
      $userPassword.prop("type", "text");
    } else {
      $userPassword.prop("type", "password");
    }
  });
});
