<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: String,
  },
  emits: ['update:modelValue'],
});
</script>

<template>
<div class="form-group">
  <div class="input-group input-group-sm">
    <div class="input-group-addon">
      <i class="fa fa-search"></i>
    </div>
    <input type="text"
      class="form-control"
      id="search-input"
      data-test-id="search-field"
      :placeholder="$t('components.searchInput.search')"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @keydown.esc="$emit('update:modelValue', '')">
  </div>
</div>
</template>

<style scoped>
.form-group {
  max-width: calc(var(--base-unit) * 10);
}
</style>
