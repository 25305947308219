import { Exercise } from '../types';

export default function filterBySearchTerm(searchTerm: string, exercises: Array<Exercise>) {
  return exercises.filter((exercise: Exercise) => {
    const re = new RegExp(searchTerm, 'gi');

    return re.test(exercise.chapter_title)
      || re.test(exercise.task_block_title)
      || re.test(exercise.chapter_subtitle)
      || re.test(exercise.title)
      || re.test(exercise.text);
  });
}
