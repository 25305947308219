import $ from 'jquery';

/**
 *
 * @param map$
 * @param lang
 * @param featurestyles
 * @returns {{findBySectionByFeatureName: findBySectionByFeatureName, findBySectionBySectionName: findBySectionBySectionName, initMap: initMap, toggleFeatureGroup: toggleFeatureGroup, loadMap: loadMap}}
 * @constructor
 */

export default function ThematicalMapViewer(map$, lang, featurestyles) {
    var LayerByFeature = {}
    var FeatureNameByLabelID = {}
    var SectionIsClicked = {}

    window.SectionIsClicked = SectionIsClicked
    window.featurestyles = featurestyles

    /**
     *
     * loaded GEOJSON data
     *
     * @type {boolean}
     */
    var labelsGeoJsonData = false

    /**
     *
     * GEOJSON.features
     *
     * @type {boolean}
     */
    var features = false

    var map = false
    var markers = L.layerGroup([])
    var styles1 = [
        {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
                {"visibility": "on"}
            ]
        },
        {
            "featureType": "administrative.country",
            "stylers": [
                {"visibility": "on"}
            ]
        },
        {
            "featureType": "administrative.locality",
            "stylers": [
                {"visibility": "on"}
            ]
        },
        {}
    ]

    var styles2 = [
        {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "administrative.country",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "administrative.locality",
            "stylers": [
                {"visibility": "on"}
            ]
        },
        {}
    ]

    var ggl1 = new L.Google('TERRAIN', {
        mapOptions: {
            styles: styles1
        }
    });

    var ggl2 = new L.Google('TERRAIN', {
        mapOptions: {
            styles: styles2
        }
    });

    var hyda = new L.TileLayer("https://{s}.tile.openstreetmap.se/hydda/base/{z}/{x}/{y}.png", {
        attribution:'Tilest and map data courtesy of <a href="http://openstreetmap.se/" target="_blank">OpenStreetMap</a>'
    });

    var Esri_WorldShadedRelief = new L.TileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Source and Tiles &copy; Esri',
        maxZoom: 13
    });

    var basemaplayers = null

    if (!featurestyles.baseMapLayers) {
        basemaplayers = {
            'MAP' : Esri_WorldShadedRelief
        };
    } else {
        basemaplayers = featurestyles.baseMapLayers
    }

    $(document).on('click', '.label', function () {
        var t$ = $(this)
        console.log('label clicked', t$)

        var FeatureNameKey = FeatureNameByLabelID[t$.attr('id')]
        if (!FeatureNameKey) {
            console.log('label has no associated FeatureNameKey [' + t$.attr('id') + ']')
        } else {
            toggleFeatureGroupHighlight(FeatureNameKey)
        }

        return true
    })

    /**
     *
     * @param FeatureKey = #COUNTRY.post_title
     * @returns #BYSECTION
     */
    function findBySectionByFeatureName(FeatureKey) {
        var BySection = featurestyles.ByCountry[FeatureKey]
        if (!BySection) {
            //console.log("BySection [" + FeatureKey + "] not found", featurestyles)
            return false
        }
        return BySection
    }

    /**
     *
     * @param SectionName = #SECTION.name
     * @returns #BYSECTION
     */
    function findBySectionBySectionName(SectionName) {
        var BySection = featurestyles.BySections[SectionName]
        if (!BySection) {
            //console.log("BySection [" + SectionName + "] not found", featurestyles)
            return false
        }
        return BySection
    }

    /**
     *
     * @param BySection #BYSECTION
     * @param onoff boolean
     * @returns {boolean}
     */
    function toggleFeatureGroup(BySection, onoff) {
        if (!BySection) {
            return false
        }

        /**
         * true := wenn der wert von <code>onoff</code> tatsÃ¤chlich Ã¼bergeben wurde
         *
         * @type {boolean}
         */
        var isOnOffProvidedAsFunctionArg = typeof onoff !== 'undefined'

        /**
         *  Style der SECTION
         */
        var FoundStyle
        var isForcedOff = (isOnOffProvidedAsFunctionArg && !onoff)
        var isForcedOn = (isOnOffProvidedAsFunctionArg && onoff)
        var isSectionClicked = SectionIsClicked[BySection.name] && !isOnOffProvidedAsFunctionArg
        var isSectionNotClicked = !SectionIsClicked[BySection.name] && !isOnOffProvidedAsFunctionArg
        var isOnOrOff = false

        /**
         * Section ist angeklickt oder soll explizit ausgeschaltet werden
         */
        if (isForcedOff) {
            SectionIsClicked[BySection.name] = false
            isOnOrOff = false
            setLayerStyle(BySection, BySection.style)
        } else if (isForcedOn || isSectionNotClicked) {
            for (var k in SectionIsClicked) {
                var isClicked = SectionIsClicked[k]
                if (isClicked) {
                    SectionIsClicked[k] = false;
                    (function (BySectionName) {
                        var BySection = featurestyles.BySections[BySectionName]
                        FoundStyle = BySection.style
                        setLayerStyle(BySection, FoundStyle)
                    })(k)
                }
            }

            isOnOrOff = true
            SectionIsClicked[BySection.name] = true
            FoundStyle = BySection.highlightstyle
            setLayerStyle(BySection, FoundStyle)
        }

        return isOnOrOff
    }

    /**
     * setzt den aktuellen Stil fÃ¼r alle Layer, die zu eine COUNTRY FeatureGroup gehÃ¶ren
     * @param BySection
     * @param FoundStyle
     */
    function setLayerStyle(BySection, FoundStyle) {
        $(BySection.countries).each(function (pos, countryFeatureKey) {
            var countryLayers = LayerByFeature[countryFeatureKey]
            if (!countryLayers) {
                return
            }
            $(countryLayers).each(function (pos, countryLayer) {
                //console.log('country', countryFeatureKey, countryLayer)
                countryLayer.setStyle(FoundStyle)
                //console.log('countryLayer.setStyle done')
            })
        })
    }

    /**
     *
     * Togglet die SECTION zu der ein bestimmtes Land (FeatureGroup) gehÃ¶rt
     *
     * @param FeatureKey COUNTRY.post_title
     */
    function toggleFeatureGroupHighlight(FeatureKey) {
        var BySection = findBySectionByFeatureName(FeatureKey)
        var onOrOff = toggleFeatureGroup(BySection)

        if (onOrOff) {
            $(document).trigger('leaflet.feature.highlighted', {section: BySection})
        } else {
            $(document).trigger('leaflet.feature.unhighlighted', {section: BySection})
        }
    }

    /**
     *
     * @param geoLayerFileName URL | false
     * @param geoData
     */
    function loadLabelsMap(geoLayerFileName) {
        map$.trigger('show-map-loader')

        function make_base_auth(user, password) {
            var tok = user + ':' + password;
            var hash = btoa(tok);
            return "Basic " + hash;
        }

        $.ajax
        ({
            type: "GET",
            url: geoLayerFileName,
            dataType: 'json',
            async: false,
            beforeSend: function (xhr) {
                //xhr.setRequestHeader('Authorization', make_base_auth("zwar-online", "Zw20ar15Online"));
            },
            success: function (geoJsonData) {
                loadLabelsMapWithGeoJsonData(geoJsonData, geoLayerFileName)
            },
            error: function () {
                alert("loading " + geoLayerFileName + " failed. Please check data availability, content and format.")
            }
        });
    }

    function loadLabelsMapWithGeoJsonData(labelsGeoJsonData, geoLayerFileName) {
        features = labelsGeoJsonData.features

        /*
         * 1. markers layergroup fÃ¼gen wir der MAP hinzu
         */
        markers.addTo(map)

        var overlayMaps = null;

        if (basemaplayers&&Object.keys(basemaplayers).length>1) {
            L.control.layers(basemaplayers, overlayMaps).addTo(map);
        }

        var ff = function (feature, layer) {
            var labelID = feature.properties.ZWAR_ID
            FeatureNameByLabelID[labelID] = feature.properties.Name
            var layerByFeat = LayerByFeature[feature.properties.Name]

            if (!layerByFeat) {
                layerByFeat = []
                LayerByFeature[feature.properties.Name] = layerByFeat
            }

            layerByFeat.push(layer)

            var bysection = featurestyles.ByCountry[feature.properties.ZWAR_ORIGINAL]

            if (featurestyles.PopupContentByFeature) {
                var PopupContent = featurestyles.PopupContentByFeature[feature.properties.Name]
                if (PopupContent) {
                    layer.bindPopup(PopupContent, {minWidth: 50, minHeight: 50})
                    layer.on('click', function (e) {
                        toggleFeatureGroupHighlight(feature.properties.Name)
                    })
                }
            }
        }

        var GeoJsonOpts = {
            onEachFeature: function (f, l) {
                popUp(f, l, markers)
                ff(f, l)

            },

            style: (function (styles) {
                if (!styles)
                    return null

                return function (feature) {
                    var FeatureKey = feature.properties.Name
                    var FoundStyle = styles.DefaultStyleByFeature[FeatureKey]

                    if (!FoundStyle) {
                        return styles.Default
                    } else {
                        return FoundStyle
                    }
                }
            })(featurestyles)
        }

        var geoJsonLayer = new L.GeoJSON(labelsGeoJsonData, GeoJsonOpts)
        markers.addLayer(geoJsonLayer)
        map$.trigger('hide-map-loader')
    }

    function initMap(centerx, centery, zoom, layers) {
        if (!zoom) {
            zoom = 4
        }

        if (!layers) {
            layers = [hyda]
        }

        if (map) {
            map.remove()
        }

        map = new L.Map(map$.get()[0],
            {
                center: new L.LatLng(centerx, centery),
                zoom: zoom, layers: layers
            });

        map.scrollWheelZoom.disable();

        map.on('zoomend', function () {
            var zoom = map.getZoom()
            cLzoom = zoom
            $(".danger").removeClass("danger")
            var RowSelected$ = $("#Level" + cLzoom)
            var rows$ = RowSelected$.get()

            if (rows$.length > 0) {
                rows$[0].scrollIntoView(true)
                RowSelected$.addClass('danger')
            }

            $(".label").each(function () {
                var l$ = $(this)
                var labelid = l$.attr('id')
                updateLabel(loadExceptionAtLevel(labelid, cLzoom), l$)
            })
        })
    }

    function popUp(f, l, markers) {
        if (!f.properties) {
            return
        }

        var out = [];

        var props = f.properties

        if (props === 'undefined') {
            return
        }

        var iconid = props.ZWAR_ID
        var labelid = props.ZWAR_ID
        var classname = props.ZWAR_ID

        var labelname = props.ZWAR_NAME
        var labelnameML = props.ZWAR_NAME_ML
        var originalName = props.ZWAR_ORIGINAL

        var divicon = L.divIcon({
            className: 'label ' + classname,
            html: labelnameML,
            iconSize: [100, 40]
        })

        var def = props.ZWAR_XTRA[0]

        var labelcenter = new L.LatLng(def.Lat, def.Lng)

        labelEntries[iconid] = {
            name: labelname,
            label: labelid,
            id: labelid,
            center: labelcenter,
            visible: true,
            startLevel: false, endLevel: false
        }

        $(divicon).attr('id', iconid)

        var label = L.marker(labelcenter, {
            icon: divicon,
        })

        markers.addLayer(label)
        var popupcontent = featurestyles.PopupContentByFeature[originalName]

        if (popupcontent) {
            label.bindPopup(popupcontent)
        }

        var label$ = $("." + classname)

        if (true)
        label$.on('click', function () {
            var t$ = $(this)
            var FeatureNameKey = FeatureNameByLabelID[t$.attr('id')]
            console.log('click on label',FeatureNameKey)
            if (!FeatureNameKey) {
                console.log('label has no associated FeatureNameKey [' + t$.attr('id') + ']')
            } else {
                toggleFeatureGroupHighlight(FeatureNameKey)
            }
        })

        label$.attr('id', labelid)

        labelCssMarkers[labelid] = label

        var R = def.Defaults

        LabelMap[labelid] = R

        try {
            var T = {}
            for (var key in R) {
                var property = R[key]
                var value = property.Value

                if (key == 'Name') {
                    value = labelname
                }

                var type = property.Type

                if (type == 'checkbox') {
                    value = property.Checked
                }

                T[key] = value
            }

            Defaults[labelid] = props.ZWAR_XTRA[0]

            Exceptions[labelid] = jQuery.extend({}, props.ZWAR_XTRA)

            updateLabel(loadExceptionAtLevel(labelid, cLzoom), label$)
        } catch (e) {
            alert('exceptions: ' + e)
        }
    }

    return {
        closePopups: function () {
            map.closePopup();
        },
        findBySectionByFeatureName: function (s) {
            return findBySectionByFeatureName(s)
        },
        findBySectionBySectionName: function (s) {
            return findBySectionBySectionName(s)
        },
        initMap: function (centerx, centery, zoom, layers) {
            return initMap(centerx, centery, zoom, layers)
        },
        toggleFeatureGroup: function (section, onoff) {
            toggleFeatureGroup(section, onoff)
        },
        loadMap: function (geoLayerFileName, geoData) {
            loadLabelsMap(geoLayerFileName, geoData)
        }
    };
}

var Defaults = {}
var Exceptions = {}

function fillEditor(labelid) {
    var tableDefaultRow$ = $('#table-default-row')
    var Default = Defaults[labelid]
    var nonDefaultRows$ = $('#table-non-default-rows')
    nonDefaultRows$.empty()

    for (var level = 1; level <= 8; level++) {
        var nonDefaultRow$ = tableDefaultRow$.clone()
        nonDefaultRow$.attr('id', 'Level' + level)
        nonDefaultRows$.append(nonDefaultRow$)

        var R = false

        if (Exceptions[labelid]) {
            R = Exceptions[labelid][level]
        }

        if (!R) {
            R = {}
        }

        var X = $.extend({}, Default)
        R = $.extend(X, R)

        for (var key in R) {
            var value = R[key]
            var span$ = $("span[name='" + key + "']").prop('label', labelid).prop('name', key)
            var in$ = $("[name='" + key + "']", nonDefaultRow$)
            in$.prop('label', labelid)
            in$.attr('label', labelid)
            var type = in$.prop('type')

            if (type && type == 'checkbox') {
                in$.prop('checked', value)
            }

            in$.val(value)
            in$.data('level', level)
        }
        $("[name='__IsEnabled']", nonDefaultRow$).prop('checked', false)
        $(".row-level-value", nonDefaultRow$).html(level)
    }

    var RowSelected$ = $("#Level" + cLzoom)
    RowSelected$.get()[0].scrollIntoView(true)
    RowSelected$.addClass('danger')

    $("#label-names [name='Name']").val(Exceptions[labelid][0]['Name'])
    $("#label-names [name='NameML']").val(Exceptions[labelid][0]['NameML'])
}

function selectLabel(labelid, l$) {
    try {
        displayEditor(true)
        cL$ = l$
        $(".label-clicked").removeClass("label-clicked")
        fillEditor(labelid)
        updateLabel(loadExceptionAtLevel(labelid, cLzoom))

        labelCssMarkers[labelid].dragging.enable()
        var current = false; // labelCss[labelid][cLzoom]

        l$.addClass('label-clicked')
        $(".danger").removeClass('danger')
        var RowSelected$ = $("#Level" + cLzoom)
        RowSelected$.get()[0].scrollIntoView(true)
        RowSelected$.addClass('danger')

        var Defaults = LabelMap[labelid]
    } catch (e) {
        alert(e)
    }
}

$("#editor-select-label").on('change', function () {
    var s$ = $(this)
    var value = s$.val()
    if (value == '') {
        unselectLabel()
    } else {
        selectLabel(value, $("." + value))
    }
})

function unselectLabel() {
    displayEditor(false)
    $('.label-clicked').removeClass('label-clicked')
}

$(window).on('keydown', function (e) {
    var keycode = e.keyCode
    var isCtrlKey = e.ctrlKey
    if (keycode == 32 && isCtrlKey) {
        toggleDisabledLabelsAreVisible()
    }
})

function toggleDisabledLabelsAreVisible(mode) {
    var style$ = $("#style-disabled-is-invisible")
    var isDisabled = style$.prop('disabled')
    style$.prop('disabled', !isDisabled)
}

$(document).on('click', '#table-non-default-rows span[name]', function () {
    var span$ = $(this)
    var parent$ = span$.parent()
    var spanname = span$.prop('name')
    var labelid = span$.prop('label')
    var in$ = $("[name='" + spanname + "'][label='" + labelid + "']", parent$)
    var intype = in$.prop('type')
    var level = in$.data('level')
    var inname = spanname

    if (intype == 'checkbox') {
        value = in$.prop('checked')
    } else {
        value = in$.val()
    }

    if (!Exceptions[labelid]) {
        Exceptions[labelid] = {}
    }

    for (var i = level + 1; i <= 8; i++) {
        if (!Exceptions[labelid][i]) {
            Exceptions[labelid][i] = {}
        }

        Exceptions[labelid][i][inname] = value

        if (cLzoom == i) {
            updateLabel(loadExceptionAtLevel(labelid, i))
        }
    }

    saveExceptions.save();
    fillEditor(labelid)
})

function loadExceptionAtLevel(labelid, level) {
    if (!Exceptions[labelid]) {
        return {}
    }
    if (!Exceptions[labelid][level]) {
        return {}
    }
    return Exceptions[labelid][level]
}

function updateLabel(R, label$) {
    if (!R) {
        R = {}
    }

    if (!label$) {
        label$ = cL$
    }

    var labelid = label$.attr('id')
    var X = $.extend({}, Defaults[labelid])
    R = $.extend(X, R)

    var FontSize = R['FontSize']
    var FontWeight = R['FontWeight']
    var Name = R['NameML']
    var Visibility = R['Visibility']
    var Color = R['Color']

    if (Name) {
        label$.html(Name)
    }

    if (FontSize) {
        label$.css("font-size", FontSize + "px")
    }

    if (FontWeight) {
        label$.css("font-weight", FontWeight)
    }

    if (Visibility !== 'undefined') {
        if (Visibility) {
            label$.removeClass("label-disabled")
        } else {
            label$.addClass("label-disabled")
        }
    }

    if (Color) {
        label$.css("color", Color)
    }

    var marker = labelCssMarkers[labelid]

    if (marker) {
        marker.setLatLng(new L.LatLng(R['Lat'], R['Lng']), {draggable: 'false'});
    }
}

function updateDisplay(zoomLevel) {
    for (var k in labelCss) {
        if (labelCssPos[k]) {
            var position = labelCssPos[k][cLzoom]

            if (position) {
                labelCssMarkers[k].setLatLng(new L.LatLng(position.lat, position.lng), {draggable: 'false'});
            }
        }

        var cur = labelCss[k][zoomLevel]
        var l$ = $("#" + k)
        for (var prop in cur) {
            var val = cur[prop]
            l$.css(prop, val)
        }
    }
}

function updateCurrentLabel() {
    var current = {}

    current['margin-left'] = cL$.css('margin-left')
    current['margin-top'] = cL$.css('margin-top')
    current['font-size'] = cL$.css('font-size')
    current['color'] = cL$.css('color')
    current['display'] = cL$.css('display')
    current['font-weight'] = cL$.css('font-weight')

    labelCss[cL$.attr('id')][cLzoom] = current

}

var greenIcon = L.icon({
    iconUrl: 'icons/deutschesreich400.png',
    iconSize: [400, 61], // size of the icon
    iconAnchor: [200, 100], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

// berlin 52.5075419,13.4251364

var cL$ = false
var cLpos$ = false
var osm = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
var m1 = L.marker([52.5075419, 13.4251364], {icon: greenIcon})

var current = {}
var labelCss = {}
var labelEntries = {}
var labelCssPos = {}
var labelCssManual = {}
var labelCssMarkers = {}

var cLzoom = 4
var defaultZoomLevel = cLzoom

$(document).on('change', '.info-input', function () {
    var in$ = $(this)
    var name = in$.attr('name')
    var type = in$.prop('type')
    var value = false
    var id = cL$.attr('id')
    var cssproperty = in$.attr('css-property')

    if (type == 'checkbox') {
        var valchecked = in$.attr('css-value-checked')
        var cssClassChecked = in$.attr('css-class-checked')
        var valunchecked = in$.attr('css-value-unchecked')

        if (cssClassChecked) {
            if (in$.prop('checked')) {
                cL$.addClass(cssClassChecked)
            } else {
                cL$.removeClass(cssClassChecked)
            }

        } else {
            if (in$.prop('checked')) {
                cL$.css(cssproperty, valchecked)
                value = valchecked
            } else {
                cL$.css(cssproperty, valunchecked)
                value = valunchecked
            }
        }
    } else {
        cL$.css(cssproperty, in$.val())
        value = in$.val()
    }

    if (value) {
        labelCss[id][cLzoom][cssproperty] = value
        labelCssManual[id][cLzoom][cssproperty] = true
    }

    for (var i = cLzoom + 1; i <= 8; i++) {
        var isManual = labelCssManual[id][i][cssproperty]

        if (isManual) {
            continue
        }

        labelCss[id][i][cssproperty] = value
    }
})

$("#editor-select-label").on('change', function () {
    var s$ = $(this)
    var value = s$.val()
    if (value == '') {
        unselectLabel()
    } else {
        selectLabel(value, $("." + value))
    }
})

var LabelMap = {}
