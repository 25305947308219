<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { FilterCategory } from './types';

export default defineComponent({
  props: {
    categories: {
      type: Array as PropType<Array<FilterCategory>>,
      required: true,
    },
    filterHash: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    notEmptyCategories(): Array<FilterCategory> {
      return this.categories.filter((category: FilterCategory) =>
        category.count > 0);
    },
  },
});
</script>

<template>
<div>
  <p v-if="isLoading">
    {{ $t('loading') }}
  </p>
  <div v-else class="filter">
    <button
      v-for="category in notEmptyCategories"
      :key="category.value"
      @click="onChange(category.value)"
      class="filter__button"
      :class="{'filter__button--active': filterHash[category.value]}">
      <span>
        {{ category.value }}
      </span>
      <span v-if="Number.isInteger(category.count)"
        class="filter__count u-ml-tiny">
        {{ category.count }}
      </span>
    </button>
  </div>
</div>
</template>

<style scoped>
.filter {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.filter__button {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid gray;
  display: inline-block;
  font-size: var(--font-size-small);
  line-height: var(--base-unit);
  margin-bottom: calc(var(--base-unit) / 4);
  margin-right: calc(var(--base-unit) / 4);
  padding: 0 calc(var(--base-unit) / 2);
  transition: all 125ms;
}
.filter__count {
  color: var(--color-subdued);
}
.filter__button--active {
  background-color: var(--theme-color);
  color: white;
}
.filter__button--active > .filter__count {
  color: white;
}
</style>
