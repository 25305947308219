import { defineStore } from 'pinia';

import timeToSeconds from './timeToSeconds';

export const useVideoStore = defineStore('video', {
  state: () => ({
    duration: 0,
    currentTime: 0,
    videoLoaded: false,
    isPlaying: false,
    clipPanelIsOpen: false,
    player: null,
  }),
  getters: {
    //doubleCount: (state) => state.count * 2,
  },
  actions: {
    changeVideoTime(timecode: string) {
      this.jumpToSeconds(timeToSeconds(timecode));
    },
    jumpToSeconds(seconds: number) {
      const player = this.player;
      player.currentTime(seconds);
      if (player.paused()) {
        player.play();
      }
    },
  },
});
