import { Exercise } from '../types';

export default function filterByTags(tags: Array<string>,
  exercises: Array<Exercise>) {
  return exercises.filter((exercise: Exercise) => {
    if (tags.length === 0) {
      return true;
    }
    return tags.some((tag: string) =>
      exercise.tags.includes(tag));
  });
}
