<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';

import { useDictionaryStore } from './dictionary_store';
import DefinitionDictionary from './DefinitionDictionary.vue';

export default defineComponent({
  components: {
    DefinitionDictionary,
  },
  props: {
    entriesJson: String,
  },
  methods: {
    ...mapActions(useDictionaryStore, ['initialize']),
  },
  beforeMount() {
    this.initialize(this.entriesJson);
  },
});
</script>

<template>
<DefinitionDictionary skip-heading />
</template>

<style scoped>
</style>
