<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import { useExercisesStore } from '../chapter/exercises_store';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    includeExercise: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(useExercisesStore, ['chapterTitle']),
  },
  emits: ['update:modelValue'],
});
</script>

<template>
<div class="radio-menu u-mb-small">
  <span v-if="includeExercise">
    <input type="radio" id="exercise" class="radio-input"
      value="exercise" :checked="modelValue==='exercise'"
      @change="$emit('update:modelValue', 'exercise')"/>
    <label class="radio-label" for="exercise">
      {{ $t('components.materials.for_exercise') }}
    </label>
  </span>

  <span>
    <input type="radio" id="chapter" class="radio-input"
      value="chapter" :checked="modelValue==='chapter'"
      @change="$emit('update:modelValue', 'chapter')"/>
    <label class="radio-label" for="chapter">
      {{ $t('components.materials.for_chapter', { name: chapterTitle }) }}
    </label>
  </span>

  <span>
    <input type="radio" id="all" class="radio-input"
      value="all" :checked="modelValue==='all'"
      @change="$emit('update:modelValue', 'all')" />
    <label class="radio-label" for="all">
      {{ $t('components.materials.all') }}
    </label>
  </span>
</div>
</template>

<style scoped>
.radio-menu {
  display: flex;
  align-items: center;
}
.radio-input {
  margin-top: 0;
}
.radio-label {
  font-weight: 400;
  margin-bottom: 0;
  margin-left: calc(var(--base-unit) / 4);
  margin-right: calc(var(--base-unit) / 2);
  margin-top: 0;
  user-select: none;
}
</style>
