<script lang="ts">
import { defineComponent } from 'vue';

import FeedbackForm from './FeedbackForm.vue';
import submitFeedback from './submitFeedback';

export default defineComponent({
  components: {
    FeedbackForm,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    formalAddress: Boolean,
  },
  data() {
    return {
      feedbackText: '',
      error: false,
      success: false,
      submitting: false,
    };
  },
  methods: {
    handleClose() {
      this.onClose();
      this.error = false;
      this.success = false;
    },
    async handleSubmit() {
      this.submitting = true;
      const response = await submitFeedback(this.feedbackText);
      this.submitting = false;

      if (response?.result === 'success') {
        this.success = true;
        this.error = false;
        this.feedbackText = '';
      } else {
        this.error = true;
      }
    },
    handleOk() {
      this.onClose();
      this.success = false;
    },
  },
});
</script>

<template>
<aside class="popup" :class="{'popup--visible': visible}">
  <button type="button" class="popup__close"
    @click="handleClose"
    :title="$t('general.close')">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <div class="popup__inner">
    <FeedbackForm v-model="feedbackText"
      :on-submit="handleSubmit"
      :on-okay="handleOk"
      :submitting="submitting"
      :error="error"
      :success="success"
      :formal-address="formalAddress">
    </FeedbackForm>
  </div>
</aside>
</template>
