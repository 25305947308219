<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { Marker } from '../types';

export default defineComponent({
  props: {
    markers: {
      type: Array as PropType<Array<Marker>>,
      required: true,
    },
    imageUrl: String,
    activeMarkerId: {
      type: String,
      required: false,
    },
    biography: String,
    focusMarker: {
      type: Function,
      required: true,
    },
  },
});
</script>

<template>
<div>
  <figure class="image u-mt-small u-mb u-ml">
    <img :src="imageUrl" width="160" />
  </figure>

  <article v-for="marker in markers"
    class="article"
    :class="{ 'article--active': marker.number === activeMarkerId }">
    <h6 class="title">
      {{ marker.date }}: {{ marker.title }}
      <button type="button"
        class="button button--icon"
        @click="focusMarker(marker.number)">
        <span class="glyphicon glyphicon-link"></span>
      </button>
    </h6>
    <p class="paragraph" v-html="marker.description"></p>
  </article>

  <div v-if="biography">
    <h3 class="heading heading--large u-mt">{{ $t('components.biography-component.long_biography') }}</h3>
    <div v-html="biography"></div>
  </div>
</div>
</template>

<style scoped>
.image {
  float: right;
}
.article {
  margin-left: calc(var(--base-unit) * -0.5);
  margin-right: calc(var(--base-unit) * -0.5);
  padding: calc(var(--base-unit) * 0.5);
  transition: background-color 125ms;
}
.article--active {
  background-color: var(--bg-color-warning);
}
.title {
  font-size: var(--font-size-normal);
  font-weight: bold;
  line-height: var(--base-unit);
  margin-bottom: 0;
  margin-top: 0;
}
.paragraph {
  margin: 0;
}
</style>
