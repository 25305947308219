<script lang="ts">
import { defineComponent } from 'vue';
import tinymce, { Editor } from 'tinymce';

import 'tinymce/icons/default';
/* What is this? */
import 'tinymce/models/dom/model';
/* A theme is also required */
import 'tinymce/themes/silver';
/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';
/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';
import 'tinymce-i18n/langs6/cs';
import 'tinymce-i18n/langs6/de';
import 'tinymce-i18n/langs6/el';
import 'tinymce-i18n/langs6/nl';
import 'tinymce-i18n/langs6/pl';
import 'tinymce-i18n/langs6/ru';
/* Import content css */
import contentUiCss from 'tinymce/skins/ui/oxide/content.css?inline';
import contentCss from 'tinymce/skins/content/default/content.css?inline';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
    initialContent: {
      type: String,
      required: false,
    },
    onContentChange: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    // This is used to influence the order in which editors are
    // initialized on the same page.
    delayInitialization: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      language: document.documentElement.lang,
    };
  },
  methods: {
    initialize() {
      if (this.delayInitialization) {
        this.$nextTick(() => {
          this.initializeTextEditor();
        });
      } else {
        this.initializeTextEditor();
      }
    },
    async initializeTextEditor() {
      const editorArray = await tinymce.init({
        selector: `#${this.id}`,
        language: this.language,
        plugins: 'fullscreen table wordcount textcolor',
        toolbar: 'undo redo | styles bold italic underline forecolor backcolor | table fullscreen',
        table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        menubar: false,
        skin: false,
        content_css: false,
        content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
        relative_urls: false,
      });

      const editor = editorArray[0];
      this.editor = editor;
      editor.setContent(this.initialContent);
      editor.setDirty(false);

      editor.on('blur', (event) => {
        const content = editor.getContent();
        this.onContentChange(content);
      });
    },
    addEventListener() {
      window.addEventListener('own-router:exercise:enter', this.updateEditorContent);
    },
    removeEventListener() {
      window.removeEventListener('own-router:exercise:enter', this.updateEditorContent);
    },
    updateEditorContent() {
      this.editor?.setContent(this.initialContent || '');
      this.editor?.setDirty(false);
    },
  },
  mounted() {
    this.initialize();
    this.addEventListener();
  },
  beforeUnmount() {
    this.editor?.destroy();
    this.removeEventListener();
  },
});
</script>

<template>
<textarea
  :id="id"
  :class="class"
  :placeholder="placeholder"></textarea>
</template>
