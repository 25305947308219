<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapWritableState, mapActions } from 'pinia';

import { User, UsersExercise } from '../types';
import { useResultsStore } from './results_store';
import SavingIndicator from '../SavingIndicator.vue';
import Collapsible from '../Collapsible.vue';
import HtmlEditorTiny from '../HtmlEditorTiny.vue';
import SlideShow from './SlideShow.vue';
import ExerciseResult from './ExerciseResult.vue';

export default defineComponent({
  components: {
    Collapsible,
    ExerciseResult,
    HtmlEditorTiny,
    SavingIndicator,
    SlideShow,
},
  props: {
    userJson: {
      type: String,
      required: true,
    },
    usersExercisesJson: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState(useResultsStore, [
      'usersExercises',
      'isFormalAddress',
      'saving',
      'savingUsersExercise',
      'bookletDataComplete',
      'introductionComplete',
      'summaryComplete',
    ]),
    ...mapWritableState(useResultsStore, [
      'bookletCover',
      'bookletIntroduction',
      'bookletSummary',
      'checkedStates',
    ]),
    groupedUsersExercises() {
      const exercisesByChapter = this.usersExercises.reduce((acc: object, ue: UsersExercise) => {
        if (!(ue.chapter.id in acc)) {
          acc[ue.chapter.id] = {
            chapter: ue.chapter,
            usersExercises: [],
          };
        }
        acc[ue.chapter.id].usersExercises.push(ue);
        return acc;
      }, {});

      return Object.values(exercisesByChapter);
    },
  },
  methods: {
    ...mapActions(useResultsStore, [
      'initialize',
      'saveBooklet',
      'saveUsersExercise',
    ]),
    handleBookletInputBlur() {
      if (this.readOnly) {
        return;
      }
      this.saveBooklet();
    },
    handleIntroductionChange(newValue: String) {
      this.bookletIntroduction = newValue;
      this.handleBookletInputBlur();
    },
    handleSummaryChange(newValue: String) {
      this.bookletSummary = newValue;
      this.handleBookletInputBlur();
    },
    handleSlideShowClick() {
      this.$router.push('/presentation');
    },
  },
  beforeMount() {
    const user: User = JSON.parse(this.userJson);
    const usersExercises: Array<UsersExercise> = JSON.parse(this.usersExercisesJson);
    this.initialize(usersExercises, user);
  },
});
</script>

<template>
<Teleport to="body">
  <router-view name="slideshow"></router-view>
</Teleport>

<p class="paragraph"
  v-html="$t(`components.resultsForm.introduction_${isFormalAddress ? 'formal' : 'informal'}_html`)">
</p>

<div class="u-mt">
  <Collapsible id="booklet"
    :label="$t('components.resultsForm.cover')"
    :complete="bookletDataComplete">
    <form class="booklet-form u-mt-small">
      <div class="form-group">
        <label for="booklet-title-input">
          {{ $t('components.resultsForm.booklet_title') }}
        </label>
        <input id="booklet-title-input" class="form-control"
          v-model="bookletCover.booklet_title"
          :readonly="readOnly"
          @blur="handleBookletInputBlur" />
      </div>
      <div class="form-group">
        <label for="booklet-subtitle-input">
          {{ $t('components.resultsForm.booklet_subtitle') }}
        </label>
        <input id="booklet-subtitle-input" class="form-control"
          v-model="bookletCover.booklet_subtitle"
          :readonly="readOnly"
          @blur="handleBookletInputBlur" />
      </div>
      <div class="form-group">
        <label for="booklet-image-url-input">
          {{ $t('components.resultsForm.booklet_image_url') }}
        </label>
        <input type="url"
          id="booklet-image-url-input" class="form-control"
          pattern="https?://.*"
          v-model="bookletCover.booklet_image_url"
          :readonly="readOnly"
          @blur="handleBookletInputBlur" />
      </div>
      <div class="form-group">
        <label for="booklet-image-attribution-input">
          {{ $t('components.resultsForm.booklet_image_attribution') }}
        </label>
        <input id="booklet-image-attribution-input" class="form-control"
          v-model="bookletCover.booklet_image_attribution"
          :readonly="readOnly"
          @blur="handleBookletInputBlur" />
      </div>
      <div class="form-group">
        <label for="booklet-author-input">
          {{ $t('components.resultsForm.booklet_author') }}
        </label>
        <input id="booklet-author-input" class="form-control"
          v-model="bookletCover.booklet_author"
          :readonly="readOnly"
          @blur="handleBookletInputBlur" />
      </div>
      <div class="form-group">
        <label for="booklet-course-input">
          {{ $t('components.resultsForm.booklet_course') }}
        </label>
        <input id="booklet-course-input" class="form-control"
          v-model="bookletCover.booklet_course"
          :readonly="readOnly"
          @blur="handleBookletInputBlur" />
      </div>
      <div class="form-group">
        <label for="booklet-date-input">
          {{ $t('components.resultsForm.booklet_date') }}
        </label>
        <input id="booklet-date-input" class="form-control"
          v-model="bookletCover.booklet_date"
          :readonly="readOnly"
          @blur="handleBookletInputBlur" />
      </div>
    </form>
    <SavingIndicator :saving="saving" />
  </Collapsible>
</div>

<div class="u-mt">
  <Collapsible id="introduction"
    :label="$t('components.resultsForm.introduction')"
    :complete="introductionComplete">
    <form class="introduction-form u-mt-small">
      <HtmlEditorTiny
        id="editor-introduction"
        :initial-content="bookletIntroduction"
        :on-content-change="handleIntroductionChange"
        :placeholder="$t('components.resultsForm.introduction')"
      />
      <SavingIndicator :saving="saving" />
    </form>
  </Collapsible>
</div>

<div v-for="chapterGroup in groupedUsersExercises"
  class="o-media o-media--small u-mt">
  <img :src="chapterGroup.chapter.image_path"
    class="o-media__img" alt="chapter image" />
  <div class="o-media__body">
    <div>
      <a class="u-bold"
        :href="`/${chapterGroup.chapter.section.slug}/${chapterGroup.chapter.slug}`">
        {{ chapterGroup.chapter.title }}
      </a>
    </div>

    <ul class="exercise-result-list u-mt-small">
      <li v-for="ue in chapterGroup.usersExercises"
        class="exercise-result u-mb">
        <a class="exercise-result__collapse" data-toggle="collapse"
          :href="`#collapse-ue-${ue.id}`"
          role="button" aria-expanded="false"
          aria-controls="collapseExample">
          <h4 class="exercise-result__title">
            {{ ue.exercise.title_formal }}
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
          </h4>
        </a>
        <div class="exercise-result__actions">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                v-model="checkedStates[ue.id]"
                data-test-id="in-booklet-checkbox"
                :disabled="readOnly"
                @change="saveUsersExercise(ue.id)" />
              {{ $t('components.resultsForm.in_booklet') }}
            </label>
          </div>
          <a class="u-ml-small"
            :href="`/${ue.chapter.section.slug}/${ue.chapter.slug}#/exercises/${ue.exercise.id}`">
            {{ $t('general.edit') }}
          </a>
        </div>
        <div :id="`collapse-ue-${ue.id}`" class="collapse">
          <ExerciseResult class="u-ml-small"
            :users-exercise="ue" :formal="isFormalAddress" />
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="u-mt-small">
  <Collapsible id="summary"
    :label="$t('components.resultsForm.summary')"
    :complete="summaryComplete">
    <form class="summary-form u-mt-small">
      <HtmlEditorTiny
        id="editor-summary"
        :initial-content="bookletSummary"
        :on-content-change="handleSummaryChange"
        :placeholder="$t('components.resultsForm.summary')"
      />
      <SavingIndicator :saving="saving" />
    </form>
  </Collapsible>
</div>

<div class="u-mt-large">
  <div>
    <button type="button" class="play-button"
      id="start-presentation"
      @click="handleSlideShowClick">
      <i class="fa fa-play" aria-hidden="true"></i>
      {{ $t('components.resultsForm.start_presentation') }}
    </button>
  </div>
  <div class="u-mt">
    <a href="/admin/my_results.pdf"
      target="_blank" rel="noreferrer" data-test-id="export-pdf-link">
      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
      {{ $t('components.resultsForm.pdf_booklet') }}
    </a>
  </div>
</div>
</template>

<style scoped>
.booklet-form,
.introduction-form,
.summary-form {
  max-width: calc(var(--base-unit) * 35);
}

.o-media__img {
  max-width: calc(var(--base-unit) * 2);
  max-height: calc(var(--base-unit) * 4);
}

.play-button {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid currentColor;
  color: var(--theme-color);
  font-size: var(--font-size-large);
  line-height: var(--base-unit);
  padding: calc(var(--base-unit) * 0.25) calc(var(--base-unit) * 0.5);
}

.exercise-result-list {
  padding-left: var(--base-unit);
  margin: 0;
  list-style-type: none;
}

.exercise-result {
}
.exercise-result__title {
  font-size: var(--font-size-normal);
  margin: 0;
  line-height: var(--base-unit);
}

.exercise-result__collapse.collapsed .fa-chevron-up {
  display: none;
}

.exercise-result__collapse:not(.collapsed) .fa-chevron-down {
  display: none;
}

.exercise-result__actions {
  display: flex;
  font-size: var(--font-size-small);
  align-items: center;
  line-height: var(--base-unit);
}

.exercise-result__actions .checkbox {
  color: var(--color-subdued);
  font-style: italic;
  margin: 0;
}
</style>
