<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { mapState, mapActions } from 'pinia';

import { useResultsStore } from './results_store';
import SlideModal from './SlideModal.vue';
import UsersExerciseContent from './UsersExerciseContent.vue';
import addIFrameToVideoLink from './addIFrameToVideoLink';
import { UsersExercise, Section } from '../types';

export default defineComponent({
  components: {
    SlideModal,
    UsersExerciseContent,
},
  data() {
    return {
      currentSlide: 1,
    };
  },
  props: {
  },
  computed: {
    ...mapState(useResultsStore, [
      'checkedUsersExercises',
      'isFormalAddress',
      'bookletCover',
      'bookletIntroduction',
      'bookletSummary',
    ]),
    showIntroduction() {
      return this.bookletIntroduction.trim().length > 0;
    },
    showSummary() {
      return this.bookletSummary.trim().length > 0;
    },
    slidesBeforeExercises() {
      return this.showIntroduction ? 2 : 1;
    },
    slidesAfterExercises() {
      return this.showSummary ? 1 : 0;
    },
    slideCount() {
      return this.slidesBeforeExercises
        + this.checkedUsersExercises.length
        + this.slidesAfterExercises;
    },
    summaryPosition() {
      return this.slidesBeforeExercises + this.checkedUsersExercises.length + 1;
    },
    displayTitle(): string {
      const result = this.bookletCover.booklet_title.trim().length > 0
        ? this.bookletCover.booklet_title.trim()
        : this.$t('components.slide-show.default_title');
      return result;
    },
    currentUsersExercise() {
      let result: UsersExercise | null;
      if (this.currentSlide <= this.slidesBeforeExercises
       || this.currentSlide > this.slidesBeforeExercises + this.checkedUsersExercises.length) {
        result = null;
      } else {
        result = this.checkedUsersExercises[this.currentSlide - (this.slidesBeforeExercises + 1)];
      }
      return result;
    },
  },
  methods: {
    ...mapActions(useResultsStore, []),
    prevSlide() {
      this.currentSlide -= 1;
    },
    nextSlide() {
      this.currentSlide += 1;
    },
    handleClose() {
      this.$router.push('/');
    },
    attachHandlerToVideoLink() {
      const $el = $(this.$el);
      const $videoLink = $el.find('.videoclip-anchor');
      $videoLink.on('click', addIFrameToVideoLink);
    },
  },
  mounted() {
    this.$el.focus();
    this.attachHandlerToVideoLink();
  },
});
</script>

<template>
<SlideModal
  :title="displayTitle"
  :current="currentSlide"
  :count="slideCount"
  :on-close="handleClose"
  :on-left="prevSlide"
  :on-right="nextSlide">
<article class="slide-show">
  <section v-if="currentSlide === 1">
    <h1 class="slide-show__booklet-title">
      {{displayTitle}}
    </h1>
    <p v-if="bookletCover.booklet_subtitle" class="slide-show__booklet-text">
      {{bookletCover.booklet_subtitle}}
    </p>
    <figure v-if="bookletCover.booklet_image_url" class="slide-show__booklet-text">
      <img :src="bookletCover.booklet_image_url" alt="" class="slide-show__booklet-image" />
      <figcaption>
        {{bookletCover.booklet_image_attribution}}
      </figcaption>
    </figure>
    <p v-if="bookletCover.booklet_author" class="slide-show__booklet-text">
      {{bookletCover.booklet_author}}
    </p>
    <p v-if="bookletCover.booklet_course" class="slide-show__booklet-text">
      {{bookletCover.booklet_course}}
    </p>
    <p v-if="bookletCover.booklet_date" class="slide-show__booklet-text">
      {{bookletCover.booklet_date}}
    </p>
  </section>

  <section v-if="showIntroduction && currentSlide === 2">
    <h2 class="slide-show__section-title">
      {{ $t('components.resultsForm.introduction') }}
    </h2>
    <div v-html="bookletIntroduction" class="u-mt"></div>
  </section>

  <UsersExerciseContent v-if="currentUsersExercise"
    :users-exercise="currentUsersExercise"
    :formal="isFormalAddress" />

  <section v-if="showSummary && currentSlide === summaryPosition">
    <h2 class="slide-show__section-title">
      {{ $t('components.resultsForm.summary') }}
    </h2>
    <div v-html="bookletSummary" class="u-mt"></div>
  </section>
</article>
</SlideModal>
</template>
