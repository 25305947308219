import { createI18n } from 'vue-i18n';

import de from '~/locales/de.json';
import en from '~/locales/en.json';
import el from '~/locales/el.json';
import nl from '~/locales/nl.json';
import pl from '~/locales/pl.json';
import cs from '~/locales/cs.json';
import ru from '~/locales/ru.json';

export default function setupI18n() {
  const locale = document.documentElement.lang;

  const i18n = createI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
      cs,
      de,
      el,
      en,
      nl,
      pl,
      ru,
    },
  });

  return i18n;
}
