import { Exercise } from '../types';

export default function filterBySubjects(subjects: Array<string>,
  exercises: Array<Exercise>) {
  return exercises.filter((exercise: Exercise) => {
    if (subjects.length === 0) {
      return true;
    }
    return subjects.some((subject: string) =>
      exercise.subjects.includes(subject));
  });
}
