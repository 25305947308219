import $ from 'jquery';

$(() => {
  $(".generate-new-password").on("click", function(ev){
    const answer = confirm(I18n["generate_new_password"] + ": " + I18n["are_you_sure?"]);
    if (answer){
      const $this = $(this);
      const userId = $this.data("id");
      const url = `/admin/users/${userId}/generate_new_password`;

      $this.html("<i class='fa fa-circle-o-notch fa-spin'></i>");

      $.ajax({
        method: "GET",
        url,
        success: function (msg) {
          $this.html("<samp>" + msg +"</samp>");
          $this.off("click").removeClass("btn btn-default btn-sm");
        },
        fail: function () {
          $this.html("offline");
        }
      });
    }
  });
});
