<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import { useGlobalStore } from '../global_store';
import PopoverButton from './PopoverButton.vue';

export default defineComponent({
  components: {
    PopoverButton,
  },
  props: {
    textHtml: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useGlobalStore, ['teacherMode']),
  },
});
</script>

<template>
<PopoverButton v-if="teacherMode" id="teacher-title"
  :text-html="textHtml" />
</template>
