<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    textHtml: {
      type: String,
      required: true,
    },
  },
  mounted() {
    $(this.$el).popover({
      html: true,
    });
  },
});
</script>

<template>
<button
  v-if="textHtml"
  type="button"
  tabindex="0"
  class="educational-popover-button"
  :title="$t('components.exerciseRow.educational_remark')"
  :data-content="textHtml"
  data-toggle="popover"
  data-trigger="focus"
  data-placement="right"
  :data-test-id="id">
  <i class="fa fa-info" aria-hidden="true"></i>
</button>
</template>

<style>
.popover {
  max-width: 600px;
  color: var(--color-text);
}
</style>
