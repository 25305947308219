import $ from 'jquery';

$(() => {
  const country_selector = document.getElementsByClassName('country-select')[0];
  const country_text_field = document.getElementsByClassName('country-text-field')[0];

  if (!country_selector) {
    return;
  }

  country_selector.onchange = function() {
    if (this.options.length == country_selector.selectedIndex + 1) {
      $(country_text_field).removeClass('hidden');
      $(country_text_field).val('')
    } else {
      $(country_text_field).addClass('hidden');
      $(country_text_field).val($(country_selector).val());
    }
  }
});
