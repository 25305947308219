<script lang="ts">
import { defineComponent } from 'vue';

import PopoverButton from './PopoverButton.vue';

export default defineComponent({
  components: {
    PopoverButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    teacherContent: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
});
</script>

<template>
<h2 class="title">
  <button type="button"
    class="heading-collapsible-button"
    :aria-controls="id"
    :aria-expanded="expanded"
    @click="toggleExpanded">
    {{ heading }}

    <i class="fa fa-chevron-down" aria-hidden="true" v-if="!expanded"></i>
    <i class="fa fa-chevron-up" aria-hidden="true" v-if="expanded"></i>
  </button>

  <span v-if="teacherContent">
    <PopoverButton id="related-exercise-info-button"
      :text-html="teacherContent" />
  </span>
</h2>
<div :id="id" v-show="expanded">
  <slot />
</div>
</template>

<style scoped>
.heading-collapsible-button {
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
}

.heading-collapsible-button:hover {
  background-color: var(--color-light-gray);
}
</style>
