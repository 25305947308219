import { defineStore } from 'pinia';

import { UsersExercise, User } from '../types';

export const useDictionaryStore = defineStore('dictionary', {
  state: () => ({
    entries: null,
  }),
  getters: {
  },
  actions: {
    initialize(entriesJson: string) {
      this.entries = JSON.parse(entriesJson);
    },
  },
});
