// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/popover.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/tab.js';

import Rails from '@rails/ujs';
Rails.start();

import '../vue/vue-main-app';

import '../jquery/addGroupUser';
import '../jquery/generateNewGroupPassword';
import '../jquery/init-thematic-map';
import '../jquery/messages';
import '../jquery/printGroupPasswords';
import '../jquery/registrationForm';
import '../jquery/renameGroupUser';
import '../jquery/section-nav';
import '../jquery/sections';
import '../jquery/showHidePassword';
import '../jquery/startpage-video';
import '../jquery/tag-cloud';
import '../jquery/video_tabs';
