<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';

import { useTimelineStore } from './timeline_store';
import TimelineComponent from './TimelineComponent.vue';

export default defineComponent({
  components: {
    TimelineComponent,
  },
  props: {
    timelineEntriesJson: String,
  },
  methods: {
    ...mapActions(useTimelineStore, ['initializeTimelineData']),
  },
  beforeMount() {
    this.initializeTimelineData(this.timelineEntriesJson);
  },
});
</script>

<template>
<TimelineComponent skip-heading />
</template>
