export default function addIFrameToVideoLink(ev: Event) {
  ev.preventDefault();
  if (iFrameAlreadyExists(this)) {
    return;
  }

  $(this).find('img').addClass('visible-print');
  $(this).prepend(
    `<iframe class="hidden-print" height="360" width="480"
scrolling="no" src="${$(this).attr('href')}"
style="overflow: hidden; border-width: 0;">`);
}

function iFrameAlreadyExists(el: HTMLElement): boolean {
  return $(el).find('iframe').length > 0;
}
