<script lang="ts">
import $ from 'jquery';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { UsersExercise } from '../types';
import addIFrameToVideoLink from './addIFrameToVideoLink';

export default defineComponent({
  props: {
    usersExercise: {
      type: Object as PropType<UsersExercise>,
      required: true,
    },
    formal: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    attachHandlerToVideoLink() {
      const $el = $(this.$el);
      const $videoLink = $el.find('.videoclip-anchor');
      $videoLink.on('click', addIFrameToVideoLink);
    },
    removeHandlerFromVideoLink() {
      const $el = $(this.$el);
      const $videoLink = $el.find('.videoclip-anchor');
      $videoLink.off('click', addIFrameToVideoLink);
    },
  },
  beforeUpdate() {
    this.removeHandlerFromVideoLink();
  },
  mounted() {
    this.attachHandlerToVideoLink();
  },
  updated() {
    this.attachHandlerToVideoLink();
  },
});
</script>

<template>
<div class="users-exercise">
  <div v-if="usersExercise.text">
    <h2 class="slide-show__section-title">
      {{ formal ? usersExercise.exercise.title_formal : usersExercise.exercise.title_informal }}
    </h2>
    <div class="u-mt"
      v-html="formal ? usersExercise.exercise.text_formal : usersExercise.exercise.text_informal">
    </div>
    <div v-html="usersExercise.text" class="users-exercise__answer u-mt">
    </div>
  </div>
  <div v-if="usersExercise.related_text" class="u-mt">
    <h2 class="slide-show__section-title">
      {{ formal ? usersExercise.exercise.related_exercise_title_formal : usersExercise.exercise.related_exercise_title_informal }}
    </h2>
    <div class="u-mt"
      v-html="formal ? usersExercise.exercise.related_exercise_formal : usersExercise.exercise.related_exercise_informal">
    </div>
    <div v-html="usersExercise.related_text" class="users-exercise__answer u-mt">
    </div>
  </div>
</div>
</template>
