<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import { Marker, MapSetting, LayerSetting } from '../types';
import { useMapStore } from './map_store';
import BiographyComponent from './BiographyComponent.vue';
import MapInstance from './MapInstance';

export default defineComponent({
  components: {
    BiographyComponent,
  },
  props: {
    skipHeading: Boolean,
  },
  data() {
    return {
      activeMarkerId: null,
    };
  },
  computed: {
    ...mapState(useMapStore, ['bioMap', 'mapSetting', 'imageUrl']),
    markers(): Array<Marker> {
      if (!this.bioMap) {
        return [];
      }

      const result = [...this.bioMap.waypoints];
      result.sort((a: Marker, b: Marker) => Number(a.number) - Number(b.number));
      return result;
    },
    layersWithLabels(): Array<LayerSetting> {
      return this.mapSetting.layers.map((layer: string) => ({
        name: layer,
        label: this.$t(`map-layers.${layer}`),
      }));
    },
  },
  watch: {
    markers(newValue, oldValue) {
      if (oldValue.length === 0 && newValue.length > 0) {
        this.addMarkersToMap();
      }
    },
  },
  methods: {
    setUpMap() {
      const settings: MapSetting = this.mapSetting;
      const map = new MapInstance({
        htmlId: 'biography-map',
        layers: this.layersWithLabels,
        onActiveMarker: (id: string | null) => { this.activeMarkerId = id; },
        drawPaths: true,
        todayIsDefault: settings.today_is_default,
        showPoland1939: settings.show_poland_1939,
        fitToMarkers: true,
        useGoogleLayer: settings.use_google_layer,
      });
      this.map = map;
    },
    addMarkersToMap() {
      this.map.addMarkers(this.markers);
    },
    activateMarker(markerId: string) {
      this.map.focusMarker(markerId);
    }
  },
  mounted() {
    if (!this.bioMap) {
      return;
    }

    this.setUpMap();
    this.addMarkersToMap();
  },
});
</script>

<template>
<div v-if="bioMap">
  <h2 v-if="!skipHeading" class="heading heading--large u-mb">
    {{ $t('components.biography-component.short_biography') }}
    <div class="pull-right materials-overlay-tools hidden-print">
      <a :href="`/materials/${bioMap?.wp_id}.pdf`"
        :title="$t('general.pdf')" target="_blank">
        <span class="fa fa-file-pdf-o"></span>
      </a>
    </div>
  </h2>

  <div class="u-mb-small">
    <div id="biography-map" class="map map--biography"></div>
  </div>

  <BiographyComponent
    :image-url="imageUrl"
    :markers="markers"
    :active-marker-id="activeMarkerId"
    :biography="bioMap?.biomap_long_biography"
    :focus-marker="activateMarker"
  />
</div>
</template>

<style scoped>
.overlay {
  position: relative;
}
.overlay__inner {
  background-color: transparent;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}
.overlay--obscured > .overlay__inner {
  background-color: rgba(0, 0, 0, 0.5);
}
.map {
  height: calc(var(--base-unit) * 20);
}
</style>
