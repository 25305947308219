<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { Material } from '../types';

export default defineComponent({
  props: {
    material: {
      type: Object as PropType<Material>,
      required: true,
    },
  },
});
</script>

<template>
<li class="card">
  <router-link
    :to="`/materials/${material.id}`"
    class="card__link"
    :style="{backgroundColor: material.color, backgroundImage: `url(${material.thumbnail_path})`}">
    <div class="card__body">
      <p class="card__text">
        {{ material.title }}
      </p>
    </div>
  </router-link>
</li>
</template>
