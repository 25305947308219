import $ from 'jquery';

import { Material } from '../types';

export default function generateMaterialHtml(material: Material): string {
  switch (material.type) {
  case 'image':
  case 'document':
  case 'drawing':
    return `<p>
  <img style="height: 250px;" src="/${material.filename}" alt="${$(material.caption).html()}"/><br>
  <small>${$(material.caption).html()}</small>
</p>`;
  case 'info_text':
    return `<b>${material.title}</b>
<div class="mceNonEditable">
  <p>${material.text}</p>
  <small>${material.written_by}</small>
</div><br>`;
  case 'chart':
    return `<b>${material.title}</b><br>
${material.chart}
<small>${material.caption}</small>`;
  case 'text_source':
    return `<b>${material.title}</b>
<div class="mceNonEditable">
  <p>
    <strong>${material.introduction}</strong>
  </p>
  ${material.text}
</div>
<small>${material.caption}</small>`;
  case 'how_to':
    return `<b>${material.title}</b>
<div class="mceNonEditable">
  ${material.howto_text_formal}
</div>`;
  default:
    console.log(material.type + " not supported.");
    return '';
  }
}
