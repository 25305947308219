import $ from 'jquery';
import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';

import BiographyMapWrapper from './map/BiographyMapWrapper.vue';
import ChapterComponent from './chapter/ChapterComponent.vue';
import FeedbackButton from './feedback-form/FeedbackButton.vue';
import MenuSection from './chapter/MenuSection.vue';
import CustomExerciseForm from './custom-exercise-form/CustomExerciseForm.vue';
import DictionaryWrapper from './dictionary/DictionaryWrapper.vue';
import HelpPage from './help/HelpPage.vue';
import ImageGallery from './gallery/ImageGallery.vue';
import MaterialsComponent from './materials/MaterialsComponent.vue';
import ResultsForm from './results/ResultsForm.vue';
import SiteSearch from './search/SiteSearch.vue';
import StartpageMap from './map/StartpageMap.vue';
import TeacherTitle from './chapter/TeacherTitle.vue';
import TimelineWrapper from './timeline/TimelineWrapper.vue';
import TopicFinder from './topic-finder/TopicFinder.vue';
import StartpageVideo from './video/StartpageVideo.vue';
import VideoPlayer from './video/VideoPlayer.vue';
import VideoTabs from './video/VideoTabs.vue';
import rootStore from './root_store';
import setupI18n from './i18n';
import routes from './routes';

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const app = createApp({
  name: 'MainApp',
  components: {
    BiographyMapWrapper,
    ChapterComponent,
    FeedbackButton,
    MenuSection,
    CustomExerciseForm,
    DictionaryWrapper,
    HelpPage,
    ImageGallery,
    MaterialsComponent,
    ResultsForm,
    SiteSearch,
    StartpageMap,
    StartpageVideo,
    TeacherTitle,
    TimelineWrapper,
    TopicFinder,
    VideoPlayer,
    VideoTabs,
  },
});

$(() => {
  const i18n = setupI18n();
  app.use(rootStore)
    .use(router)
    .use(i18n)
    .mount('#vue-main-app');
});
