<script lang="ts">
import { defineComponent } from 'vue';

import { Marker, MapSetting, LayerSetting, BioMap } from '../types';
import fetchAllMapData from './fetchAllMapData';
import MapInstance from './MapInstance';

export default defineComponent({
  props: {
    mapSetting: String,
  },
  data() {
    return {
      mapData: null,
      mapDataLoading: false,
    };
  },
  computed: {
    markers(): Array<Marker> {
      if (this.mapData === null) {
        return [];
      }
      const markers = this.mapData
        .filter((bioMap: BioMap) =>
          typeof bioMap.chapter === 'object' && bioMap.chapter !== null)
        .map((bioMap: BioMap) => {
          const waypoints: Array<Marker> = JSON.parse(bioMap.biomap_way_points);

          return waypoints.map((waypoint: Marker) => ({
            ...waypoint,
            chapter: bioMap.chapter.title,  // bioMaps without chapters are filtered out above.
            chapterPath: bioMap.chapter_path,
          }));
      }).flat();
      return markers;
    },
    parsedMapSetting(): MapSetting {
      return JSON.parse(this.mapSetting);
    },
    layersWithLabels(): Array<LayerSetting> {
      return this.parsedMapSetting.layers.map((layer: string) => ({
        name: layer,
        label: this.$t(`map-layers.${layer}`),
      }));
    },
  },
  watch: {
    markers(newValue, oldValue) {
      if (oldValue.length === 0 && newValue.length > 0) {
        this.addMarkersToMap();
      }
    },
  },
  methods: {
    async fetchMarkers() {
      this.mapDataLoading = true;
      this.mapData = await fetchAllMapData();
      this.mapDataLoading = false;
    },
    setUpMap() {
      const settings = this.parsedMapSetting;
      const map = new MapInstance({
        htmlId: 'startpage-map',
        layers: this.layersWithLabels,
        initialCenter: [
          Number(settings.center_lat),
          Number(settings.center_lng),
        ],
        initialZoom: settings.zoom,
        todayIsDefault: true,
        showPoland1939: settings.show_poland_1939,
        fitToMarkers: false,
        useGoogleLayer: settings.use_google_layer,
      });
      this.map = map;
    },
    addMarkersToMap() {
      this.map.addMarkers(this.markers);
    },
  },
  mounted() {
    this.setUpMap();
    this.fetchMarkers();
  },
});
</script>

<template>
<div class="overlay u-mb-small" :class="{ 'overlay--obscured': mapDataLoading }">
  <div class="overlay__inner"></div>
  <div id="startpage-map" class="map map--startpage"></div>
</div>
</template>

<style scoped>
.overlay {
  position: relative;
}
.overlay__inner {
  background-color: transparent;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}
.overlay--obscured > .overlay__inner {
  background-color: rgba(0, 0, 0, 0.5);
}
.map {
  height: calc(var(--base-unit) * 20);
}
</style>
