import $ from 'jquery';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake';

/*
 *  print group passwords with javascript since they
 * are not stored in DB
 */

$(() => {
  $(document).on('click', '.export-to-pdf', function(){
    var groupName = $(this).data('group-name');
    const element = document.getElementById('export-to-pdf');

    if (!element) {
      return;
    }

    html2canvas(element).then(function(canvas) {
      const data = canvas.toDataURL();
      const docDefinition = {
        content: [
          {
            image: data,
            width: 500,
          },
        ],
      };
      pdfMake.createPdf(docDefinition).download(`${groupName}.pdf`);
    });
  });
});
