<script lang="ts">
import { defineComponent } from 'vue';

import SearchInputWithLoading from './SearchInputWithLoading.vue';
import ResultList from './ResultList.vue';
import fetchResultsForType from './fetchResultsForType';
import transformSearchResult from './transformSearchResult';

import { SearchResult, TranscriptSegment } from '../types';

export default defineComponent({
  components: {
    SearchInputWithLoading,
    ResultList,
  },
  props: {
    skipHeading: Boolean,
  },
  data() {
    return {
      OLP_SEARCH_TYPES: [
        'chapters',
        'cues',
        'exercises',
        'materials',
        'dictionary',
        'timelines'
      ],
      searchTerm: '',
      isFetching: false,
      hasResults: false,
      results: {},
    };
  },
  computed: {
    transformedResults() {
      let resultObject = {} as any;
      this.OLP_SEARCH_TYPES.forEach((type: string): void => {
        resultObject[type] = this.results[type].map((result: any): SearchResult =>
          transformSearchResult(type, result, this.searchTerm));
      });
      return resultObject;
    },
  },
  methods: {
    async fetchResults(searchTerm: string) {
      this.isFetching = true;
      this.searchTerm = searchTerm;
      this.results = {};

      const promises = this.OLP_SEARCH_TYPES.map((type: string) =>
        fetchResultsForType(searchTerm, type));
      const allResults = await Promise.all(promises);
      this.isFetching = false;
      this.hasResults = true;

      this.OLP_SEARCH_TYPES.forEach((type: string, index: number) => {
        const resultsForType = allResults[index];

        this.results[type] = resultsForType;

        if (type === 'cues') {
          let cueResults = {};
          this.results.cues.forEach((cue: TranscriptSegment) => {
            const pathPart = `${cue.section.slug}/${cue.chapter.slug}`;
            if (cueResults[pathPart]) {
              cueResults[pathPart] = cueResults[pathPart].concat(cue);
            } else {
              cueResults[pathPart] = [cue];
            }
          });
          this.results[type] = Object.values(cueResults);
        }
      });
    },
  },
});
</script>

<template>
<div>
  <h2 v-if="!skipHeading" class="heading heading--large u-mb">
    {{ $t('components.menu-section.search') }}
  </h2>

  <SearchInputWithLoading
    :isLoading="isFetching"
    :onSubmit="fetchResults"
  />

  <div id="accordion" class="panel-group u-mt"
    aria-multiselectable="true" role="tablist"
    v-if="!isFetching && hasResults">
    <ResultList v-for="type in OLP_SEARCH_TYPES"
      :type="type"
      :searchTerm="searchTerm"
      :results="transformedResults[type]"
    />
  </div>
</div>
</template>
