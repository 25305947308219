<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    complete: {
      type: Boolean,
      required: false,
    },
  },
});
</script>

<template>
<button type="button" class="collapsed btn btn-default"
  data-toggle="collapse" :data-target="`#collapsible-${id}`"
  aria-expanded="false" :aria-controls="`collapsible-${id}`">
  <i v-if="complete" class="fa fa-check text-success" aria-hidden="true"></i>
  {{ label }}
  <i class="fa fa-chevron-down" aria-hidden="true"></i>
  <i class="fa fa-chevron-up" aria-hidden="true"></i>
</button>

<div :id="`collapsible-${id}`" class="collapse">
  <slot />
</div>
</template>

<style scoped>
.btn {
  font-weight: 700;
}

.btn.collapsed > .fa-chevron-up {
  display: none;
}
.btn:not(.collapsed) > .fa-chevron-down {
  display: none;
}
</style>
