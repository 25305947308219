<script lang="ts">
import { defineComponent } from 'vue';
import flow from 'lodash.flow';
import curry from 'lodash.curry';

import ExerciseRow from './ExerciseRow.vue';
import SearchInput from '../SearchInput.vue';
import saveOrUpdateFavorite from '../saveOrUpdateFavorite';
import Filter from '../Filter.vue';
import { Exercise, Favorite, FilterCategory  } from '../types';
import filterBySearchTerm from './filterBySearchTerm';
import filterBySubjects from './filterBySubjects';
import filterByTags from './filterByTags';
import filterByStar from './filterByStar';
import filterByNotes from './filterByNotes';

export default defineComponent({
  components: {
    ExerciseRow,
    Filter,
    SearchInput,
  },
  props: {
    exercises: {
      type: String,
      required: true,
    },
    subjects: {
      type: String,
      required: true,
    },
    tags: {
      type: String,
      required: true,
    },
    favSymbol: {
      type: String,
      required: true,
    },
  },
  data() {
    const parsedExercises = JSON.parse(this.exercises);
    const parsedSubjects = JSON.parse(this.subjects);
    const parsedTags = JSON.parse(this.tags);
    return {
      searchTerm: '',
      parsedExercises,
      subjectFilterHash: parsedSubjects.reduce((acc: Object, subject: string) => {
        acc[subject] = false;
        return acc;
      }, {}),
      showTagFilter: false,
      tagFilterHash: parsedTags.reduce((acc: Object, tag: string) => {
        acc[tag] = false;
        return acc;
      }, {}),
      onlyStarred: false,
      onlyNotes: false,
    };
  },
  computed: {
    parsedExercises(): Array<Exercise> {
      return JSON.parse(this.exercises);
    },
    parsedSubjects(): Array<string> {
      return JSON.parse(this.subjects);
    },
    parsedTags(): Array<string> {
      return JSON.parse(this.tags);
    },
    subjectFilter(): Array<FilterCategory> {
      return this.parsedSubjects.map((subject: string) => ({
        value: subject,
        count: this.filteredExercises.filter((exercise: Exercise) =>
          exercise.subjects.includes(subject)).length,
      }));
    },
    tagFilter(): Array<FilterCategory> {
      return this.parsedTags.map((tag: string) => ({
        value: tag,
        count: this.filteredExercises.filter((exercise: Exercise) =>
          exercise.tags.includes(tag)).length,
      }));
    },
    checkedSubjects() {
      return Object.entries(this.subjectFilterHash).filter(([label, checked]) => checked)
        .map(([label, checked]) => label);
    },
    checkedTags() {
      return Object.entries(this.tagFilterHash).filter(([label, checked]) => checked)
        .map(([label, checked]) => label);
    },
    filteredExercises(): Array<Exercise> {
      const filterFunctions = [
        curry(filterBySearchTerm)(this.searchTerm),
        curry(filterBySubjects)(this.checkedSubjects),
        curry(filterByTags)(this.checkedTags),
      ];

      if (this.onlyStarred) {
        filterFunctions.push(filterByStar);
      }
      if (this.onlyNotes) {
        filterFunctions.push(filterByNotes);
      }

      const applyFilters = flow(filterFunctions);
      return applyFilters(this.parsedExercises);
    },
    numResultsString() {
      const filteredCount = this.filteredExercises.length;
      const allCount = this.parsedExercises.length;
      const exercisesTranslation = this.$t('searchResultTypes.exercises');

      if (filteredCount === allCount) {
        return `${filteredCount} ${exercisesTranslation}`;
      } else {
        return `${filteredCount} / ${allCount} ${exercisesTranslation}`;
      }
    }
  },
  methods: {
    handleSubjectFilterChange(value: string) {
      this.subjectFilterHash[value] = !this.subjectFilterHash[value];
    },
    handleTagFilterChange(value: string) {
      this.tagFilterHash[value] = !this.tagFilterHash[value];
    },
    toggleTagFilter() {
      this.showTagFilter = !this.showTagFilter;
    },
    async handleToggleStar(exercise: Exercise, starred: boolean) {
      const favorite: Favorite = await saveOrUpdateFavorite(exercise, { starred });
      exercise.favorite_id = favorite.id;
      exercise.starred = favorite.starred;
    },
    async handleUpdateNote(exercise: Exercise, note: string) {
      const favorite: Favorite = await saveOrUpdateFavorite(exercise, { note });
      exercise.favorite_id = favorite.id;
      exercise.note = favorite.note;
    }
  },
});
</script>

<template>
<div>
  <div>
    <SearchInput v-model="searchTerm" />
  </div>

  <div class="u-mt-small">
    <label>{{ $t('components.topicFinder.subjects') }}</label>
    <Filter :categories="subjectFilter"
      :filterHash="subjectFilterHash"
      :onChange="handleSubjectFilterChange"/>
  </div>

  <button type="button" class="u-mt-small btn btn-default"
    aria-label="Show tag filter"
    title="Show tag filter"
    @click="toggleTagFilter">
    {{ $t('components.topicFinder.filter_tags') }} <i :class="`fa fa-${showTagFilter ? 'chevron-up' : 'chevron-down'}`"
      aria-hidden="true"></i>
  </button>

  <div v-if="showTagFilter" class="u-mt-small">
    <Filter :categories="tagFilter"
      :filterHash="tagFilterHash"
      :onChange="handleTagFilterChange"/>
  </div>

  <div class="checkbox u-mt-small">
    <label>
      <input type="checkbox" v-model="onlyStarred"
        data-test-id="bookmarked-exercises-checkbox" />
      {{ $t('components.topicFinder.starred_only') }}
      <i :class="`fa fa-${favSymbol}`" aria-hidden="true"></i>
    </label>
  </div>

  <div class="checkbox u-mt-small">
    <label>
      <input type="checkbox" v-model="onlyNotes" />
      {{ $t('components.topicFinder.notes_only') }}
    </label>
  </div>

  <div class="u-mt-small">
    {{ numResultsString }}
  </div>
</div>

<div class="c-table u-mt">
  <table class="c-table__table">
    <thead>
      <tr>
        <th class="c-table__header" scope="col">
          {{ $t('components.topicFinder.chapter') }}
        </th>
        <th class="c-table__header" scope="col">
          {{ $t('components.topicFinder.exercise') }}
        </th>
        <th class="c-table__header" scope="col">
          {{ $t('components.topicFinder.subjects') }}
        </th>
        <th class="c-table__header" scope="col">
          {{ $t('components.topicFinder.tags') }}
        </th>
        <th class="c-table__header" scope="col">
          {{ $t('components.topicFinder.fav') }}
        </th>
        <th class="c-table__header" scope="col">
          {{ $t('components.topicFinder.note') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ExerciseRow v-for="exercise in filteredExercises"
        :key="exercise.id"
        :exercise="exercise"
        :searchTerm="searchTerm"
        :favSymbol="favSymbol"
        :onToggleStar="handleToggleStar"
        :onUpdateNote="handleUpdateNote"
        />
    </tbody>
  </table>
</div>
</template>

<style scoped>
.btn {
  font-weight: 700;
}
</style>
